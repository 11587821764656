<h2 class="sectionTitle">{{ Heading }}</h2>
<h6 class="packages-subtext">
  <span>{{ Subtext }}</span>
</h6>
<div class="row medium">
  <div class="col-md-12">
    @if (Loading) {
      <div><span>Loading...</span></div>
    }

    @if (!Loading) {
      <div>
        <rn-grid data-testid="addonsTable" [Configuration]="TableConfiguration"></rn-grid>
      </div>
    }
  </div>
</div>
