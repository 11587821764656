import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { processEventStateTypes } from "../../../../core/enums/processEventStateTypes";
import { TableSelectable } from "../../../../core/interfaces/table-selectable";
import { DataRetrievalParameters } from "../../../../core/models/data-retrieval-parameters";
import { ProcessingEventData } from "../../../../core/models/processing-event-data";
import { UserLoginState } from "../../../../core/models/user.login.state";
import { CartService } from "../../../services/cart/cart.service";
import { RnDialogService } from "../../../services/dialog/rndialog.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { NotificationDialogService } from "../../../services/notificationDialog/notification-dialog.service";
import { OrganizationLogicService } from "../../../services/organization-logic/organization-logic.service";
import {
  LicenseService,
  OrganizationService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnCommonMultipleId,
  RnCommonSearch,
  RnLicenseBaseVM,
  RnLicenseFullVM,
  RnOrganizationConfigurationVM,
  RnOrganizationPaymentOnFileVM,
  RnOrganizationsProfileVM,
  RnOrganizationWLPContactInformationVM,
  RnRNOrderItem,
} from "../../../services/rnapi2-service/models/models";
import {
  OrderService,
  RnRNAddOnPayload,
  RnRNLicenseItem,
  RnRNOrderHeader,
} from "src/app/shared/services/rnapi2-service";
import { SelectionService } from "../../../services/selection/selection.service";
import { IdService } from "src/app/shared/services/id/id.service";
import {
  RnsidebarService,
  SidebarComponents,
} from "../../../services/sidebar/rnsidebar.service";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { GridCheckLimitColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-check-limit-column-configuration";
import { GridColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-column-configuration";
import { GridConfiguration } from "../../third-party-wrappers/grid/configuration/grid-configuration";
import { GridHtmlColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-html-column-configuration";
import { GridHyperlinkColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-hyperlink-column-configuration";
import { GenericDialogConfiguration } from "../generic-dialog/generic-dialog-configuration";
import { LicenseAddEditConfig } from "../license-add-edit/license-add-edit-config";
import { LicensePurchaseResponse } from "../license-purchase/license-purchase-info";
import { SearchControlConfiguration } from "../search-control/configuration/search-control-configuration";
import { SignalrPollingService } from "src/app/shared/services/signalr-polling/signalr-polling.service";
import { SignalRService } from "src/app/shared/notifications/signalr.service";
import { SignalrPollingBaseMessage } from "src/app/core/models/signalr/signalr-polling-base-message";
import dayjs from "dayjs";
import { ConstantsService } from "../../../services/constants/constants.service";
import { BillingFormData } from "../billing-form/billing-form.data";
import { OrganizationCacheService } from "src/app/shared/services/organization-cache/organization-cache.service";

class LicenseData extends RnLicenseFullVM implements TableSelectable {
  RnTableSelected: boolean;
}
@Component({
  selector: "app-license-management",
  templateUrl: "./license-management.component.html",
  styleUrls: [
    "./license-management.component.scss",
    "../../third-party-wrappers/grid/grid.component.scss",
  ],
})
export class LicenseManagementComponent implements OnInit, OnDestroy {
  rerender = false;
  @Input() WelcomeWizardMode: boolean;
  @ViewChild("search") searchTextBox: ElementRef;
  isLoading = false;
  @Input() set OrgProfile(profile: RnOrganizationsProfileVM) {
    this.orgProfile = profile;
    if (this.orgProfile) {
      this.orgLoad = true;
      this.loadingPayInfo = true;
      this.rnCommonSearch.Id = this.orgProfile?.ID.toString();
      this.rnCommonSearch.AffectedOrganizationId = this.orgProfile?.ID;
      this.rnCommonSearch.pageNumber = 0;

      this.rnCommonSearchQBO.Id = this.orgProfile?.ID.toString();
      this.rnCommonSearchQBO.AffectedOrganizationId = this.orgProfile?.ID;
      this.rnCommonSearchQBO.pageNumber = 0;

      this.rnCommonId.Id = this.orgProfile?.ID.toString();
      this.rnCommonId.AffectedOrganizationId = this.orgProfile?.ID;

      if (this.canRefresh) {
        this.performSearch();
      } else {
        this.needsRefresh = true;
      }

      this.selectionService.GetSelectedOrgPaymentInfo().subscribe((p) => {
        this.orgPaymentInfo = p;
        if (!this.orgLoad) {
          this.sendParentEvent(false);
        }
        this.loadingPayInfo = false;
      });
      this.canShowRemoveLicenseButton =
        this.loggedInService.loggedInUserHasRights(["RMVLICENCS"]) &&
        this.orgProfile?.Status !== "WriteOff";
      this.rnCommonSearchQBO.pageSize = this.qboPageSize;
      this.loadQboSubscriptionData(false);
      this.showPurchaseQBOsButton = this.shouldShowPurchaseQboButton(profile);
    }
  }
  @Input() set OrgWLPContactInfo(
    contactInfo: RnOrganizationWLPContactInformationVM,
  ) {
    this.orgWLPContactInfo = contactInfo;
  }

  @Input() set OrgPaymentInfo(paymentInfo: RnOrganizationPaymentOnFileVM) {
    this.orgPaymentInfo = paymentInfo;
  }

  @Input() OrgConfig: RnOrganizationConfigurationVM;

  @Output() ProcessingEvent = new EventEmitter<ProcessingEventData>();

  @Output() LicensesPurchased = new EventEmitter<LicensePurchaseResponse[]>();
  @Output() ChangeState = new EventEmitter<any>();
  @Output() LicenseAdded = new EventEmitter<any>();

  // qbo table configuration
  qboPageSize = 10;
  qboRowsPerPageOptions: Array<number> = [10];
  qboCurrentPageNum = 0;
  qboSubsData: any[];
  qboSubsCount = 0;
  qboTotalSubsCount = 0;
  isProcessing = false;

  constructor(
    private selectionService: SelectionService,
    private orgService: OrganizationService,
    private rnDialogService: RnDialogService,
    private notificationDlgService: NotificationDialogService,
    private licenseService: LicenseService,
    private cartService: CartService,
    private loggedInService: LoggedInInfoService,
    private orgLogicService: OrganizationLogicService,
    private rnsidebarService: RnsidebarService,
    private toastService: RnToastService,
    private loggedInInfoService: LoggedInInfoService,
    private signalrPollingService: SignalrPollingService,
    private signalRService: SignalRService,
    private notificationService: NotificationDialogService,
    private orderService: OrderService,
    private cdRef: ChangeDetectorRef,
    private constantsService: ConstantsService,
    private organizationCacheService: OrganizationCacheService,
  ) {}
  doRerender() {
    this.rerender = true;
    this.cdRef.detectChanges();
    this.rerender = false;
  }
  licenseListGridConfiguration: GridConfiguration = new GridConfiguration();
  searchControlConfiguration: SearchControlConfiguration;
  searchText = "";
  searchTextQBO = "";
  clearSearch: Subject<void> = new Subject<void>();
  exporting = false;
  selectionColumnConfig: GridCheckLimitColumnConfiguration;
  showHeaderLabel = true;
  showExportLicensesButton = false;
  showExportQBOsButton = false;
  showPurchaseQBOsButton = true;
  //showBuyAndLeaseButton: boolean = false;
  canAddLicenses = false;
  canShowRemoveLicenseButton = false;
  qboSubscriptionFeatureEnabled = false;
  qboMultipleSubscriptionFeatureEnabled = false;

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  getTooltipIfPurchaseNotAllowed() {
    if (
      this.qboSubsCount &&
      this.qboSubsCount > 0 &&
      !this.qboMultipleSubscriptionFeatureEnabled
    )
      return "<span data-testid='tooltipMsg'>Purchasing Multiple Plans is not enabled currently!</span>";
    else return "";
  }

  // Return tooltip if add-on already exists for a base package.
  getTooltipIfAddonPurchaseNotAllowed(items: any) {
    if (items.length > 1) {
      const itemName: string = items[1].Name.toString();
      return (
        "<span data-testid='tooltipMsg'> The " +
        itemName +
        " add-on already exists!</span>"
      );
    } else return "";
  }

  licenseSelectable(
    licenseType: string,
    loggedInUserOrgID: number,
    licenseOrgID: number,
    orgHasPOF: boolean,
  ): boolean {
    if (licenseType === "BYOL") {
      // If it's a BYOL, yes
      return true;
    } else if (licenseType.indexOf("Pending") >= 0) {
      // If it's pending, no
      return false;
    } else if (licenseOrgID !== loggedInUserOrgID && orgHasPOF) {
      // If the user isn't in the org, and the org is self-paid (has a POF), also no
      return false;
    } else {
      // Otherwise, yes
      return true;
    }
  }

  ngOnInit(): void {
    this.rnCommonSearch.pageSize = 10;
    this.rnCommonSearch.pageNumber = 0;
    this.rnCommonSearch.OrderByText = "orderByLicense1";
    this.rnCommonSearch.isDescendingOrder = false;

    this.rnCommonSearchQBO.pageSize = 10;
    this.rnCommonSearchQBO.pageNumber = 0;
    this.rnCommonSearchQBO.OrderByText = "orderByLicense1";
    this.rnCommonSearchQBO.isDescendingOrder = false;

    this.subscriptions.push(
      this.selectionService.SelectedOrgWLPConfig.subscribe((c) => {
        this.orgWLPContactInfo = c;
      }),
    );

    this.searchControlConfiguration = new SearchControlConfiguration();
    this.searchControlConfiguration.EditFieldPlaceholderText = "Search";
    this.searchControlConfiguration.ButtonClass =
      "internal-landing-search-button";
    this.searchControlConfiguration.EditClass = "default-search-edit"; //Pending
    this.searchControlConfiguration.ContainerClass = "search-field-QBO-style";
    this.searchControlConfiguration.ShowMagnifyingGlassIcon = true;
    this.searchControlConfiguration.MagnifyingGlassClass = "search-icon-test";

    this.licenseListGridConfiguration.ShowCountHeader = true;
    this.licenseListGridConfiguration.CountHeaderItemsName = "Licenses";
    this.licenseListGridConfiguration.RowsPerPageOptions = [10, 25];
    this.licenseListGridConfiguration.FirstRow = 0;
    this.licenseListGridConfiguration.GridClass = "tableHeadingColorClass";

    this.licenseListGridConfiguration.setUrlData = (
      params: DataRetrievalParameters,
    ) => {
      this.rnCommonSearch.pageNumber =
        ((params.SortOrder === "" &&
          this.rnCommonSearch.OrderByText === "orderByLicense1") ||
          this.rnCommonSearch.OrderByText === params.SortOrder) &&
        this.rnCommonSearch.isDescendingOrder === params.IsDescendingOrder &&
        this.rnCommonSearch.pageSize === params.PageSize
          ? params.PageNumber
          : 0;
      this.rnCommonSearch.pageSize = params.PageSize;
      this.rnCommonSearch.isDescendingOrder = params.IsDescendingOrder;
      this.rnCommonSearch.OrderByText = params.SortOrder
        ? params.SortOrder
        : "orderByLicense1";
      this.performSearch();
    };

    // column configuration
    this.licenseListGridConfiguration.ColumnConfiguration = [];
    this.selectionColumnConfig = new GridCheckLimitColumnConfiguration(
      "",
      "",
      "checkboxCol",
      "",
    );
    this.selectionColumnConfig.GetIsDisabled = (rowData: any) => {
      const licenseData = rowData as RnLicenseFullVM;
      return !this.licenseSelectable(
        licenseData.Type,
        this.loggedInUser.OrganizationID,
        this.orgProfile?.ID,
        this.orgProfile?.HasDirectPaymentOnFile,
      );
    };

    this.selectionColumnConfig.SelectAllDisabled = () => {
      return (
        this.currLicenses?.filter((l) => {
          return this.licenseSelectable(
            l.Type,
            this.loggedInUser.OrganizationID,
            this.orgProfile?.ID,
            this.orgProfile?.HasDirectPaymentOnFile,
          );
        }).length === 0
      );
    };

    this.selectionColumnConfig.SelectAllChange = (
      $event,
      allSelected: boolean,
    ) => {
      if (allSelected) {
        this.currLicenses.forEach((l) => {
          if (
            this.selectionColumnConfig.SelectedItems.filter((i) => {
              const temp = i as RnLicenseFullVM;
              return temp.LicenseID === l.LicenseID;
            }).length === 0 &&
            this.licenseSelectable(
              l.Type,
              this.loggedInUser.OrganizationID,
              this.orgProfile?.ID,
              this.orgProfile?.HasDirectPaymentOnFile,
            )
          ) {
            const d = l as LicenseData;
            d.RnTableSelected = true;
            this.selectionColumnConfig.SelectedItems.push(d);
          }
        });
      } else {
        this.selectionColumnConfig.SelectedItems.forEach((i) => {
          i.RnTableSelected = false;
        });
        this.selectionColumnConfig.SelectedItems = [];
      }
    };
    this.selectionColumnConfig.SelectAllAvailable = true;
    this.selectionColumnConfig.SelectedItems = [];
    this.selectionColumnConfig.CheckChange = ($event, rowData: any) => {
      const l = rowData as LicenseData;
      if (l.RnTableSelected) {
        const selectableCount = this.currLicenses?.filter((c) => {
          return c.Type.indexOf("Pending") < 0;
        }).length;
        this.licenseListGridConfiguration.RnSelectAll =
          selectableCount === this.selectionColumnConfig.SelectedItems.length;
      } else {
        this.licenseListGridConfiguration.RnSelectAll = false;
      }
    };

    this.licenseListGridConfiguration.ColumnConfiguration.push(
      this.selectionColumnConfig,
    );
    this.licenseListGridConfiguration.RnSelectionEnabled = true;
    this.licenseListGridConfiguration.RnSelectAll = false;

    const license1Column = new GridHyperlinkColumnConfiguration(
      "License1",
      "License Key",
      "",
      "orderByLicense1",
    );

    license1Column.GetLinkIsEnabled = (rowData: any) => {
      if (this.WelcomeWizardMode) {
        return false;
      }

      const licenseData = rowData as RnLicenseFullVM;
      return licenseData.Type.indexOf("Pending") < 0;
    };

    license1Column.RunClickCommand = (rowData: any) => {
      const licenseData = rowData as RnLicenseFullVM;
      this.ChangeState.emit({ state: "details", id: licenseData.LicenseID });
    };

    license1Column.ClickAvailableHandler = (rowData: any) => {
      const licenseData = rowData as RnLicenseFullVM;
      return licenseData.Type.indexOf("Pending") < 0;
    };

    license1Column.GetCustomLinkText = (rowData: any) => {
      const licenseData = rowData as RnLicenseFullVM;
      if (licenseData.Type.indexOf("Pending") >= 0) {
        const typeText =
          licenseData.LicenseEditTypeName.toLowerCase().indexOf("isreadonly") >=
          0
            ? "Auto"
            : "Manual";
        if (typeText === "Auto") {
          return `<div class="dspy-flex"><div class="m-bt-auto m-l-neg8 addUserSpinner pull-left">
                    <i class="fa-solid fa-circle-notch fa-spin faMedium fa-fw"></i>
                  </div> Pending ${typeText} Fulfillment (Order Date:  ${dayjs(licenseData.CreatedDate).format("MM/DD/YYYY")})`;
        }
        return `Pending ${typeText} Fulfillment (Order Date:  ${dayjs(licenseData.CreatedDate).format("MM/DD/YYYY")})`;
      }

      return licenseData.License1;
    };

    this.licenseListGridConfiguration.ColumnConfiguration.push(license1Column);

    const license2Column = new GridHtmlColumnConfiguration(
      "License2",
      "Product Code",
      "",
      "orderByLicense2",
    );
    license2Column.GetHtmlDataForColumn = (rowData: any) => {
      const licenseData = rowData as RnLicenseFullVM;
      if (licenseData.Type.indexOf("Pending") >= 0) {
        return "";
      } else {
        return licenseData.License2;
      }
    };

    this.licenseListGridConfiguration.ColumnConfiguration.push(license2Column);
    this.licenseListGridConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "DisplayName",
        "Type",
        "",
        "orderByDisplayName",
      ),
    );
    this.licenseListGridConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration("TypeDesc", "Ownership", "", "orderByType"),
    );

    const autoAssignCol = new GridColumnConfiguration(
      "IsAutoAssignable",
      "Auto-assign",
      "",
      "",
    );
    autoAssignCol.CustomDataRetrieval = (rowData: any) => {
      const licenseData = rowData as RnLicenseFullVM;
      return licenseData.IsAutoAssignable ? "Yes" : "No";
    };
    this.licenseListGridConfiguration.ColumnConfiguration.push(autoAssignCol);

    this.licenseListGridConfiguration.getColSpanForCell = (
      col: GridColumnConfiguration,
      rowData: any,
    ) => {
      const licenseData = rowData as RnLicenseFullVM;
      if (
        col.FieldName === "License1" &&
        licenseData.Type.indexOf("Pending") >= 0
      ) {
        return "2";
      } else {
        return "1";
      }
    };
    this.licenseListGridConfiguration.getClassForCell = (
      col: GridColumnConfiguration,
      rowData: any,
    ) => {
      if (col.FieldName === "License2") {
        const licenseData = rowData as RnLicenseFullVM;
        if (licenseData.Type.indexOf("Pending") >= 0) {
          return "dspy-none";
        }
      }
      return "";
    };

    this.licenseListGridConfiguration.DisableSetLoading = true;

    this.subscriptions.push(
      this.rnsidebarService.getObservable().subscribe((p) => {
        if (p.refreshUser) {
          this.setLoading();
          this.performSearch();
        }
        if (!p.visibleSidebar) {
          this.cartService.clearCart();
        }
      }),
    );

    this.canRefresh = true;
    if (this.needsRefresh) {
      this.performSearch();
    }

    this.loggedInUser = this.loggedInService.GetLoggedInUser();
    this.showHeaderLabel = !this.WelcomeWizardMode;
    this.showExportLicensesButton =
      !this.WelcomeWizardMode &&
      this.loggedInService.loggedInUserHasRights(["EXPORTLIC"]);
    this.showExportQBOsButton = !this.WelcomeWizardMode;

    this.canAddLicenses = this.loggedInService.loggedInUserHasRights([
      "ADDLICENCS",
    ]);

    const payload: RnCommonSearch = {
      search: "feature_qbosubscriptions_enabled",
    };
    this.subscriptions.push(
      this.orgService
        .apiV2OrganizationsGetconfigurablevaluePost(payload, "response")
        .subscribe((r) => {
          if (r.body.data && r.body.data !== "") {
            this.qboSubscriptionFeatureEnabled = r.body.data === "true";
          }
        }),
    );

    // Check QBO Multiple subscription is allowed or not
    const payloadQBO: RnCommonSearch = {
      search: "allowmultipleqbo",
    };
    this.subscriptions.push(
      this.orgService
        .apiV2OrganizationsIsfeaturemanagementkeyenabledPost(
          payloadQBO,
          "response",
        )
        .subscribe((r) => {
          this.qboMultipleSubscriptionFeatureEnabled = r.body.data;
        }),
    );

    if (this.loggedInUser?.OrganizationID) {
      this.showPurchaseQBOsButton = this.shouldShowPurchaseQboButton(this.orgProfile);
    }
  }

  shouldShowPurchaseQboButton = (
    orgProfile: RnOrganizationsProfileVM
  ): boolean => {
    if (this.WelcomeWizardMode) {
      return false;
    }

    return (
      this.loggedInUser?.OrganizationID === orgProfile?.ID &&
      orgProfile?.HasDirectPaymentOnFile
    );
  };

  loadQboSubscriptionData(isManualLoading: boolean) {
    this.sendParentEvent(true);

    // QBO subscription configuration
    this.orgService
      .apiV2OrganizationsGetqboinfofororgPost(this.rnCommonSearchQBO, "body")
      .subscribe((r: any) => {
        this.qboSubsData = r.data.Data;
        this.qboSubsCount = this.qboSubsData.length;
        this.qboTotalSubsCount = r.data.TotalRecords;
        this.sendParentEvent(false);
        if (!isManualLoading && !r.data.IsProcessing) {
          this.signalRService.messages.subscribe((message) => {
            const notification = JSON.parse(
              message,
            ) as SignalrPollingBaseMessage;
            if (
              notification &&
              notification.Payload &&
              notification.Payload.AreaDataType.toUpperCase() ===
                "GENERIC_PROPERTY_PAYLOAD" &&
              notification.Payload.Area.toUpperCase() == "ORG" &&
              notification.Payload.AreaID == this.loggedInUser.OrganizationID
            ) {
              this.qboSearchOccurred("");
              this.removing = false;
            }
          });
        }
        if (r.data.IsProcessing) {
          this.orgLoad = true;
          this.setLoading();
          this.sendParentEvent(true);
          setTimeout(() => this.loadQboSubscriptionData(true), 15000);
        }
      });
  }

  qboPageSizeChanged() {
    // NYI feature
  }

  qboNavToPage(pageNumber: number) {
    this.orgLoad = true;
    this.qboCurrentPageNum = pageNumber;
    this.rnCommonSearchQBO.pageNumber = this.qboCurrentPageNum;
    this.setLoading();
    this.loadQboSubscriptionData(false);
  }

  setShowBuyAndLeaseButton() {
    //this.showBuyAndLeaseButton = !this.WelcomeWizardMode && this.orgLogicService.CanOrgPurchaseLeaseLicenses(this.loggedInService.loggedInUserHasRights(['PRCHDROPPK']), this.orgProfile, this.OrgConfig, this.loggedInService.GetLoggedInUser().OrganizationID);
  }

  orgLicenseRemovalDisabled(): boolean {
    return (
      this.removing || this.selectionColumnConfig.SelectedItems.length === 0
    );
  }

  orgExportLicenseDisabled(): boolean {
    return this.exporting;
  }

  showBuyAndLeaseButton(): boolean {
    if (!this.orgProfile && !this.OrgConfig) {
      return false;
    }
    const PRCHDROPPK = this.loggedInService.loggedInUserHasRights([
      "PRCHDROPPK",
    ]);

    return (
      !this.WelcomeWizardMode &&
      this.orgLogicService.CanOrgPurchaseLeaseLicenses(
        PRCHDROPPK,
        this.orgProfile,
        this.OrgConfig,
        this.loggedInService.GetLoggedInUser().OrganizationID,
      )
    );
  }

  searchOccurred(searchText: string): void {
    this.orgLoad = true;
    this.searchText = searchText;
    this.rnCommonSearch.search = this.searchText;
    this.rnCommonSearch.pageNumber = 0;
    this.licenseListGridConfiguration.FirstRow = 0;
    this.setLoading();
    this.performSearch();
  }

  qboSearchOccurred(searchTextQBO: string): void {
    this.orgLoad = true;
    this.searchTextQBO = searchTextQBO;
    this.rnCommonSearchQBO.search = this.searchTextQBO;
    this.rnCommonSearchQBO.pageNumber = this.qboCurrentPageNum;
    this.rnCommonSearchQBO.pageSize = this.qboPageSize;
    this.setLoading();
    this.loadQboSubscriptionData(false);
  }

  addLicense(): void {
    const dialogConfig = new LicenseAddEditConfig();
    dialogConfig.IsEdit = false;
    dialogConfig.OrganizationID = this.orgProfile?.ID;
    dialogConfig.OrgWLPSupportWebsite = this.orgWLPContactInfo.SupportWebsite;
    const dlg = this.rnDialogService.AddEditLicenseDialog(dialogConfig);
    this.subscriptions.push(
      dlg.onClose.subscribe((r) => {
        if (r) {
          this.setLoading();
          this.performSearch();
          if (this.WelcomeWizardMode) {
            this.LicenseAdded.emit([]);
          }
        }
      }),
    );
  }

  removeLicensesConfirm(): void {
    this.removing = true;
    const confirmConfig = new GenericDialogConfiguration();
    const safeOrgName = this.orgProfile.Name.replace(
      /[\u00A0-\u9999<>\\&]/g,
      function (i) {
        return "&#" + i.charCodeAt(0).toString() + ";";
      },
    );
    confirmConfig.StyleClass = "confirmation";
    confirmConfig.Title = "Delete License?";
    confirmConfig.Message = `This will permanently remove this license from <strong>${safeOrgName}</strong> and unassign it from all users.`;
    confirmConfig.ConfirmButtonStyleClass = "destroy";
    confirmConfig.DialogHeaderClass = "modal-header no-border";
    confirmConfig.DialogFooterCancelClass = "right-spacing";
    confirmConfig.ConfirmButtonText = "Delete License";
    confirmConfig.MessageContainsHTML = true;
    confirmConfig.ShowCloseButton = true;
    console.log(confirmConfig);
    confirmConfig.Confirmed = () => {
      this.removeLicenses();
    };
    confirmConfig.Canceled = () => {
      this.removing = false;
    };

    this.notificationDlgService.ShowConfirmation(confirmConfig);
  }

  removeLicenses(): void {
    this.licenseListGridConfiguration.LoadingText = "Deleting...";

    this.setLoading();
    this.orgLoad = true;
    this.sendParentEvent(true);

    const payload = new RnCommonMultipleId();
    payload.AffectedOrganizationId = this.orgProfile?.ID;
    payload.Ids = [];
    let reloadData = false;
    this.selectionColumnConfig.SelectedItems.forEach((i) => {
      const l = i as RnLicenseFullVM;
      if (l.ItemId === -1) {
        const deleteIdx = this.purchasedItems.findIndex(
          (i) => i.purchaseID === l.ItemName,
        );
        if (deleteIdx >= 0) {
          const purchItem = this.purchasedItems[deleteIdx];
          const removeItem = { PackageID: purchItem.license.PackageId };
          while (purchItem.quantity > 0) {
            this.cartService.removeFromCart(removeItem);
            purchItem.quantity--;
          }
          this.purchasedItems.splice(deleteIdx, 1);
          reloadData = true;
        }
      } else {
        payload.Ids.push(l.LicenseID.toString());
      }
    });

    if (reloadData) {
      this.LicensesPurchased.emit(this.purchasedItems);
    }

    if (payload.Ids.length > 0) {
      this.licenseService
        .apiV2LicensesCancelmultiplePost(payload, "response")
        .subscribe((s) => {
          setTimeout(() => {
            this.licenseListGridConfiguration.LoadingText = "";
            this.removing = false;
            this.selectionColumnConfig.SelectedItems = [];
            this.setLoading();
            this.performSearch();
            let removeMsg = payload.Ids.length.toString();
            removeMsg +=
              payload.Ids.length === 1
                ? " license has been removed."
                : " licenses have been removed.";
            this.toastService.showSuccess(removeMsg);
          }, 5000);
        });
    } else if (reloadData) {
      this.removing = false;
      this.selectionColumnConfig.SelectedItems = [];
      this.setLoading();
      this.performSearch();
    }
  }

  exportLicenses(): void {
    this.exporting = true;
    const fileName = this.orgProfile.Name.replace(/\s/g, "");
    const todayDate = new Date();
    const datePipe = new DatePipe("en-US");
    const datePiped = datePipe.transform(todayDate, "yyyy-MMM-dd");
    this.subscriptions.push(
      this.orgService
        .apiV2OrganizationsGetOrgLicenseExportByOrgIdPost(
          this.rnCommonSearch,
          "response",
        )
        .subscribe(
          (result) => {
            const filename =
              "LicenseReport_" + fileName + "_" + datePiped + ".xlsx";
            const contentType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

            const linkElement = document.createElement("a");
            try {
              const blob = new Blob([result.body], { type: contentType });
              const url = window.URL.createObjectURL(blob);

              linkElement.setAttribute("href", url);
              linkElement.setAttribute("download", filename);

              const clickEvent = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false,
              });
              linkElement.dispatchEvent(clickEvent);
            } catch (ex) {
              console.log(ex);
            }
            this.exporting = false;
          },
          (error) => {
            this.toastService.showError(
              "There was an error in loading the data for the report. Please contact support for further assistance.",
            );
            this.exporting = false;
          },
        ),
    );
  }

  exportQBOs(): void {
    this.exporting = true;
    const fileName = this.orgProfile.Name.replace(/\s/g, "");
    const todayDate = new Date();
    const datePipe = new DatePipe("en-US");
    const datePiped = datePipe.transform(todayDate, "yyyy-MMM-dd");
    this.subscriptions.push(
      this.orgService
        .apiV2OrganizationsGetqboinfoforreportbyorgidPost(
          this.rnCommonId,
          "response",
        )
        .subscribe(
          (result) => {
            const filename =
              "Online_Subscriptions_" + fileName + "_" + datePiped + ".xlsx";
            const contentType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

            const linkElement = document.createElement("a");
            try {
              const blob = new Blob([result.body], { type: contentType });
              const url = window.URL.createObjectURL(blob);

              linkElement.setAttribute("href", url);
              linkElement.setAttribute("download", filename);

              const clickEvent = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false,
              });
              linkElement.dispatchEvent(clickEvent);
            } catch (ex) {
              console.log(ex);
            }
            this.exporting = false;
          },
          (error) => {
            this.toastService.showError(
              "There was an error in loading the data for the report. Please contact support for further assistance.",
            );
            this.exporting = false;
          },
        ),
    );
  }

  cancelSubscription(selectedItem: any) {
    const confirmConfig = this.getConfirmSaveChangesDialogConfig(selectedItem);
    this.notificationService.ShowConfirmation(confirmConfig);
  }

  changeSubscriptionType(
    qboAccountID: string,
    baseSubscriptionID: string,
    selectedItem: any,
  ) {
    this.rnsidebarService.trigger(
      SidebarComponents.purchaseQboSub,
      selectedItem,
      "purchaseSubscription",
      qboAccountID + ":" + baseSubscriptionID,
    );
  }

  getConfirmSaveChangesDialogConfig(
    selectedItem: any,
  ): GenericDialogConfiguration {
    const cc = new GenericDialogConfiguration();
    if (selectedItem.Name === "QuickBooks Online") {
      cc.Title = "Cancel QuickBooks Online Subscription?";
    }
    if (selectedItem.Name === "QuickBooks Online Payroll") {
      cc.Title = "Cancel QuickBooks Payroll Subscription";
    }
    if (selectedItem.Name === "QuickBooks Time") {
      cc.Title = "Cancel QuickBooks Time Subscription";
    }
    cc.MessageContainsHTML = true;
    cc.DialogFooterClass = "rightAlignButtons";
    cc.DialogHeaderClass = "modal-header no-border";
    cc.DialogFooterCancelClass = "right-spacing";
    cc.CancelButtonText = "BACK";
    cc.ConfirmButtonText = "CANCEL SUBSCRIPTION";
    cc.ShowCloseButton = false;
    cc.CancelButtonStyleClass = "addUserTeritary";
    cc.ConfirmButtonStyleClass = "dontCreateUserButton";
    cc.StyleClass = "confirmation cancel-subs";
    if (selectedItem.Name === "QuickBooks Online") {
      cc.Message =
        "This will also cancel your QuickBooks Time and QuickBooks Payroll subscriptions. Are you sure you want to continue?";
    } else {
      cc.Message = "Are you sure you want to continue?";
    }
    cc.Confirmed = () => {
      this.confirmSaveChanges(selectedItem);
      this.removing = true;
    };
    return cc;
  }

  purchaseQbo(): void {
    if (this.canPurchaseNeedsPOF()) {
      const confirmConfig = new GenericDialogConfiguration();
      confirmConfig.StyleClass = "confirmation modal-body-14";
      confirmConfig.Title = "No Payment Method set up";
      confirmConfig.Message =
        this.constantsService.purchaseQboSubscriptionNoBillingInfoMessage;
      confirmConfig.ConfirmButtonStyleClass = "primary";
      confirmConfig.DialogHeaderClass = "modal-header no-border";
      confirmConfig.DialogFooterCancelClass = "right-spacing";
      confirmConfig.ConfirmButtonText = "CONTINUE";
      confirmConfig.CancelButtonStyleClass = "addUserTeritary";
      confirmConfig.MessageContainsHTML = false;
      confirmConfig.ShowCloseButton = true;
      confirmConfig.cancelButtonNoContentPadding = true;
      confirmConfig.confirmButtonNoContentPadding = true;
      confirmConfig.UseProgressButton = true;
      confirmConfig.KeepOpenAfterConfirm = false;
      confirmConfig.Confirmed = (dialogRef) => {
        dialogRef.CloseDialog();
        const data = new BillingFormData();
        data.organization = this.orgProfile;
        data.limitedRefresh = true;
        const billingFormDialog = this.rnDialogService.BillingFormDialog(data);
        billingFormDialog.beforeClosed().subscribe((s) => {
          if (s) {
            this.removing = true;
            this.rnsidebarService.trigger(
              SidebarComponents.purchaseQboSub,
              "",
              "purchaseSubscription",
            );
          }
        });
      };
      this.notificationService.ShowConfirmation(confirmConfig);
    } else {
      this.removing = true;
      this.rnsidebarService.trigger(
        SidebarComponents.purchaseQboSub,
        "",
        "purchaseSubscription",
      );
    }
  }

  private canPurchaseNeedsPOF(): boolean {
    if (!this.orgProfile) {
      return false;
    }
    const canPurchaseRight = this.loggedInInfoService.loggedInUserHasRights([
      "PRCHDROPPK",
    ]);
    const canUpdateBilling = this.loggedInInfoService.loggedInUserHasRights([
      "UPDTBILING",
    ]);
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return false;
    }

    const isInternal = loggedInUser.IsInternal();
    const loggedInUserOrg = this.loggedInInfoService.GetLoggedInUserOrg();
    return this.orgLogicService.CanPurchasePackagesNeedPOF(
      canPurchaseRight,
      canUpdateBilling,
      isInternal,
      this.orgProfile,
      this.OrgConfig,
      loggedInUserOrg,
      loggedInUser.UserType,
    );
  }

  addPayrollOrTime(qboAccountID?: string): void {
    this.rnsidebarService.trigger(
      SidebarComponents.purchaseQboSub,
      "addPayrollOrTime",
      "purchaseSubscription",
      qboAccountID,
    );
  }

  confirmSaveChanges(selectedItem: any) {
    this.isProcessing = true;
    this.setLoading();
    this.orgLoad = true;
    this.sendParentEvent(true);
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return;
    }
    //to used for add
    const payload: RnRNAddOnPayload = new RnRNAddOnPayload();
    payload.PaymentOnFileID =
      this.loggedInInfoService.GetLoggedInUserOrg().PaymentOnFileID;
    payload.OrganizationID = this.orgProfile.ID;
    payload.OrderItems = this.getOrderItems(selectedItem);
    payload.QbesLicenseItems = new Array<RnRNLicenseItem>();
    payload.PromotionCodeID = null;
    payload.ForceSkipEmail = false;
    const header: RnRNOrderHeader = {
      Order_XID: IdService.makeID(),
      Order_Source: "AppHub",
      Order_Date: new Date().toISOString().slice(0, 10).replace(/-/g, "/"),
      SalesAgent_TAG: loggedInUser.UserID.toString(),
    };
    payload.Header = header;
    this.orderService
      .apiV2OrdersCreateaddonPost(payload, "response")
      .subscribe((response) => {
        this.loadQboSubscriptionData(true);
        if (response.body && response.body.Success) {
          this.signalRService.messages.subscribe((message) => {
            const notification = JSON.parse(
              message,
            ) as SignalrPollingBaseMessage;
            if (
              notification &&
              notification.Payload &&
              notification.Payload.AreaDataType.toUpperCase() ===
                "GENERIC_PROPERTY_PAYLOAD" &&
              notification.Payload.Area.toUpperCase() == "ORG" &&
              notification.Payload.AreaID == this.loggedInUser.OrganizationID
            ) {
              this.qboSearchOccurred("");
            }
          });
        }
      });
  }

  getOrderItems(selectedItem: any) {
    const orderItems = [];

    if (selectedItem.Name === "QuickBooks Online") {
      const qboSub = this.qboSubsData.find((account) =>
        account.Items.some(
          (item) => item.SubscriptionID === selectedItem.SubscriptionID,
        ),
      );
      qboSub.Items.forEach((element) => {
        const o: RnRNOrderItem = {
          PackageID: element.PackageID,
          Quantity: -1,
          PromoCode: "-1",
          VendorSubscriptionID: element.SubscriptionID,
        };
        orderItems.push(o);
      });
    } else {
      const o: RnRNOrderItem = {
        PackageID: selectedItem.PackageID,
        Quantity: -1,
        PromoCode: "-1",
        VendorSubscriptionID: selectedItem.SubscriptionID,
      };
      orderItems.push(o);
    }
    return orderItems;
  }

  goBackAndReload(): void {
    setTimeout(() => {
      if (!this.loggedInInfoService.GetLoggedInUser()) {
        return;
      }
      this.isProcessing = false;
      window.location.reload();
    }, 3000);
  }
  licenseDetails(licenseId: number): void {
    alert("open Id: " + licenseId.toString());
  }

  private rnCommonSearch: RnCommonSearch = new RnCommonSearch();
  private rnCommonId: RnCommonId = new RnCommonId();
  private rnCommonSearchQBO: RnCommonSearch = new RnCommonSearch();
  private subscriptions: Subscription[] = [];
  private orgProfile: RnOrganizationsProfileVM;
  private orgWLPContactInfo: RnOrganizationWLPContactInformationVM;
  private currLicenses: RnLicenseFullVM[];
  private removing = false;
  private performDelete: boolean;
  private orgPaymentInfo: RnOrganizationPaymentOnFileVM;
  private performSearch(): void {
    this.sendParentEvent(true);

    if (this.rnCommonSearch.Id && this.rnCommonSearch.Id !== "") {
      if (this.WelcomeWizardMode) {
        if (this.purchasedItems.length > 0) {
          this.welcomeWizardPageNumber = this.rnCommonSearch.pageNumber;
          this.welcomeWizardPageSize = this.rnCommonSearch.pageSize;
          const lastPageWithPurchases = Math.floor(
            this.purchasedItems.length / this.rnCommonSearch.pageSize,
          );
          if (this.rnCommonSearch.pageNumber <= lastPageWithPurchases) {
            this.rnCommonSearch.pageNumber = 0;
          } else {
            this.rnCommonSearch.pageNumber =
              this.rnCommonSearch.pageNumber - lastPageWithPurchases;
            if (this.rnCommonSearch.pageNumber > 0) {
              this.rnCommonSearch.pageNumber--;
              this.rnCommonSearch.pageSize = this.rnCommonSearch.pageSize * 2;
            }
          }
        }
      }

      this.selectionColumnConfig.SelectedItems = [];
      this.licenseListGridConfiguration.RnSelectAll = false;
      this.subscriptions.push(
        this.orgService
          .apiV2OrganizationsGetlicensesfororganizationPost(
            this.rnCommonSearch,
            "response",
          )
          .subscribe((r) => {
            this.sendParentEvent(false);
            this.needsRefresh = false;
            this.licenseListGridConfiguration.DisableSetLoading = false;
            if (this.WelcomeWizardMode) {
              if (this.purchasedItems.length > 0) {
                let offset = 0;
                this.currLicenses = [];

                const lastPageWithPurchases = Math.floor(
                  this.purchasedItems.length / this.rnCommonSearch.pageSize,
                );
                if (this.welcomeWizardPageNumber <= lastPageWithPurchases) {
                  const startIdx =
                    this.welcomeWizardPageNumber * this.welcomeWizardPageSize;
                  const endIdx = startIdx + this.welcomeWizardPageSize - 1;

                  let idx = startIdx;
                  while (idx < this.purchasedItems.length && idx <= endIdx) {
                    const item = this.purchasedItems[idx];
                    const listItem = this.LicenseBaseToLicenseFull(
                      item.license,
                    );
                    listItem.ItemName = item.purchaseID;
                    this.currLicenses.push(listItem);
                    idx++;
                  }

                  offset = 0;
                } else {
                  offset =
                    this.welcomeWizardPageSize -
                    (this.purchasedItems.length % this.welcomeWizardPageSize);
                }

                if (this.currLicenses.length < this.rnCommonSearch.pageSize) {
                  let ctr = offset;
                  while (
                    this.currLicenses.length < this.rnCommonSearch.pageSize &&
                    ctr < r.body.data.Results.length
                  ) {
                    this.currLicenses.push(r.body.data.Results[ctr]);
                    ctr++;
                  }
                }

                this.licenseListGridConfiguration.loadingDataCompleted(
                  this.currLicenses,
                  r.body.data.TotalNumberRecords + this.purchasedItems.length,
                  this.rnCommonSearch.pageNumber * this.rnCommonSearch.pageSize,
                  this.rnCommonSearch.pageNumber,
                );
                if (!this.loadingPayInfo) {
                  this.sendParentEvent(false);
                }
                this.orgLoad = false;
                return;
              }
            }

            this.currLicenses = r.body.data.Results;
            this.licenseListGridConfiguration.loadingDataCompleted(
              this.currLicenses,
              r.body.data.TotalNumberRecords,
              this.rnCommonSearch.pageNumber * this.rnCommonSearch.pageSize,
              this.rnCommonSearch.pageNumber,
            );
            if (!this.loadingPayInfo) {
              this.sendParentEvent(false);
            }
            this.orgLoad = false;
          }),
      );
    }
  }

  private setLoading() {
    if (!this.orgLoad) {
      this.licenseListGridConfiguration.Loading = true;
    }
  }

  private LicenseBaseToLicenseFull(
    licenseBase: RnLicenseBaseVM,
  ): RnLicenseFullVM {
    const result = {
      License1: "New License",
      License2: "",
      DisplayName: licenseBase.PackageName,
      Type: licenseBase.LicenseTypeName,
      TypeDesc: `Pending ${licenseBase.LicenseTypeName}`,
      IsAutoAssignable: true,
      LicenseID: licenseBase.ItemId,
      ItemId: -1,
    };
    return result;
  }

  private sendParentEvent(isLoading: boolean) {
    if (!this.orgLoad && !this.loadingPayInfo) {
      return;
    }

    const eventData = new ProcessingEventData();
    if (isLoading) {
      eventData.overlayText = "Loading...";
      eventData.type = processEventStateTypes.updating;
    } else {
      eventData.type = processEventStateTypes.completed;
    }

    this.ProcessingEvent.emit(eventData);
  }

  private purchasedItems: LicensePurchaseResponse[] = [];
  private welcomeWizardPageNumber: number;
  private welcomeWizardPageSize: number;
  private canRefresh: boolean;
  private needsRefresh: boolean;
  private orgLoad: boolean;
  private loadingPayInfo = false;
  private loggedInUser: UserLoginState;
}
