@if (userLoggedIn) {
  <div class="row topmarg"></div>
  <div class="header" role="main" id="header">
    <div class="logo">
      <img alt="right-works logo" class="logoImage" src="{{ assetUrl }}/images/NewAngularRightWorksLightLogo.png" />
    </div>
    @if (userLoggedIn) {
      <div class="navbar-right">
        <div class="pad-well">
          <mat-menu #menu="matMenu" yPosition="below" xPosition="before" (closed)="closedMenuHandler($event)">
            <div>
              <app-button
                id="btnEditProfile"
                buttonStyle="mat-menu-item"
                (clickActionEmitter)="showProfileModal()"
                label="Edit Profile Information"
                [disabled]="isWelcomeWizard"
              ></app-button>
            </div>
            @if (!isChangePackageButtonDisabled) {
              <div>
                <app-button
                  id="userDetailChangePackage"
                  [disabled]="isChangePackageButtonDisabled || isWelcomeWizard"
                  buttonStyle="mat-menu-item"
                  (clickActionEmitter)="processButtonClick('ChangePackage')"
                  label="Manage Packages"
                ></app-button>
              </div>
            }
            @if (!isChangeManageAddonsDisabled) {
              <div>
                <app-button
                  id="userDetailManageAddons"
                  buttonStyle="mat-menu-item"
                  [disabled]="isChangeManageAddonsDisabled || isWelcomeWizard"
                  (clickActionEmitter)="processButtonClick('ManageAddons')"
                  label="Manage Add-ons"
                ></app-button>
              </div>
            }
            @if (showEditClientAccessButton) {
              <div>
                <app-button
                  id="btnMngClientAccess"
                  buttonStyle="mat-menu-item"
                  (clickActionEmitter)="openClientAccess()"
                  label="Manage Client Access"
                  [disabled]="isWelcomeWizard"
                ></app-button>
              </div>
            }
            @if (!showEditClientAccessButton) {
              <div>
                <app-button
                  id="btnMngClientAccess"
                  buttonStyle="mat-menu-item"
                  (clickActionEmitter)="openClientAccess()"
                  label="View Client Access"
                  [disabled]="isWelcomeWizard"
                ></app-button>
              </div>
            }
            <div>
              <app-process-button
                #resetPasswordButton
                id="btnResetPassword"
                [Configuration]="resetUserPasswordButtonConfig"
                (ButtonClicked)="resetPassword()"
              ></app-process-button>
            </div>
            <div>
              @if (showSecurityBtn) {
                <app-button
                  id="btnSecurity"
                  buttonStyle="mat-menu-item"
                  imgSource="rn-shield"
                  (clickActionEmitter)="launchSecurityModal()"
                  label="{{ mfaLabel }}"
                  additionalContent="{{ mfaStatus }}"
                  additionalContentClass="{{ mfaButtonContentStyle }}"
                  additionalIconSource="{{ mfaButtonContentIcon }}"
                  [disabled]="isWelcomeWizard"
                ></app-button>
              }
            </div>
            <mat-divider></mat-divider>
            <div>
              <app-button
                id="btnLogout"
                buttonStyle="mat-menu-item"
                imgSource="rn-log-out"
                (clickActionEmitter)="logout()"
                label="Log out"
              ></app-button>
            </div>
          </mat-menu>
          <a data-testid="userName" class="btn btn-secondary" id="userName" [matMenuTriggerFor]="menu"
            >{{ loggedInUserName }}&nbsp;<i class="fa-solid fa-caret-down"></i
          ></a>
        </div>
      </div>
    }
  </div>
}
