<div class="user-edit-addons height-99">
  <div class="row">
    <div class="col-6">
      <br />
      <h2>Change Add-ons</h2>
      <br />
    </div>
    <div class="col-6 shoppingcartdiv">
      <div #cartIcon class="pull-right">
        <app-shopping-cart-icon (ToggleShoppingCart)="toggleShoppingCart()"></app-shopping-cart-icon>
      </div>
      <div #cart>
        @if (isShoppingCartVisible) {
          <app-shopping-cart
            [PaymentInfo]="paymentInfo"
            [IsInline]="false"
            (CloseCart)="toggleShoppingCart()"
          ></app-shopping-cart>
        }
      </div>
    </div>
  </div>
  <div class="row sidebar-scrollable-content">
    <div class="col-12 p-ml-3">
      <app-manage-addons
        data-testid="appManageAddons"
        [addonsSelectForm]="addOnsForm"
        [OrganizationID]="organizationId"
        [orgProfile]="orgProfile"
        [orgConfig]="orgConfig"
        [orgWlpContactInfo]="orgWlpContactInfo"
        [SelectedPackage]="selectedPackage"
        [AddOnList]="availableAddOns"
        [AssignedAddOns]="assignedAddOns"
        [hideTitles]="true"
        (PackageChanged)="PackageChanged($event)"
      ></app-manage-addons>
    </div>
  </div>

  <div class="rightAlignButtons">
    <a tabindex="0" (keyup.entere)="dismiss()" data-testid="manageAddonsCancelBtn" class="cancel" (click)="dismiss()"
      >Cancel</a
    >
    &nbsp;
    <app-button
      id="btnSave"
      [disabled]="!canSubmit()"
      (clickActionEmitter)="saveChanges()"
      label="Save"
      showProcessing="true"
      [processing]="loading"
      buttonStyle="primary"
    >
    </app-button>
    <br />
  </div>
</div>
