<div class="login-notification">
  <div class="modal-header header-border-none">
    <div>
      @switch (State) {
        @case ('returntotoplevel') {
          <h3>Rightworks Maintenance Mode</h3>
        }
        @case ('logoutonly') {
          <h3>Rightworks services for {{ OrganizationName }} are offline for maintenance</h3>
        }
      }
    </div>
  </div>
  <div class="modal-body">
    <div>
      @switch (State) {
        @case ('returntotoplevel') {
          <div>
            <span>
              Hello {{ FirstName }}. <br />
              We are currently working on some Rightworks services behind-the-scenes. While this is processing you will
              not be able to access this client. Please wait 5 minutes and try your action again. if you have any
              questions, please contact
              {{ technical_support_phone }}.
            </span>
          </div>
        }
        @case ('logoutonly') {
          <div>
            <span>
              Hello {{ FirstName }}. We're currently working on some Rightworks services behind-the-scenes. While we're
              working, you won't be able to access AppHub or your Rightworks cloud-hosted desktop. If you have any
              questions in the meantime, you can call {{ technical_support_phone }} and they will be happy to assist.
            </span>
          </div>
        }
      }
    </div>
    <br />
    <div>
      @if (showOrgName) {
        <div><b>Organization: </b> {{ OrganizationName }}</div>
      }
      <div>
        <b>Maintenance started: </b>
        {{ StartDate | utcToLocalDate | date: 'MM/dd/yyyy hh:mm:ss a Z' }}
      </div>
      <div><b>Reason: </b> {{ Reason }}</div>
    </div>
  </div>
  <div class="modal-footer footer-top-border">
    <div class="flex-row no-bottom-margin">
      <div>
        @switch (State) {
          @case ('returntotoplevel') {
            <app-button
              id="btnReturn"
              (clickActionEmitter)="goToTopLevelOrg()"
              label="Return to Top-Level Organization"
              buttonStyle="primary"
            >
            </app-button>
          }
          @case ('logoutonly') {
            <app-button id="btnLogout" (clickActionEmitter)="logout()" label="Logout" buttonStyle="teritary">
            </app-button>
          }
        }
      </div>
    </div>
  </div>
</div>
