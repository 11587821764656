<div class="row medium titleDivision">
  <div class="col-md-12">
    <h2 class="pageTitle" data-testid="pageTitle">Packages and Add-ons Inventory</h2>
    <h6 class="packages-subtext" data-testid="pageTitleSubTxt">
      This is a view of your assigned and remaining packages and add-ons.
    </h6>
    <h6 class="packages-subtext" data-testid="pageTitleSubTxt2">
      For information related to any WISP purchases, please access your invoice in the Billing tab.
    </h6>
  </div>
</div>
@if (canShowChangeInventory()) {
  <div class="medium changePackageButton">
    <div class="float-right">
      <app-button
        id="btnChangePackagesandAddOns"
        buttonStyle="changePackage"
        noContentPadding="true"
        label="CHANGE PACKAGES AND ADD-ONS INVENTORY"
        (clickActionEmitter)="changeInventory()"
      >
      </app-button>
    </div>
  </div>
}
@if (showWLPPackages()) {
  <app-wlp-inventory-table
    [WLPName]="OrgProfile?.PartnerName"
    [WlpPackageText]=""
    [PkgConfigInfo]="PkgConfigInfo"
    [WLPPackages]="WlpPackages"
    [WLPAddOns]="WlpAddOns"
    [OrgWlpContactInfo]="OrgWlpContactInfo"
    [OrgProfile]="OrgProfile"
    [Loading]="Loading"
    (PackagePoolSelected)="packageSelected($event)"
  >
  </app-wlp-inventory-table>
}

@if (showDirectPackages()) {
  <app-direct-inventory-table
    data-testid="pkgTable"
    [Heading]="directPackagesHeading"
    [QuantityColumnHeading]="'Seats'"
    [Subtext]="directPackagesSubtext"
    [PackageList]="RnPackages"
    [Loading]="Loading"
    [OrgProfile]="OrgProfile"
    [PkgConfigInfo]="PkgConfigInfo"
    (PackagePoolSelected)="packageSelected($event)"
  >
  </app-direct-inventory-table>
}

@if (showDirectAddons()) {
  <app-direct-inventory-table
    data-testid="addonsTable"
    [IsAddOns]="isAddonsFlag"
    [Heading]="directAddonsHeading"
    [QuantityColumnHeading]="'Quantity'"
    [Subtext]="directAddonsSubtext"
    [PackageList]="RnAddOns"
    [Loading]="Loading"
    [OrgProfile]="OrgProfile"
    [PkgConfigInfo]="PkgConfigInfo"
    (PackagePoolSelected)="packageSelected($event)"
  >
  </app-direct-inventory-table>
}
