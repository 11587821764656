<div class="modal-header">
  <h3 class="modal-title">
    {{ idleService.idleState | rnIdleService: 'title' }}
  </h3>
</div>
@if (idleService.idleState | rnIdleService: 'iswarningmode') {
  <div idle-countdown="countdown" class="modal-body idle-dialog-content">
    <p>
      <strong>Are you still using your Self-Service Portal?</strong> Due to inactivity, you will be automatically logged
      out in <strong>{{ idleService.countdown }}</strong> second(s).
    </p>
  </div>
}
@if (!(idleService.idleState | rnIdleService: 'iswarningmode')) {
  <div idle-countdown="countdown" class="modal-body idle-dialog-content">
    <p>
      Due to inactivity and to help prevent unauthorized use of your account, you have been logged out of the
      Self-Service Portal.
    </p>
  </div>
}
@if (false) {
  <div class="modal-footer">
    <a tabindex="0" (keyup.enter)="logout()" (click)="logout()">Log Out</a>
    &nbsp;
    <app-button id="btnContinue" (clickActionEmitter)="continue()" label="Continue" buttonStyle="primary"> </app-button>
  </div>
}

@if (!(idleService.idleState | rnIdleService: 'iswarningmode')) {
  <div class="modal-footer">
    <app-button id="btnDismiss" (clickActionEmitter)="continue()" label="Dismiss" buttonStyle="secondary"> </app-button>
  </div>
}
