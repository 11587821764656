<form [formGroup]="parentForm">
  <div class="form-check form-switch">
    <input
      class="form-check-input toggleSwitch"
      type="checkbox"
      role="switch"
      id="{{id}}"
      data-testid="parentForm.value.enabled ? 'switchEnabled' : 'switchDisabled'"
      data-pendo-id="parentForm.value.enabled ? 'switchEnabled' : 'switchDisabled'"
      [formControlName]="id"
      (click)="handleChange($event)">
  </div>
</form>
