<div class="overlay-container height-100percent main-div">
  @if (user | rnEditMfaDialog: 'loading') {
    <app-loader text="Loading..." opaque="true"></app-loader>
  }
  @if (isProcessing) {
    <app-loader text="{{ message }}" opaque="true"></app-loader>
  }
  @if (!isProcessing) {
    <a
      tabindex="0"
      (keyup.enter)="close()"
      class="close xToClose c-pointer"
      pTooltip="Close"
      tooltipPosition="top"
      (click)="close()"
      ><img class="x-image" src="{{ assetUrl }}/icons/rn-x.svg" alt="close icon"
    /></a>
  }
  @if (finishedProcessing) {
    <div class="processing">
      <div class="processing-content">
        <div class="ta-center">
          @if (checkedIcon) {
            <img src="assets/images/mfa_enabled.svg" alt="MFA enabled icon" />
          }
          @if (!checkedIcon) {
            <img src="assets/images/mfa_disabled.svg" alt="MFA disabled icon" />
          }
        </div>
        <div class="message" data-testid="messageMFA">{{ message }}</div>
        @if (submessage) {
          <div data-testid="subMessageMFA" class="submessage">
            {{ submessage }}
          </div>
        }
        <div class="modal-footer">
          <a tabindex="0" (keyup.enter)="close()" (click)="close()" data-testid="close" class="cancel-button">Close</a>
        </div>
      </div>
    </div>
  }
  @if (!(user | rnEditMfaDialog: 'userIsMfaEnabled') && !orgMFAEnabled) {
    <div>
      <div class="modal-content">
        <div class="row">
          <div class="col-md-1">
            <span class="fa-stack fa-2x">
              <i class="fak fa-rn-shield-filled fa-stack-2x"></i>
              <i class="fak fa-rn-lock-outline fa-stack-1x fa-inverse fa-md"></i>
            </span>
          </div>
          <div class="col-md-11 div-make-safer">
            <p class="p-protect">Protect your account with Multi-Factor Authentication (MFA)</p>
            <p class="p-make-safer">
              Make your Rightworks account safer and more secure by enabling multi-factor authentication. When enabled
              the security feature will be used for AppHub, Remote Desktop, and File Manager.
            </p>
            <p class="p-list"><b>Follow the steps below to get started.</b></p>
            <ol class="circledNumberedList">
              <li>Click the Enable MFA button below.</li>
              <li>Once the feature is enabled, log out of your Rightworks account.</li>
              <li>Log back into AppHub, and you will be prompted to run through the enrollment process.</li>
            </ol>
            <p class="mfa-notice">
              Once this feature is fully enabled you will be asked to authenticate each time you login.
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer p-right-50">
        <a tabindex="0" (keyup.enter)="close()" class="cancel-button" data-testid="cancelBtn" (click)="close()"
          >Cancel</a
        >
        <app-button id="btnEnableMFA" (clickActionEmitter)="enableMfa()" label="Enable MFA" buttonStyle="primary">
        </app-button>
      </div>
    </div>
  }
  @if (user | rnEditMfaDialog: 'userIsMfaEnabled') {
    <div>
      <div class="modal-content">
        <div class="row">
          <div class="col-md-1">
            <span class="fa-stack fa-2x">
              <i class="fak fa-rn-shield-filled fa-stack-2x"></i>
              <i class="fak fa-rn-lock-outline fa-stack-1x fa-inverse fa-md"></i>
              <i class="fa-solid fa-circle-check fa-stack-1x"></i>
            </span>
          </div>
          <div class="col-md-8 p-top-20 pad-bot-50 p-left-33">
            <p class="mfa-enabled">MFA Status:&nbsp;<span class="color-green-enabled">Enabled</span></p>
            <span class="enabledDate"
              >MFA has been enabled since {{ user.MfaEnabled | utcToLocalDate | date: 'MMMM d, y - h:mm a' }}</span
            >
          </div>
        </div>
        <div class="actionSection">
          @if (!orgMFAEnabled) {
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-8 p-left-33">
                <span class="mfa-disabled">Disable MFA</span>
              </div>
            </div>
          }
          @if (!orgMFAEnabled) {
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-8 p-left-33">
                <span class="mfa-disabling">
                  Disabling MFA means you will no longer be asked to authenticate when you log in to AppHub, Remote
                  Desktop, and File Manager.
                </span>
              </div>
              <div class="col-md-3">
                <app-button
                  id="btnDisableMFA"
                  [disabled]="disableButtonDisableMFA"
                  (clickActionEmitter)="disableMfa()"
                  label="Disable MFA"
                  buttonStyle="secondary"
                >
                </app-button>
              </div>
            </div>
          }
          @if (!orgMFAEnabled) {
            <hr class="sectionDivider" />
          }
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-8 resetText p-left-33">
              <span class="mfa-reset"> Reset MFA </span>
              <br />
              <span class="span-haveyou">Have you changed your device?</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-8 p-left-33 p-bottom-33 p-top-8">
              <span class="by-reset">
                By resetting MFA, you will be prompted to enroll on a new device the next time you log in.
              </span>
            </div>
            <div class="col-md-3">
              @if (!orgMFAEnabled) {
                <app-button
                  id="btnResetMFA"
                  (clickActionEmitter)="resetMfa()"
                  label="Reset MFA"
                  buttonStyle="secondary"
                >
                </app-button>
              }
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer p-right-33 p-top-30">
        <a tabindex="0" (keyup.enter)="close()" class="cancel-button" data-testid="cancelBtn" (click)="close()"
          >Cancel</a
        >
        @if (orgMFAEnabled) {
          <app-button id="btnResetMFA2" (clickActionEmitter)="resetMfa()" label="Reset MFA" buttonStyle="primary">
          </app-button>
        }
      </div>
    </div>
  }
</div>
