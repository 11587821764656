<div class="confirmation" tabindex="-1">
  <div class="modal-header no-border">
    <h3 data-testid="folderPopupHeader" class="modal-header-text m-b-1rem">
      {{ dialogTitle }}
    </h3>
  </div>
  <div class="modal-body modal-content">
    <form #containerForm="ngForm" name="containerForm" novalidate>
      <fieldset>
        <div
          class="form-group"
          [ngClass]="{
            'has-error':
              ((folderNameField?.errors?.required && (containerForm.submitted || folderNameField?.touched)) ||
                uniquefoldername ||
                (containerForm.invalid && !folderNameField?.errors?.required)) &&
              !folderNameField?.errors?.pendingvalidation,
            'has-pending-validation': folderNameField?.errors?.pendingvalidation
          }"
        >
          <div class="p-field">
            <label for="folderName" class="folder_name">Folder Name</label>
            <input
              #folderNameFocus
              id="folderName"
              #folderNameField="ngModel"
              data-testid="folderName"
              type="text"
              autocomplete="off"
              maxlength="64"
              pattern="((?![\x22<>:/\|\\\?\*,&\t]).)*$"
              minlength="1"
              name="folderName"
              class="form-control"
              [(ngModel)]="editfolderName"
              required
              placeholder="Folder Name"
              (keyup)="onFolderNameRename()"
              (paste)="onFolderNameRename()"
              pInputText
            />
            <div>
              @if (
                folderNameField?.errors?.required &&
                (containerForm.submitted || folderNameField?.touched) &&
                !folderNameField?.errors?.pendingvalidation
              ) {
                <div data-testid="nameReqiredMsg" class="help-block">Required field</div>
              }
              @if (folderNameField?.errors?.maxlength && !folderNameField?.errors?.pendingvalidation) {
                <div data-testid="nameLengthErr" class="help-block">
                  Folder name is too long (must be less than 64 characters)
                </div>
              }
              @if (folderNameField?.errors?.pattern && !folderNameField?.errors?.pendingvalidation) {
                <div data-testid="specialCharErr" class="help-block">
                  Folder name cannot contain the following special characters: tab, < > " : / \ | ? * , &
                </div>
              }
              @if (uniquefoldername && !folderNameField?.errors?.pendingvalidation) {
                <div data-testid="nameExistsErr" class="help-block">
                  Folder with this name already exists at this level
                </div>
              }
              @if (folderNameField?.errors?.pendingvalidation) {
                <div class="help-block">
                  <span data-testid="checkingNameMsg">Checking if folder name is unique... </span
                  ><app-icon
                    data-testid="whiteLoadingSpinner"
                    source="rn-circle-notch"
                    imgClass="loading-spinner white-icon "
                  ></app-icon>
                </div>
              }
            </div>
          </div>
        </div>
      </fieldset>
    </form>
    @if (dialogConfig.isEdit) {
      <div>
        <strong>Note:</strong> Updating the folder <strong>{{ dialogConfig.currentName }}</strong> will lock
        organizations that have users with access to this folder until the renaming process has completed.
      </div>
    }
  </div>
  <div class="modal-footer">
    <app-button
      id="cancelButton"
      label="CANCEL"
      [disabled]="working"
      (clickActionEmitter)="!working && close()"
      buttonStyle="addUserTeritary"
    >
    </app-button>
    <app-button
      id="submitBtn"
      label="{{ submitButtonText }}"
      [disabled]="!containerForm.valid || submitDisabled"
      (clickActionEmitter)="submit()"
      [processing]="working"
      showProcessing="true"
      buttonStyle="primary"
    >
    </app-button>
  </div>
</div>
