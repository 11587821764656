<div class="flex-row primaryFrame">
  <div class="content">
    <div id="primaryframelabel">{{ frameLabel }}</div>
    @if (showSearch) {
      <div class="primaryframeSearch">
        <app-search-control
          id="primaryFrameSearchControl"
          class="float-right"
          [Configuration]="searchControlConfiguration"
          (SearchOccurred)="searchOccurred($event)"
        ></app-search-control>
      </div>
    }
  </div>
</div>
