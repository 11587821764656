<div class="summary-header">Your Order Summary</div>
<hr class="headerSeperator" />
<div class="summary-info-text">Your Order Has Been Placed.</div>
<div class="row">
  <div class="col-md-6">
    <app-license-purchase-items
      [useBold]="true"
      [purchases]="purchases"
      [licenseConfig]="licenseConfig"
    ></app-license-purchase-items>
    <div class="payment-method-container">
      <div class="payment-method-text">CURRENT PAYMENT METHOD</div>
      <div class="payment-method-detail-container">
        @if (licenseConfig?.paymentInfo.PaymentMethods[0]?.PaymentMethodType === 'ACH') {
          <div>
            <div>ACH</div>
            <div>
              Account Type:
              {{ licenseConfig?.paymentInfo.PaymentMethods[0]?.ACHTypeName }}
            </div>
            <div>
              {{ licenseConfig?.paymentInfo.PaymentMethods[0]?.AccountLast4 }}
            </div>
          </div>
        }
        @if (licenseConfig?.paymentInfo.PaymentMethods[0]?.PaymentMethodType !== 'ACH') {
          <div>
            <div>
              {{ licenseConfig?.paymentInfo.PaymentMethods[0]?.PaymentMethodType }}&nbsp;{{
                licenseConfig?.paymentInfo.PaymentMethods[0]?.AccountLast4
              }}
            </div>
            @if (licenseConfig?.paymentInfo.PaymentMethods[0]?.CCExpireOn) {
              <div>
                Expires
                {{ licenseConfig?.paymentInfo.PaymentMethods[0]?.CCExpireOn | date: 'MM/yyyy' }}
              </div>
            }
          </div>
        }
        <div class="change-pay-method-text">
          You can change your payment and billing information on the Billing tab.
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-5 nextStep">
    <div class="next-steps">Next Steps</div>
    <div class="next-steps-container">
      You will receive an email with your license fulfillment details and help articles on assigning and deploying your
      licenses.
      <br />
      <br />
      If you have any questions, please feel free to contact the Account Services team at
      <a href="mailto:accountservices@rightworks.com">accountservices&#64;rightworks.com</a>.
    </div>
  </div>
</div>
