@if (gridData && gridData.length > 0) {
  <div class="paginator-container" #navButtons data-testid="gridNavButtons">
    Rows per page
    <select [(ngModel)]="pageSize" (change)="pageSizeChanged()" class="selectOptionForPagination">
      @for (option of pageSizeOptions; track option) {
        <option [value]="option">
          {{ option }}
        </option>
      }
    </select>

    @if (pages?.length > 0) {
      <span data-testid="gridPageButtonContainer" class="float-right gridPageButtonContainer">
        {{ getItemRangeText() }}
        <button
          class="paginator-nav-button"
          data-testid="gridNavButtonLeft"
          [disabled]="!pages || currentPageNum === pages[0]"
          (click)="navToPage(currentPageNum - 1)"
        >
          <i class="fa-solid fa-chevron-left"></i>
        </button>
        @for (page of pages; track page) {
          <span>
            <button
              class="paginator-nav-button"
              [disabled]="page < 0 || page === currentPageNum"
              [ngClass]="{ 'active-page-item': page === currentPageNum }"
              (click)="navToPage(page)"
              [attr.data-testid]="'gridPageButton_' + page"
            >
              {{ page }}
            </button>
          </span>
        }
        <button
          title="next"
          class="paginator-nav-button"
          data-testid="gridNavButtonRight"
          [disabled]="currentPageNum === getLastPageNumber()"
          (click)="navToPage(currentPageNum + 1)"
        >
          <i class="fa-solid fa-chevron-right"></i>
        </button>
      </span>
    }
  </div>
}
