<div class="detailed_wrapper">
  <div class="col-12">
    <div class="m-2">
      <em
        >Please check your official Rightworks invoice for the most accurate billing information, including sales
        tax.</em
      ><br />
      @if (gridConfiguration.Loading) {
        <app-loader text="Loading..." inline="true"></app-loader>
      }
      <rn-grid [Configuration]="gridConfiguration"></rn-grid>
    </div>
  </div>
</div>
