<div [formGroup]="timeForm">
  <p-accordion
    [activeIndex]="index"
    [expandIcon]="'pi pi-fw pi-chevron-down'"
    [collapseIcon]="'pi pi-fw pi-chevron-up'"
  >
    @for (tab of QboTimeData; track tab; let i = $index) {
      <div
        tabindex="0"
        (keyup.enter)="setRow(i)"
        [ngClass]="{
          'div-highlight': selectedIndex === i,
          'qbo-time': existingQboPlans === null || tab.PackageCategoryName.trim() !== existingQboPlans.Type.trim()
        }"
        (click)="setRow(i)"
      >
        @if (existingQboPlans === null || tab.PackageCategoryName.trim() !== existingQboPlans.Type.trim()) {
          <p-accordionTab class="qboAccordionTab" (click)="selectionOccured(tab)">
            <p-header>
              <span class="package-name">{{ tab.PackageCategoryName }}</span>
              <span class="package-amount"
                ><div class="float-right">
                  ${{ tab.NextBillPrice | number: '1.2-2' }}
                  <p class="month-label">/month</p>
                </div></span
              >
            </p-header>
            <p class="package-content" [innerHtml]="tab.HTMPDescription | safeHtml"></p>
          </p-accordionTab>
        }
      </div>
    }
    @if (existingQboPlans === null && !isChangeSubType) {
      <div
        tabindex="0"
        (keyup.enter)="selectionOccured(100)"
        class="div-class"
        [ngClass]="{ 'div-highlight': selectedIndex === 100 }"
        (click)="selectionOccured(100)"
      >
        I don’t need QuickBooks Time
      </div>
    }
  </p-accordion>
</div>
