<div>
  <h2 class="sectionTitle">{{ OrgProfile?.PartnerName }} Purchases</h2>
  <h6 class="packages-subtext">
    <span [innerHtml]="getWlpPackageText | safeHtml"></span>
  </h6>
  <div class="row medium">
    <div class="col-md-12">
      @if (ShowPackages) {
        <div>
          <rn-grid [Configuration]="PackageTableConfiguration"></rn-grid>
        </div>
      }
      @if (ShowAddOns) {
        <div>
          <rn-grid [Configuration]="AddOnTableConfiguration"></rn-grid>
        </div>
      }

      <!--<div class="table-intuit-container" >
      <table ng-table="intuitPackageTableParams" show-filter="false" class="table-intuit table-packages" *ngIf="HasWLPPackages()">
        <tr *ngFor="item in $data | orderBy: 'PackageName'">
          <td data-title="'Packages'" class="orgPackageTableNameColumn">
            <a *ngIf="canNavigateToPackages" id="packageMgmt" (click)="openPackageDetailsWithParams(item)" *ngClass="">{{item.PackageName  | limitTo : 70 }}</a>
            <span *ngIf="!canNavigateToPackages">{{item.PackageName  | limitTo : 70 }}</span>
          </td>
          <td data-title="'Seats'" class="packageCells orgPackageTableNumberColumn">
            <span>{{item.NumberAllowed}}</span>
          </td>
          <td data-title="'Assigned'" class="packageCells orgPackageTableNumberColumn">
            <span>{{item.NumberUsed}}</span>
          </td>
          <td data-title="'Remaining'" class="packageCells orgPackageTableNumberColumn">
            <span>{{item.NumberAllowed - item.NumberUsed}}</span>
          </td>
        </tr>
      </table>-->
      <!--<table ng-table="unpurchasableAddonTableParams" show-filter="false" class="table-intuit table-packages" *ngIf="HasWLPAddOns()">
      <tr *ngFor="item in $data | orderBy: 'PackageName'">
        <td data-title="'Add-ons'" class="orgPackageTableNameColumn">
          <a *ngIf="canNavigateToPackages" id="packageMgmt" (click)="openPackageDetailsWithParams(item)" *ngClass="">{{item.PackageName  | limitTo : 70 }}</a>
          <span *ngIf="!canNavigateToPackages">{{item.PackageName  | limitTo : 70 }}</span>
        </td>
        <td data-title="'Seats'" class="packageCells orgPackageTableNumberColumn">
          <span>{{item.NumberAllowed}}</span>
        </td>
        <td data-title="'Assigned'" class="packageCells orgPackageTableNumberColumn">
          <span>{{item.NumberUsed}}</span>
        </td>
        <td data-title="'Remaining'" class="packageCells orgPackageTableNumberColumn">
          <span>{{item.NumberAllowed - item.NumberUsed}}</span>
        </td>
      </tr>
    </table>
  </div>-->
    </div>
  </div>
</div>
