<div [ngClass]="StyleClass">
  <div [ngClass]="DialogHeaderClass">
    <h3 [ngClass]="HeaderClass">{{ Title }}</h3>
    @if (ShowCloseButton) {
      <div tabindex="0" (keyup.enter)="CloseClick()" role="presentation" class="close-button" (click)="CloseClick()">
        <i class="fa-solid fa-times"></i>
      </div>
    }
    <div
      tabindex="0"
      (keyup.enter)="CloseFromBodyClick()"
      class="close-clicker dspy-none"
      (click)="CloseFromBodyClick()"
    ></div>
  </div>
  <div class="modal-body" [ngClass]="DialogBodyClass">
    @if (MessageContainsHTML) {
      <div data-testid="orgMFAconfirmMsgContainer" [innerHtml]="Message | safeHtml"></div>
    }
    @if (!MessageContainsHTML) {
      <div>
        {{ Message }}
      </div>
    }
  </div>
  @if (showDontShowAgain) {
    <div class="modal-body">
      <rw-checkbox
        [id]="'mycheckbox'"
        [className]="'rw-check-primary'"
        [isChecked]="false"
        [isDisabled]="false"
        [text]="dontShowAgainText"
        [arialabel]="'aria-label'"
        [datapendoid]="'data-pendo-id'"
        [datatestid]="'data-testid'"
        (checkboxDataEmitter)="dontShowAgainClicked($event)"
      ></rw-checkbox>
    </div>
  }
  <div class="modal-footer" [ngClass]="DialogFooterClass">
    <hr class="modal-footer-seperator" />
    <div class="flex-row no-bottom-margin">
      <div [ngClass]="DialogFooterCancelClass">
        @if (!UseButtonForCancel && !HideCancel) {
          <app-button
            id="btnCancel1"
            [label]="CancelButtonText"
            (clickActionEmitter)="CancelClick()"
            noContentPadding="cancelButtonNoContentPadding"
            buttonStyle="{{ CancelButtonStyleClass || 'addUserTeritary' }}"
          >
          </app-button>
        }
        @if (UseButtonForCancel && !HideCancel) {
          <app-button
            id="btnCancel2"
            label="{{ CancelButtonText }}"
            (clickActionEmitter)="CancelClick()"
            noContentPadding="cancelButtonNoContentPadding"
            buttonStyle="secondary"
          >
          </app-button>
        }
      </div>
      &nbsp;
      <div [ngClass]="DialogFooterConfirmClass">
        <app-button
          id="btnConfirm1"
          showIcon="{{ showConfirmIcon }}"
          imgSource="{{ showConfirmIconImageSource }}"
          imgClass="{{ confirmImageIconClass }}"
          label="{{ ConfirmButtonText }}"
          (clickActionEmitter)="ConfirmClick()"
          noContentPadding="confirmButtonNoContentPadding"
          [processing]="IsWorking"
          showProcessing="true"
          buttonStyle="{{ ConfirmButtonStyleClass }}"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>
