<div class="modal-header">
  <h3 class="modal-title">We can’t rename your {{ type }} right now</h3>
</div>
<div class="row medium m-0">
  <div class="col-md-11 rename-desc">
    We can't rename your {{ type }} because some of your users are logged in to their cloud-hosted desktops. Have these
    users log off, then try renaming your {{ type }} again:
  </div>
  <div class="user-list">
    <ul>
      @for (user of usersLoggedIn; track user) {
        <li>{{ user.OrganizationName }}: {{ user.FullName }}</li>
      }
    </ul>
  </div>
</div>
<div class="modal-footer">
  <a tabindex="0" (keyup.enter)="close()" class="p-t-xs pull-left inline_block no-href-link" (click)="close()">Close</a>
</div>
