@if (showHeader()) {
  <app-rn-header></app-rn-header>
}
<p-toast position="top-center" [baseZIndex]="99999">
  <ng-template let-message pTemplate="message">
    <p data-testid="toastMsg" [innerHtml]="message.detail | safeHtml"></p>
  </ng-template>
</p-toast>
@if (showAppLoading()) {
  <div class="resource-loader">
    <img class="dark-image" src="{{ assetUrl }}/logos/phoenix-right-networks-dark.png" alt="Rightworks" />
    <br />
    <br />
    <app-loader text="Loading AppHub resources..." inline="true"></app-loader>
  </div>
}

@if (!showAppLoading()) {
  <div [ngClass]="{ 'app-content': showSidebar() }">
    @if (showSidebar()) {
      <app-rn-sidebar></app-rn-sidebar>
    }
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <p-sidebar
      [(visible)]="visibleSidebar"
      position="right"
      [style]="{ width: '75%' }"
      class="{{ sideBarComponentStyles }} sidebarBackgroundZIndex"
      blockScroll="true"
      (onHide)="onHideSideBar(NavSideBar)"
      #NavSideBar
      [autoZIndex]="false"
    >
      @if (!destorySidebar) {
        <app-rn-right-sidebar
          [componentName]="sideBarComponentName"
          [data]="sideBarComponentData"
          [additionalData]="sideBarComponentAdditionalData"
        ></app-rn-right-sidebar>
      }
    </p-sidebar>
    @if (showConfirmation) {
      <p-sidebar
        [(visible)]="showConfirmation"
        position="right"
        [style]="{ width: '75%' }"
        blockScroll="true"
        [dismissible]="false"
        [showCloseIcon]="false"
        (onShow)="onShowConfirmation()"
        [autoZIndex]="false"
      >
        <app-rn-confirmation (confirmationResult)="receiveConfirmationResult($event)"></app-rn-confirmation>
      </p-sidebar>
    }
  </div>
}

@if (showFooter()) {
  <app-rn-footer></app-rn-footer>
}
