<div
  [ngClass]="{
    'height-99': componentName === 'app-access-management' || componentName === 'app-user-events',
    'height-99-with-overflow': componentName !== 'app-access-management' && componentName !== 'app-user-events'
  }"
>
  @if (Loading && !!componentName) {
    <app-loader text="Loading..."></app-loader>
  }
  <div>
    @switch (componentName) {
      @case ('app-manage-addons') {
        <div class="height-99-with-overflow">
          <app-manage-addons-sidebar
            [data]="data"
            (componentLoaded)="componentLoaded($event)"
          ></app-manage-addons-sidebar>
        </div>
      }
      @case ('app-access-management') {
        <div class="height-99">
          <app-access-management
            [UserProfile]="data"
            (ComponentLoaded)="componentLoaded($event)"
          ></app-access-management>
        </div>
      }
      @case ('app-manage-packages') {
        <div class="height-99-with-overflow">
          <app-manage-packages-sidebar
            [data]="data"
            (componentLoaded)="componentLoaded($event)"
          ></app-manage-packages-sidebar>
        </div>
      }
      @case ('app-user-events') {
        <div class="height-99-with-overflow">
          <app-user-events [data]="data" (componentLoaded)="componentLoaded($event)"></app-user-events>
        </div>
      }
      @case ('app-license-purchase') {
        <div class="height-99">
          <app-license-purchase [data]="data" (componentLoaded)="componentLoaded($event)"></app-license-purchase>
        </div>
      }
      @case ('app-qbo-purchase') {
        <div class="height-99">
          <app-qbo-purchase
            [data]="data"
            [additionalData]="additionalData"
            (componentLoaded)="componentLoaded($event)"
          ></app-qbo-purchase>
        </div>
      }
      @case ('app-license-assign') {
        <div class="height-99-with-overflow">
          <app-license-assign [data]="data" (componentLoaded)="componentLoaded($event)"></app-license-assign>
        </div>
      }
      @case ('app-access-management-assign') {
        <div class="height-99-with-overflow">
          <app-access-management-assign
            [data]="data"
            (componentLoaded)="componentLoaded($event)"
          ></app-access-management-assign>
        </div>
      }
    }
  </div>
</div>
