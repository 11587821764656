@if (loading) {
  <app-loader text="Loading..." class="padded" inline="true"></app-loader>
}
<p-accordion>
  @for (tab of tabs; track tab) {
    <p-accordionTab data-testid="invoiceRow" header="{{ tab.heading }}" [selected]="tab.selected">
      @if (tab.invoices.length === 0) {
        <div data-testid="noInvoiceMsg" class="text-center">No invoices found for this period</div>
      }
      @if (tab.invoices.length > 0) {
        <table data-testid="invoiceTable" class="table">
          <thead>
            <tr>
              <th data-testid="invoiceDateHeader">Invoice Date</th>
              <th data-testid="invoiceNumberHeader">Invoice Number</th>
              <th data-testid="invoicePriceHeader">Amount</th>
              <th data-testid="invoiceDownloadHeader">Download</th>
            </tr>
          </thead>
          <tbody>
            @for (invoice of tab.invoices; track invoice) {
              <tr>
                <td data-testid="invoiceDate" class="invoice-list-date-column">
                  {{ invoice.invoiceDate | date: 'MM/dd/yyyy' }}
                </td>
                <td data-testid="invoiceNumber">{{ invoice.invoiceNumber }}</td>
                <td data-testid="invoicePrice" class="invoice-list-amount-column">
                  {{ invoice.amount | currency }}
                </td>
                <td data-testid="invoiceDownload" class="invoice-list-download-column">
                  <a
                    tabindex="0"
                    (keyup.enter)="getInvoicePdf(invoice.pdfFileId, invoice.invoiceNumber, invoice.invoiceDate)"
                    (click)="getInvoicePdf(invoice.pdfFileId, invoice.invoiceNumber, invoice.invoiceDate)"
                    class="c-pointer"
                    ><i class="fa-regular fa-file-pdf"></i
                  ></a>
                </td>
              </tr>
            }
          </tbody>
        </table>
      }
    </p-accordionTab>
  }
</p-accordion>
