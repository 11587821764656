<div class="modal-content feedback_wrapper">
  <div class="justifiedRow">
    <h3 class="rn-modal-title">Send us Feedback</h3>
    <a
      tabindex="0"
      (keyup.enter)="close()"
      class="close xToClose c-pointer"
      pTooltip="Close"
      tooltipPosition="top"
      (click)="close()"
      >x</a
    >
  </div>
  <div class="row p-field">
    <p class="col-12 description">
      Thanks for taking a moment to give us feedback on AppHub. <br />If you need support, please
      <a href="https://helpdesk.rightnetworks.com/s/" target="_blank">contact us</a>.
    </p>
  </div>
  <form [formGroup]="feedbackForm">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <div
          [ngClass]="{
            'has-error': f.category.invalid && (f.submitted || f.category.touched)
          }"
        >
          <label for="category">What's your feedback about?</label>
          <app-select
            componentId="category"
            hasDefaultItem="true"
            defaultText="Pick a category"
            [items]="categories"
            value="CategoryID"
            text="CategoryName"
            [frmComponent]="feedbackForm"
            [loading]="loadingCategories"
          ></app-select>
        </div>
      </div>
      <div class="p-field p-col-12">
        <div
          [ngClass]="{
            'has-error': f.comments.invalid && (f.submitted || f.comments.touched)
          }"
        >
          <label for="comments">Comments</label>
          <textarea
            id="comments"
            formControlName="comments"
            type="text"
            rows="7"
            pInputTextarea
            placeholder="Tell us your comments, suggestions, or concerns"
          ></textarea>
          @if (f.submitted || f.comments.touched) {
            <div>
              @if (f.comments.errors?.required) {
                <div class="help-block">Required field</div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-inner">
    <app-button id="btnCancel" label="CANCEL" buttonStyle="addUserTeritary" (clickActionEmitter)="close()">
    </app-button>
    <app-button
      id="btnSendFeedback"
      [disabled]="!feedbackForm.valid"
      buttonStyle="primary"
      label="SUBMIT"
      showProcessing="true"
      [processing]="isWorking"
      (clickActionEmitter)="sendFeedback()"
    >
    </app-button>
  </div>
</div>
