<div class="detailsDetails">
  <div class="dspy-flex justifiedRow detailsHeading">
    <h5 class="licenseHeading">License Details</h5>
    <a
      data-testid="licenseManagementLnk"
      href="javascript:void(0);"
      class="no-href-link allLicensesButton"
      (click)="goBackToLicenses()"
      ><i class="fa-solid fa-chevron-left"></i>&nbsp;&nbsp;ALL LICENSES</a
    >
  </div>
  <div class="p-datatable rn-table">
    <div class="ui-table ui-widget ui-table-responsive">
      <div class="ui-table-wrapper">
        <form #editLicenseForm="ngForm" [formGroup]="formGroup">
          <table>
            <thead class="ui-table-thead">
              <tr>
                <th>License Type</th>
                <th>License Key</th>
                <th>Product Code</th>
                <th>Country</th>
                <th class="width-10">Ownership</th>
                <th class="c-align">
                  Auto Assign&nbsp;&nbsp;&nbsp;<app-icon
                    source="rn-info"
                    tooltipStyleClass="tooltipMsg"
                    pTooltip="<span data-testid='tooltipMsg'>When Auto Assign is enabled for a license, this allows for the associated QuickBooks version to be available for installation for any user with a hosting package.</span>"
                    [escape]="false"
                  ></app-icon>
                </th>
                @if (canShowActions) {
                  <th class="c-align">Actions</th>
                }
              </tr>
            </thead>
            <tbody class="ui-table-tbody">
              <tr>
                <td>
                  <span data-testid="licenseType">{{ license.DisplayName }}</span>
                </td>
                <td>
                  <span data-testid="licenseKey">{{ license.License1 }}</span>
                </td>
                <td>
                  <span data-testid="licenseProductCode">{{ license.License2 }}</span>
                </td>
                <td>
                  <span data-testid="licenseCountry">{{ license.Country }}</span>
                </td>
                <td class="width-10">
                  <span data-testid="licenseOwnership">{{ license.Type }}</span>
                </td>
                <td class="c-align">
                  <span
                    ><mat-checkbox
                      [checked]="license.IsAutoAssignable"
                      [disabled]="autoAssignInProgress"
                      (change)="toggleLicenseAutoAssign($event)"
                    ></mat-checkbox
                  ></span>
                </td>
                @if (canShowEdit) {
                  <td class="c-align">
                    <span class="editInline">
                      <app-button
                        id="licenseEditBtn"
                        (clickActionEmitter)="editLicense()"
                        label="Edit"
                        noContentPadding="true"
                        buttonStyle="teritaryEdit"
                      >
                      </app-button>
                    </span>
                  </td>
                }
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="justifiedFlexEnd">
  <app-button
    id="btnUnassign"
    buttonStyle="primary"
    [disabled]="isUnassignUsersDisabled"
    noContentPadding="true"
    label="UNASSIGN USERS"
    (clickActionEmitter)="unassignUsers()"
  >
  </app-button>

  <app-button
    id="btnAssign"
    buttonStyle="primary"
    [disabled]="!canAssignUsersToLicense"
    noContentPadding="true"
    label="ASSIGN USERS"
    (clickActionEmitter)="assignUsers()"
  >
  </app-button>
</div>
<div class="flex-row">
  <rn-grid [Configuration]="licenseGridConfiguration" (rowSelected)="rowSelected($event)"></rn-grid>
</div>
