import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import * as duo from "../../../../../assets/js/Duo-Web-v2.min.js";
import { environment } from "../../../../../environments/environment";
import { DuoDialogConfiguration } from "../../../../core/models/duo-dialog-configuration";
import { AuthenticationService } from "../../../security/authentication.service";
import { DuoService } from "../../../services/rnapi2-service/apis/api";
import {
  RnDuoSignRequestProfile,
  RnDuoVerifyResponseProfile,
} from "../../../services/rnapi2-service/models/models";

@Component({
  selector: "app-duo-dialog",
  templateUrl: "./duo-dialog.component.html",
  styleUrls: ["./duo-dialog.component.scss"],
})
export class DuoDialogComponent implements OnInit {
  private subscriptions = [];
  isDuoLoginError = false;
  isLoading = true;

  public Configuration: DuoDialogConfiguration;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private authenticationService: AuthenticationService,
    private duoService: DuoService,
  ) {
    this.Configuration = config.data;
  }

  ngOnInit(): void {
    this.duoInit();
  }

  duoInit(): void {
    const payload = new RnDuoSignRequestProfile();
    payload.UserName = this.Configuration.Email;
    function verifyResponse(signedRequest) {
      const sigResponse = signedRequest.elements.sig_response.value;

      const payload = new RnDuoVerifyResponseProfile();
      payload.SigResponse = sigResponse;
      payload.Email = this.Configuration.Email;
      payload.BaseUri = environment.baseUrl;
      this.subscriptions.push(
        this.duoService
          .apiV2DuoVerifyresponsePost(payload, "response")
          .subscribe(
            (response) => {
              if (response.body.Success) {
                // if we got this far we have a valid response
                const username = response.body.data.Username;
                // if we get back a username continue the login process
                if (username) {
                  this.authenticationService.handleTokenResponse(
                    response.body.data.Token,
                    response.body.data.SignalRConnection?.AccessToken,
                    response.body.data.SignalRConnection?.Url,
                  );
                }
              } else {
                this.isLoading = false;
                this.isDuoLoginError = true;
              }
            },
            (err) => {
              console.log("error=", err);
              this.isLoading = false;
              this.isDuoLoginError = true;
            },
          ),
      );
    }
    this.subscriptions.push(
      this.duoService.apiV2DuoSignrequestPost(payload, "response").subscribe(
        (response) => {
          if (response.body.Success) {
            const signedRequest = response.body.data.Response;

            try {
              duo.init({
                host: environment.duoInstance,
                sig_request: signedRequest,
                submit_callback: verifyResponse.bind(this),
              });
            } catch (e) {
              this.ref.close();
            }

            setTimeout(() => {
              this.isLoading = false;
            }, 2000);
          } else {
            this.isLoading = false;
            this.isDuoLoginError = true;
          }
        },
        (err) => {
          console.log("error=", err);
          this.isLoading = false;
          this.isDuoLoginError = true;
        },
      ),
    );
  }
}
