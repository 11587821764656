import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  OnDestroy,
  OnChanges,
} from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { AccessManagementConfiguration } from "../../../../core/models/access-management-configuration";
import {
  UserService,
  OrganizationService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonId,
  RnUsersProfileVMRNResponseRelay,
} from "../../../../shared/services/rnapi2-service/models/models";
import { Observable, forkJoin, fromEvent } from "rxjs";
import { HttpResponse } from "@angular/common/http";
import { RnTreeNodeData } from "../../../../core/models/RnTreeNodeData";
import {
  RnDriveMappingVMListRNResponseRelay,
  RnOrganizationTreeNodeListRNResponseRelay,
  RnOrganizationTreeNode,
  RnDriveMappingVM,
  RnUserDriveMappingsSetMultiple,
} from "../../../services/rnapi2-service/models/models";
import { RnsidebarService } from "../../../services/sidebar/rnsidebar.service";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { LoggedInInfoService } from "../../../../shared/services/loggedInInfo/logged-in-info.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-access-management",
  templateUrl: "./access-management.component.html",
  styleUrls: ["./access-management.component.scss"],
})
export class AccessManagementComponent implements OnChanges, OnDestroy, OnInit {
  @Output() ComponentLoaded: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  accessMgmtConfig: AccessManagementConfiguration =
    new AccessManagementConfiguration();
  availableDriveCount: number;
  public treeNodes: RnTreeNodeData[];
  selectedFolders: any;
  selectedNodesArray: any[] = new Array<any>();
  initialDriveMappingCount: number;
  newDriveMappingCount: number;

  private subscriptions: Subscription[] = [];
  loading = false;
  canSubmit = false;
  dialogTitle = "";
  clientAccessReadOnly: boolean;
  customZoom: boolean;

  constructor(
    private userService: UserService,
    private orgService: OrganizationService,
    private rnSidebarService: RnsidebarService,
    private loggedInInfoService: LoggedInInfoService,
    private toastService: RnToastService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogTrackService: DialogTrackingService,
  ) {
    this.clientAccessReadOnly = !this.loggedInInfoService.loggedInUserHasRights(
      ["GRNTORGACC", "REVKORGACC"],
    );
  }

  ngOnInit(): void {
    this.ComponentLoaded.emit(false);
    this.setTitle();
    this.handleBrowserZoom();
    this.accessMgmtConfig = this.config.data;
    this.setupTree();
  }

  handleBrowserZoom() {
    this.subscriptions.push(
      fromEvent(window, "resize").subscribe(() => {
        const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
        this.customZoom = browserZoomLevel > 155 ? true : false;
      }),
    );
  }

  ngOnChanges(): void {
    this.setupTree();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  private setupTree(): void {
    const init = forkJoin(
      this.loadAvailDriveMappings(),
      this.loadUsersOrgTree(),
      this.getProfile(),
    ).subscribe(
      ([driveMappingResponse, orgTreeResponse, getProfileResponse]) => {
        this.accessMgmtConfig.UserProfile = getProfileResponse.body.data;
        this.availableDriveMappings = driveMappingResponse.body.data;
        this.initialDriveMappingCount = this.availableDriveCount =
          this.availableDriveMappings?.length;
        this.orgTreeData = orgTreeResponse.body.data;
        for (const cp of this.accessMgmtConfig.UserProfile.ConnectPointData) {
          cp["Type"] = this.getConnectPointType(
            this.orgTreeData[0],
            cp.ConnectPointID,
          );
        }
        this.treeNodes = this.convertOrgTreeToUITreeNode(this.orgTreeData);
        this.setupParentNodes(this.treeNodes[0]);
        this.preSelectNodes(this.treeNodes);
        this.ComponentLoaded.emit(true);
      },
    );
    this.subscriptions.push(init);
  }

  viewSelf(): boolean {
    return (
      this.loggedInInfoService.GetLoggedInUser().UserID ===
      this.accessMgmtConfig.UserProfile.UserID
    );
  }

  getConnectPointType(node, cpID) {
    if (node.connectPointId == cpID) {
      node.mapped = true;
      return node.type;
    } else if (node.nodes && node.nodes.length > 0) {
      for (let i = 0; i < node.nodes.length; i++) {
        const childResult = this.getConnectPointType(node.nodes[i], cpID);
        if (!(childResult === null)) {
          return childResult;
        }
      }
    }
    return null;
  }

  nodeSelect(event) {
    this.setCanSubmit();
    let clickedNode = event.node;
    while (clickedNode.parent) {
      const countMapped = clickedNode.parent.children.filter((c) => {
        return c.mapped === true;
      }).length;
      if (
        countMapped > 0 ||
        (countMapped === 0 && clickedNode?.data?.status === "NotMapped")
      ) {
        if (clickedNode.parent.mapped) {
          clickedNode.parent.mapped = !clickedNode.parent.mapped;
        }
      }
      clickedNode = clickedNode.parent;
    }
    if (!clickedNode.parent) {
      if (!clickedNode.mapped) {
        clickedNode.partialSelected =
          this.selectedNodesArray && this.selectedNodesArray.length
            ? true
            : false;
      }
    }

    this.calculateMappedConnectPoints();
  }

  calculateMappedConnectPoints(): number {
    const mappedConnectPointIds = [];
    this.selectedNodesArray.forEach((connectPoint) => {
      if (
        !connectPoint?.partialSelected &&
        (connectPoint?.parent?.partialSelected ||
          connectPoint?.parent === undefined)
      ) {
        mappedConnectPointIds.push(connectPoint.data.connectPointId);
      }
    });
    this.newDriveMappingCount = mappedConnectPointIds.length;
    return (this.availableDriveCount = 21 - this.newDriveMappingCount);
  }

  preSelectNodes(tree: RnTreeNodeData[]) {
    this.setInitialChecks(tree[0]);
  }

  calcCheckBox(node) {
    this.setChildChecks(node, node.data ? node.data.mapped : node.mapped);
  }

  setInitialChecks(node) {
    if (
      node.parent === undefined &&
      (node.data.mapped || node.mapped) &&
      this.accessMgmtConfig.UserProfile.UserType == "Account Owner"
    ) {
      // Preventing Account Owners from unmapping themselves from their org's default connect point
      node.selectable = false;
    }
    if (node.data.mapped || node.mapped) {
      this.calcCheckBox(node);
    } else {
      if (node.children.length > 0) {
        const allChildren = this.getAllChildren(node);
        const mappedChildren = allChildren.filter((child) => {
          return child.data ? child.data.mapped : child.mapped;
        });
        node.partialSelected =
          mappedChildren.length > 0 &&
          (mappedChildren.length != allChildren.length || node.partialSelected);
        node.children.forEach((n) => {
          this.setInitialChecks(n);
        });
      }
    }
    if (!node.mapped && !node.partialSelected && this.clientAccessReadOnly) {
      node.styleClass = "dspy-none";
    }
  }

  getAllChildren(node) {
    let children = [];
    if (node.data) {
      for (let i = 0; i < node.children.length; i++) {
        const childNode = node.data.nodes[i];
        children.push(childNode);
        const subchildNodes = this.getAllChildren(childNode);
        children = children.concat(subchildNodes);
      }
    }

    return children;
  }

  setChildChecks(node: RnTreeNodeData, mapped: boolean) {
    node.mapped = mapped;
    let indeterminate = false;
    if (node.data.nodes.length > 0 || node.children.length > 0) {
      const allChildren = this.getAllChildren(node);

      const mappedChildren = allChildren.filter((child) => {
        return child.data ? child.data.mapped : child.mapped;
      });
      indeterminate =
        allChildren.length > mappedChildren.length && mappedChildren.length > 0;
    }
    if (indeterminate) {
      node.partialSelected = true;
    } else {
      if (node.mapped) {
        this.selectedNodesArray.push(node);
      } else {
        node.partialSelected = true;
      }
    }
    if (node.data.nodes.length > 0) {
      node.data.nodes.forEach((n) => {
        this.setChildChecks(n, mapped);
      });
    }
    if (!node.mapped && !node.partialSelected && this.clientAccessReadOnly) {
      node.styleClass = "dspy-none";
    }
  }

  setTitle(): void {
    if (this.clientAccessReadOnly) this.dialogTitle = "Client Access";
    else this.dialogTitle = "Manage Client Access";
  }

  getReadOnlyDesc(): string {
    if (this.viewSelf())
      return "These are the organization and folders you have access to on your hosted desktop.";
    else
      return `These are the organization and folders ${this.getUserFullName()} has access to on their hosted desktop.`;
  }

  dismiss() {
    this.dialogTrackService.closeDialog(this.ref, null);
  }

  getUserFullName(): string {
    return (
      this.accessMgmtConfig.UserProfile.FirstName +
      " " +
      this.accessMgmtConfig.UserProfile.LastName
    );
  }

  setCanSubmit() {
    const removedConnectPoints = this.initialConnectPoints.filter((item) => {
      return this.selectedNodesArray.indexOf(item) === -1;
    });
    this.calculateMappedConnectPoints();

    if (
      (removedConnectPoints.length > 0 ||
        this.initialConnectPoints.length !== this.selectedNodesArray.length ||
        this.availableDriveCount !== this.initialDriveMappingCount) &&
      this.newDriveMappingCount <= 21
    ) {
      this.canSubmit = true;
    } else {
      this.canSubmit = false;
    }
  }

  saveChanges() {
    this.loading = true;
    this.canSubmit = false;
    const removedConnectPointIds = [];
    this.initialConnectPoints.forEach((connectPoint) => {
      if (this.selectedNodesArray.indexOf(connectPoint) === -1) {
        removedConnectPointIds.push(connectPoint.data.connectPointId);
      }
    });

    const mappedConnectPointIds = [];
    this.selectedNodesArray.forEach((connectPoint) => {
      if (
        !connectPoint?.partialSelected &&
        connectPoint?.parent?.partialSelected
      ) {
        mappedConnectPointIds.push(connectPoint.data.connectPointId);
      } else if (connectPoint?.parent == null) {
        // For AP-639, if the top most node is selected, we want to make sure that gets pushed into the nodes to map
        // The previous if statement was ignoring it.
        mappedConnectPointIds.push(connectPoint.data.connectPointId);
      } else removedConnectPointIds.push(connectPoint.data.connectPointId);
    });

    const payload: RnUserDriveMappingsSetMultiple = {
      UserId: this.accessMgmtConfig.UserProfile.UserID,
      AddConnectPointIds: mappedConnectPointIds,
      RemoveConnectPointIds: removedConnectPointIds,
    };
    this.userService
      .apiV2UsersSetdrivemappingsPost(payload)
      .subscribe((response) => {
        this.toastService.showSuccess("Successfully saved changes");
        this.rnSidebarService.refreshUser();
        this.dialogTrackService.closeDialog(this.ref, null);
        this.loading = false;
      });
  }

  private loadAvailDriveMappings(): Observable<
    HttpResponse<RnDriveMappingVMListRNResponseRelay>
  > {
    const payload = new RnCommonId();
    payload.Id = this.accessMgmtConfig.UserProfile.UserID.toString();
    return this.userService.apiV2UsersGetavailabledrivemappingsPost(
      payload,
      "response",
    );
  }

  private loadUsersOrgTree(): Observable<
    HttpResponse<RnOrganizationTreeNodeListRNResponseRelay>
  > {
    const payload = new RnCommonId();
    payload.AffectedOrganizationId =
      this.accessMgmtConfig?.UserProfile?.OrganizationID;
    payload.Id = this.accessMgmtConfig?.UserProfile?.UserID.toString();
    return this.orgService.apiV2OrganizationsSuborgtreewithstatusPost(
      payload,
      "response",
    );
  }

  private convertOrgTreeToUITreeNode(
    rnTreeNode: RnOrganizationTreeNode[],
  ): RnTreeNodeData[] {
    const result = [];
    for (let i = 0; i < rnTreeNode.length; i++) {
      const orgInfo = rnTreeNode[i];
      const node = new RnTreeNodeData();
      node.label = orgInfo.name;
      node.data = orgInfo;
      node.expandedIcon =
        orgInfo.type === "org" ? "fa fa-building" : "pi pi-folder-open";
      node.collapsedIcon =
        orgInfo.type === "org" ? "fa fa-building" : "pi pi-folder";
      node.children =
        orgInfo.nodes.length > 0
          ? this.convertOrgTreeToUITreeNode(orgInfo.nodes)
          : [];
      node.data.nodes = node.children;
      node.partialSelected =
        orgInfo.status === "PartiallyMapped" ? true : false;
      node.parent = rnTreeNode[i]["parent"];
      node.expanded = true;
      result.push(node);
    }
    return result;
  }

  private setupParentNodes(
    currentNode: RnTreeNodeData,
    parentNode?: RnTreeNodeData,
  ): void {
    if (currentNode.data.status !== "PartiallyMapped") {
      if (
        currentNode.data.mapped ||
        currentNode.mapped ||
        (parentNode &&
          parentNode.data &&
          (parentNode.data.mapped || parentNode.mapped))
      ) {
        this.initialConnectPoints.push(currentNode);
      }
    }

    if (currentNode.data.status === "PartiallyMapped") {
      currentNode.partialSelected = true;
    }
    if (currentNode.children.length > 0) {
      for (let i = 0; i < currentNode.children.length; i++) {
        const node = currentNode.children[i];
        if (currentNode.data.mapped || currentNode.mapped)
          node.data.mapped = true;
        this.setupParentNodes(node, currentNode);
      }
    }
  }

  private getProfile(): Observable<
    HttpResponse<RnUsersProfileVMRNResponseRelay>
  > {
    const getUserPayload = new RnCommonId();
    getUserPayload.Id = this.accessMgmtConfig.UserProfile.UserID.toString();
    return this.userService.apiV2UsersGetuserPost(getUserPayload, "response");
  }

  private availableDriveMappings: RnDriveMappingVM[];
  private orgTreeData: RnOrganizationTreeNode[];
  private initialConnectPoints: RnTreeNodeData[] = [];
}
