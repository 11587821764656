<div class="adduser-modal-window">
  @if (dataLoaded) {
    <app-loader text="Loading..."></app-loader>
  }
  <div class="firstLine"></div>
  <div
    [ngClass]="{
      greyLine: currentStep === 1,
      blueLine: currentStep === 2,
      blueLine1: currentStep === 3,
      blueLine2: currentStep === 4,
      blueLine3: currentStep === 5
    }"
  ></div>
  <div
    [ngClass]="{
      greyLine: currentStep === 1,
      greyLine1: currentStep === 2,
      blueLine1: currentStep === 3,
      blueLine2: currentStep === 4,
      blueLine3: currentStep === 5
    }"
  ></div>
  <div
    [ngClass]="{
      greyLine: currentStep === 1,
      greyLine1: currentStep === 2,
      greyLine2: currentStep === 3,
      blueLine2: currentStep === 4,
      blueLine3: currentStep === 5
    }"
  ></div>
  <div class="modal-content">
    <div id="createUserHeader" class="dspy-block">
      <div class="justifiedTitlebar">
        <h3 class="header-qbo">{{ steps[currentStep - 1].title }}</h3>
      </div>
    </div>
    <div class="modal-body" scroll-to-top-when="step_changed">
      <div class="m-b-10 qbo-desc">
        <span class="step-desc qbo-step-desc" [innerHtml]="steps[currentStep - 1].desc"></span>
      </div>
      <form [formGroup]="mainForm">
        <app-qbo-plans
          [plansForm]="mainForm.get('QboPlans')"
          [hidden]="currentStep !== 1"
          (QboPlansSelected)="qboPlanSelected($event)"
          [qboPlansData]="QboPlansData"
          [qboExistingPlan]="existingItem"
          [isChangeSub]="isChangeSubscription"
        ></app-qbo-plans>
        <app-qbo-payroll
          [payrollForm]="mainForm.get('QboPayroll')"
          [hidden]="currentStep !== 2"
          (QboPayrollSelected)="qboPayrollSelected($event)"
          [qboPayrollData]="QboPayrollData"
          [qboExistingPlan]="existingItem"
          [isChangeSub]="isChangeSubscription"
        ></app-qbo-payroll>
        <app-qbo-time
          [timeForm]="mainForm.get('QboTime')"
          [hidden]="currentStep !== 3"
          (QboTimeSelected)="qboTimeSelected($event)"
          [qboTimeData]="QboTimeData"
          [qboExistingPlan]="existingItem"
          [isChangeSub]="isChangeSubscription"
        ></app-qbo-time>
        <app-qbo-checkout
          [checkoutForm]="mainForm.get('QboCheckout')"
          [qboCheckout]="checkoutData"
          [hidden]="currentStep !== 4"
        ></app-qbo-checkout>
        @if (currentStep === 5) {
          <app-qbo-confirmation
            [confirmationForm]="mainForm.get('QboConfirmation')"
            [qboCheckout]="checkoutData"
          ></app-qbo-confirmation>
        }
      </form>
    </div>
    <div class="button-container">
      @if (
        !(
          currentStep === 1 ||
          currentStep === 5 ||
          (startingStep === 2 && currentStep === 2) ||
          (isChangeSubscription && startingStep === 2 && currentStep === 2) ||
          (isChangeSubscription && startingStep === 3 && currentStep === 3)
        )
      ) {
        <app-button
          id="btnPrevious"
          label="BACK"
          buttonStyle="addUserTeritary"
          noContentPadding="true"
          (clickActionEmitter)="handlePrevious()"
        >
        </app-button>
      }
      @if (!(currentStep === 5)) {
        <app-button
          id="btnNext"
          [label]="currentStep | rnPurchaseQbo: 'getNextLabel' : steps.length"
          buttonStyle="primary"
          [disabled]="
            !(
              (checkoutData.length > 0 && currentStep === 1) ||
              ((checkoutData.length > 0 || iDontNeedPayroll) && startingStep === 2 && currentStep === 2) ||
              (checkoutData.length > 0 && iDontNeedPayroll && currentStep === 2) ||
              (checkoutData.length > 1 && currentStep === 2) ||
              (checkoutData.length > 0 && iDontNeedPayroll && startingStep === 2 && currentStep === 3) ||
              (checkoutData.length > 0 && iDontNeedPayroll && iDontNeedTime && currentStep === 3) ||
              (checkoutData.length > 1 && (iDontNeedPayroll || iDontNeedTime) && currentStep === 3) ||
              (checkoutData.length > 0 && startingStep === 3 && currentStep === 3) ||
              currentStep === 4
            )
          "
          [processing]="processing()"
          showProcessing="true"
          noContentPadding="true"
          (clickActionEmitter)="handleNext()"
        >
        </app-button>
      }
    </div>
  </div>
</div>
