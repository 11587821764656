<form [formGroup]="frmGroup">
  <div class="container-fluid modal-content">
    <div class="row modal-header">
      <div class="row">
        <div class="col-12 billing_form_wrapper">
          <h4 class="modal-title"><b>Billing Information</b></h4>
        </div>
      </div>
    </div>
    <div class="vertically-scrollable">
      <div class="row p-2">
        <div class="col-8">
          <span><strong>Organization: </strong> {{ orgName }}</span>
          <br />
          <span>{{ billingInfoConst }}</span>
        </div>
        @if (wlpBillingInfoMsg) {
          <div class="col-4">
            <p class="well position-abs m-t-neg9" [innerHtml]="wlpBillingInfoMsg"></p>
          </div>
        }
      </div>

      @if (canEditPaymentMethodType) {
        <div class="row p-2">
          <div class="col-12">
            <span class="text_14">Select a payment method</span>
            <mat-radio-group name="paymentMethod" formControlName="paymentMethod">
              <div class="p-field-radiobutton col-6 pl-0">
                <mat-radio-button
                  value="CC"
                  (keypress)="checkSpaceClicked($event)"
                  (click)="setPaymentMethodValidators()"
                  id="CC"
                  name="paymentMethod"
                  >Credit Card</mat-radio-button
                >
              </div>
              <div class="p-field-radiobutton col-6 pl-0">
                <mat-radio-button value="ACH" id="ACH" name="paymentMethod" (click)="setPaymentMethodValidators()"
                  >ACH</mat-radio-button
                >
              </div>
            </mat-radio-group>
          </div>
        </div>
      }

      <div class="row p-2">
        <div class="col-8">
          @if (paymentMethod.value === 'CC') {
            <app-payment-form-cc [parentForm]="frmGroup" [submitted]="submitted"></app-payment-form-cc>
          }
          @if (paymentMethod.value === 'ACH') {
            <app-payment-form-ach [parentForm]="frmGroup" [submitted]="submitted"></app-payment-form-ach>
          }
        </div>
      </div>

      <div class="row p-2 pt-4">
        <div class="col-8 d-flex">
          <mat-checkbox
            data-testid="sameBillingAddress"
            id="sameBillingAddress"
            name="sameBillingAddress"
            (change)="onChangeSameBillingAddress($event.checked)"
          >
            Billing Address is the same as my Business Address
          </mat-checkbox>
        </div>
      </div>

      <div class="row p-2">
        <div class="col-8">
          <app-business-address
            [address]="address"
            [parentForm]="frmGroup"
            [submitted]="submitted"
          ></app-business-address>
        </div>
      </div>
    </div>
    <div class="row p-2 billing_footer">
      <div class="col-12">
        <div class="modal-footer">
          <span tooltipStyleClass="wideTooltipMed" tooltipPosition="top" [pTooltip]="cancelTooltip">
            @if (billingFormData.missingBillingInfoOnLogin) {
              <a data-testid="cancellink" class="no-href-link" style="cursor: not-allowed">Cancel</a>
            }
          </span>
          @if (!this.billingFormData.missingBillingInfoOnLogin) {
            <app-button id="btnCancel" label="CANCEL" buttonStyle="addUserTeritary" (clickActionEmitter)="close()">
            </app-button>
          }
          <app-button
            id="btnChangePackagesandAddOns"
            buttonStyle="primary"
            [disabled]="
              (paymentMethod.value === 'CC' && !validCCData()) ||
              (paymentMethod.value === 'ACH' && !validACHData()) ||
              submitted
            "
            [label]="submitButtonText"
            (clickActionEmitter)="onSubmit()"
            showProcessing="true"
            [processing]="submitted"
          >
          </app-button>
        </div>
      </div>
    </div>
  </div>
</form>
