<div class="card app-tile" [ngClass]="{ 'new-card': canUseNewTiles }">
  <div class="card-body app-tile-body" [ngClass]="{ 'p-0': canUseNewTiles }">
    <mat-card-title>
      <div class="icon-frame" [ngClass]="{ 'new-icon-frame-size': canUseNewTiles }">
        <img
          src="{{ card.AppImageURL }}"
          alt="application-logo"
          class="app-icon"
          [ngClass]="{ 'card-img': canUseNewTiles }"
        />
      </div>
      @if (!canUseNewTiles) {
        <div>
          <mat-card-title-text>
            <p class="card-text app-name-no-hover">{{ card?.AppName }}</p>
          </mat-card-title-text>
        </div>
      }
      @if (canUseNewTiles) {
        <div>
          <mat-card-title-text>
            <div class="row">
              <div class="col-12 p-0">
                <p
                  class="card-text app-name-no-hover card-title"
                  style="width: 100%"
                  [pTooltip]="card?.AppName"
                  [tooltipPosition]="'top'"
                >
                  {{ card?.AppName }}
                </p>
              </div>
            </div>
          </mat-card-title-text>
        </div>
      }
    </mat-card-title>
    <mat-card-actions>
      @if (!canUseNewTiles) {
        <div class="button-container">
          <app-button
            id="btnDownload"
            label="DOWNLOAD"
            buttonStyle="secondary"
            (clickActionEmitter)="install()"
            width="224px"
          >
          </app-button>
        </div>
      }
      @if (canUseNewTiles) {
        <div class="button-container">
          <app-button
            id="btnDownload"
            label="DOWNLOAD"
            buttonStyle="changeSeatCount"
            (clickActionEmitter)="install()"
            noContentPadding="true"
          >
          </app-button>
        </div>
      }
    </mat-card-actions>
  </div>
</div>
