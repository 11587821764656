<div class="modal-content">
  <div class="modal-header">
    <p [innerHTML]="pageTitle"></p>
    <div>
      <button type="button" class="close pullUpperRight" (click)="close()" aria-hidden="true">
        <span uib-tooltip="Close" tooltipPosition="top" pTooltip="Close">×</span>
      </button>
    </div>
  </div>
  <hr class="headerSeperator" />
  <form [formGroup]="frmGroup">
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div
            class="p-field"
            [ngClass]="{
              'has-error': !businessName?.valid && (submitted || businessName?.touched)
            }"
          >
            <label for="businessName">Business Name</label><br />
            <input
              #businessNamelInput
              appTrimInput
              data-testid="businessName"
              id="businessName"
              name="businessName"
              [ngClass]="{ 'ui-inputtext-notallowed': orgNameDisabled }"
              formControlName="businessName"
              class="ui-inputtext form-control"
              placeholder="Business Name"
              pInputText
              [readonly]="orgNameDisabled"
              maxlength="64"
            />
            @if (submitted || businessName?.touched) {
              <div>
                @if (businessName.errors?.required || businessName.errors?.whitespace) {
                  <div class="help-block">Required field</div>
                }
              </div>
            }
            @if (orgNameDisabled) {
              <div>{{ canNotEditOrgNameMessage }}</div>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-business-address
            [isReadOnly]="readOnly"
            [address]="address"
            [parentForm]="frmGroup"
            [submitted]="submitted"
          >
          </app-business-address>
        </div>
      </div>
      <div class="row pt-1">
        <div class="col-12">
          <div
            class="p-field"
            [ngClass]="{
              'has-error': !xid?.valid && (submitted || xid?.touched)
            }"
          >
            <label for="xid">XID (optional) </label>&nbsp;<app-icon
              source="rn-help"
              tooltipStyleClass="swTooltipBottom"
              tooltip-placement="right"
              tooltipZIndex="20000"
              [pTooltip]="xidHelp"
              [escape]="false"
            ></app-icon
            ><br />
            <input
              appTrimInput
              id="xid"
              name="xid"
              formControlName="xid"
              type="text"
              placeholder="XID"
              pInputText
              class="form-control"
            />
            @if (submitted || xid?.touched) {
              <div>
                @if (xid?.errors?.maxlength) {
                  <div class="help-block">XID is too long (must be 50 characters or less)</div>
                }
              </div>
            }
          </div>
        </div>
      </div>
      @if (canShowBusinessIndustry) {
        <div>
          <div class="row">
            <div class="col-12">
              <div
                class="p-field"
                [ngClass]="{
                  'has-error': !segment?.valid && (submitted || segment?.touched)
                }"
              >
                <span class="text_14">Business Industry</span>
                @for (seg of segments; track seg) {
                  <div>
                    <input data-testid="{{ seg }}Option" type="radio" formControlName="segment" [value]="seg" />
                    <label for="{{ seg }}">{{ seg === 'Accounting Firm' ? 'Accounting/Tax' : 'Other' }}</label>
                  </div>
                }
              </div>
            </div>
          </div>
          @if (segment.value === 'SMB' && !isLoadingIndustry) {
            <div class="row">
              <div class="col-12">
                <div
                  class="p-field"
                  [ngClass]="{
                    'has-error': !industry?.valid && (submitted || industry?.touched)
                  }"
                >
                  <span class="text_14">Business Industry</span>
                  <rw-single-select
                    [id]="'industry'"
                    [items]="industries"
                    [bindLabel]="'IndustryName'"
                    [bindValue]="'IndustryName'"
                    [placeholder]="'Select Industry'"
                    [arialabel]="'Select Industry'"
                    [datapendoid]="'selectIndustry'"
                    [datatestid]="'selectIndustry'"
                    searchable="false"
                    formControlName="industry"
                  ></rw-single-select>
                  @if (industry.value === 'Other') {
                    <div>
                      <input
                        class="form-control other-industry-input"
                        type="text"
                        formControlName="otherIndustryTypeName"
                        placeholder="Other Industry..."
                        maxlength="50"
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
          }
          @if (isLoadingIndustry) {
            <div class="smallLoading">
              @if (isLoadingIndustry) {
                <app-loader text="Loading..." inline="true"></app-loader>
              }
            </div>
          }
        </div>
      }
    </div>
    <div class="modal-footer right">
      <app-button
        id="btnCancel"
        label="CANCEL"
        noContentPadding="true"
        buttonStyle="addUserTeritary"
        (clickActionEmitter)="close()"
      >
      </app-button>
      <app-button
        id="btnSubmit"
        [label]="normalButtonText"
        [disabled]="submitted || !frmGroup.valid || this.frmGroup.pristine"
        showProcessing="true"
        noContentPadding="true"
        [processing]="working"
        buttonStyle="primary"
        (clickActionEmitter)="onSubmit()"
      >
      </app-button>
    </div>
  </form>
</div>
