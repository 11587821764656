/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec } from "@angular/common/http";
import { CustomHttpParameterCodec } from "../encoder";
import { Observable } from "rxjs";

import { RnCommonIdMulti } from "../models/models";
import { RnCommonMultipleId } from "../models/models";
import { RnCommonSearch } from "../models/models";
import { RnLicenseBaseVMListRNResponseRelay } from "../models/models";
import { RnLicenseGetCatalog } from "../models/models";
import { RnLicenseUpdateDTO } from "../models/models";
import { RnLicensesCreate } from "../models/models";
import { RnLicensesWithUsersVMListRNResponseRelay } from "../models/models";
import { RnObjectRNResponseRelay } from "../models/models";
import { RnRNResponseMulti } from "../models/models";
import { RnUserLicensesAssignUsers } from "../models/models";
import { RnUserLicensesRemoveUser } from "../models/models";
import { RnUsersForOrgSubOrgSearchForLicenseVMSearchResultsVMRNResponseRelay } from "../models/models";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable({
  providedIn: "root",
})
export class LicenseService {
  protected basePath = "http://localhost";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== "string") {
      if (typeof basePath !== "string") {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string,
  ): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string,
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)),
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            value.toISOString().substr(0, 10),
          );
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k,
            )),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

  /**
   * Assign license to a user
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnUserLicensesAssignUsers Contains information to create the new license
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LicensesAssignlicensestousersPost(
    rnUserLicensesAssignUsers?: RnUserLicensesAssignUsers,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2LicensesAssignlicensestousersPost(
    rnUserLicensesAssignUsers?: RnUserLicensesAssignUsers,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2LicensesAssignlicensestousersPost(
    rnUserLicensesAssignUsers?: RnUserLicensesAssignUsers,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2LicensesAssignlicensestousersPost(
    rnUserLicensesAssignUsers?: RnUserLicensesAssignUsers,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/licenses/assignlicensestousers`,
      rnUserLicensesAssignUsers,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Delete licenses on an org
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonMultipleId Contains a list of license Ids to delete from an org
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LicensesCancelmultiplePost(
    rnCommonMultipleId?: RnCommonMultipleId,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnRNResponseMulti>;
  public apiV2LicensesCancelmultiplePost(
    rnCommonMultipleId?: RnCommonMultipleId,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnRNResponseMulti>>;
  public apiV2LicensesCancelmultiplePost(
    rnCommonMultipleId?: RnCommonMultipleId,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnRNResponseMulti>>;
  public apiV2LicensesCancelmultiplePost(
    rnCommonMultipleId?: RnCommonMultipleId,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnRNResponseMulti>(
      `${this.configuration.basePath}/api/v2/licenses/cancelmultiple`,
      rnCommonMultipleId,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Adds a new license to an org
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnLicensesCreate Contains information to create the new license
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LicensesCreatePost(
    rnLicensesCreate?: RnLicensesCreate,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2LicensesCreatePost(
    rnLicensesCreate?: RnLicensesCreate,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2LicensesCreatePost(
    rnLicensesCreate?: RnLicensesCreate,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2LicensesCreatePost(
    rnLicensesCreate?: RnLicensesCreate,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/licenses/create`,
      rnLicensesCreate,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get a list of licenses for an org
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnLicenseGetCatalog Contains the ID of the org
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LicensesGetlicenseitemsPost(
    rnLicenseGetCatalog?: RnLicenseGetCatalog,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnLicenseBaseVMListRNResponseRelay>;
  public apiV2LicensesGetlicenseitemsPost(
    rnLicenseGetCatalog?: RnLicenseGetCatalog,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnLicenseBaseVMListRNResponseRelay>>;
  public apiV2LicensesGetlicenseitemsPost(
    rnLicenseGetCatalog?: RnLicenseGetCatalog,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnLicenseBaseVMListRNResponseRelay>>;
  public apiV2LicensesGetlicenseitemsPost(
    rnLicenseGetCatalog?: RnLicenseGetCatalog,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnLicenseBaseVMListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/licenses/getlicenseitems`,
      rnLicenseGetCatalog,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get a list of users of a license
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonSearch Contains the ID of the license
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LicensesGetlicenseusersPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnUsersForOrgSubOrgSearchForLicenseVMSearchResultsVMRNResponseRelay>;
  public apiV2LicensesGetlicenseusersPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnUsersForOrgSubOrgSearchForLicenseVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2LicensesGetlicenseusersPost(
    rnCommonSearch?: RnCommonSearch,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpEvent<RnUsersForOrgSubOrgSearchForLicenseVMSearchResultsVMRNResponseRelay>
  >;
  public apiV2LicensesGetlicenseusersPost(
    rnCommonSearch?: RnCommonSearch,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnUsersForOrgSubOrgSearchForLicenseVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/licenses/getlicenseusers`,
      rnCommonSearch,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Get Licenses By Id )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnCommonIdMulti
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LicensesLicensesgetbyidPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnLicensesWithUsersVMListRNResponseRelay>;
  public apiV2LicensesLicensesgetbyidPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnLicensesWithUsersVMListRNResponseRelay>>;
  public apiV2LicensesLicensesgetbyidPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnLicensesWithUsersVMListRNResponseRelay>>;
  public apiV2LicensesLicensesgetbyidPost(
    rnCommonIdMulti?: RnCommonIdMulti,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnLicensesWithUsersVMListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/licenses/licensesgetbyid`,
      rnCommonIdMulti,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Unassign a user from license
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnUserLicensesRemoveUser Contains the ID of the license and list of users
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LicensesRemovelicensefromusersPost(
    rnUserLicensesRemoveUser?: RnUserLicensesRemoveUser,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2LicensesRemovelicensefromusersPost(
    rnUserLicensesRemoveUser?: RnUserLicensesRemoveUser,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2LicensesRemovelicensefromusersPost(
    rnUserLicensesRemoveUser?: RnUserLicensesRemoveUser,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2LicensesRemovelicensefromusersPost(
    rnUserLicensesRemoveUser?: RnUserLicensesRemoveUser,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/licenses/removelicensefromusers`,
      rnUserLicensesRemoveUser,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Update license details
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param rnLicenseUpdateDTO Contains the ID of the license and dictionary of updated items
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LicensesUpdatePost(
    rnLicenseUpdateDTO?: RnLicenseUpdateDTO,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnObjectRNResponseRelay>;
  public apiV2LicensesUpdatePost(
    rnLicenseUpdateDTO?: RnLicenseUpdateDTO,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnObjectRNResponseRelay>>;
  public apiV2LicensesUpdatePost(
    rnLicenseUpdateDTO?: RnLicenseUpdateDTO,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnObjectRNResponseRelay>>;
  public apiV2LicensesUpdatePost(
    rnLicenseUpdateDTO?: RnLicenseUpdateDTO,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnObjectRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/licenses/update`,
      rnLicenseUpdateDTO,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
