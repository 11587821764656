<div [formGroup]="userDetailsForm">
  <div class="userDetailSection">
    <h3>User Type</h3>
  </div>
  <div class="ui-fluid">
    <div
      [ngClass]="{
        'has-error': !f.role.valid && (f.submitted || f.role.touched)
      }"
    >
      <label for="role">
        Role &nbsp;
        <app-icon
          source="rn-help"
          tooltipPlacement="right"
          [pTooltip]="rolesTooltip"
          tooltipStyleClass="wideTooltipLg"
          [escape]="false"
        ></app-icon>
      </label>
      <app-select
        componentId="role"
        [items]="data.UserTypes"
        text="Name"
        [userClass]="'addUserClass'"
        [frmComponent]="userDetailsForm"
        required
      >
      </app-select>
      @if (f.submitted || f.role.touched) {
        <div>
          @if (f.role.errors?.required) {
            <div class="help-block">Required</div>
          }
        </div>
      }
    </div>
  </div>
  <div class="ui-fluid">
    <div
      class="p-field firstNameDiv"
      [ngClass]="{
        'has-error': !f.firstName.valid && (f.submitted || f.firstName.touched)
      }"
    >
      <label for="firstName">First Name</label>
      <input
        class="form-control ui-inputtext"
        formControlName="firstName"
        placeholder="First Name"
        maxlength="40"
        required
        data-testid="userfnameedit"
        (focus)="$event.target.select()"
      />
      @if (f.submitted || f.firstName.touched) {
        <div>
          @if (f.firstName.errors?.required) {
            <div data-testid="firstNameError" class="help-block">Required field</div>
          }
        </div>
      }
    </div>
    <div
      class="p-field"
      [ngClass]="{
        'has-error': !f.lastName.valid && (f.submitted || f.lastName.touched)
      }"
    >
      <label for="lastName">Last Name</label>
      <input
        class="form-control ui-inputtext"
        placeholder="Last Name"
        formControlName="lastName"
        maxlength="50"
        required
        data-testid="userlnameedit"
        (focus)="$event.target.select()"
      />
      @if (f.submitted || f.lastName.touched) {
        <div>
          @if (f.lastName.errors?.required) {
            <div data-testid="lastNameError" class="help-block">Required field</div>
          }
        </div>
      }
    </div>
    <app-email-verfication
      [userDetailsForm]="userDetailsForm"
      [emailInputClass]="'userEmailClass'"
      [inEditingMode]="true"
      data-testid="useremailedit"
      (pendingValidationEvent)="pendingValidationChanged($event)"
    ></app-email-verfication>
    <div class="p-field m-b-6" [ngClass]="{ 'has-error': !confirmEmail.valid && confirmEmail.touched }">
      <label for="email">Confirm Email Address</label>
      <input
        type="text"
        class="form-control ui-inputtext"
        placeholder="Confirm Email Address"
        formControlName="confirmEmail"
        (focus)="$event.target.select()"
        data-testid="userEmailConfirm"
      />
      @if (!confirmEmail.valid && confirmEmail.touched) {
        <div>
          @if (confirmEmail.errors?.required) {
            <div data-testid="confirmEmailError" class="help-block">Required field</div>
          }
          @if (confirmEmail.errors?.mustMatch) {
            <div data-testid="missMatchError" class="help-block">Email Addresses Must Match</div>
          }
        </div>
      }
    </div>
  </div>
  <div class="userDetailSection">
    <h3 class="panel-title" ng-if="!selectedOrgConfiguration.IsInternal">Additional Settings</h3>
  </div>
  <div class="m-b-1rem" [ngClass]="{ 'has-error': !f.xid.valid && (f.submitted || f.xid.touched) }">
    <label for="xid">
      XID (optional)&nbsp;
      <app-icon source="rn-help" tooltipPlacement="right" [pTooltip]="xidHelp" [escape]="false"></app-icon>
    </label>
    <input
      class="form-control ui-inputtext"
      placeholder="XID (Optional)"
      formControlName="xid"
      data-testid="userxidedit"
      (focus)="$event.target.select()"
    />
    @if (f.submitted || f.xid.touched) {
      <div>
        @if (f.xid.errors?.maxlength) {
          <div class="help-block">XID is too long (must be 50 characters or less)</div>
        }
      </div>
    }
  </div>
  <div>
    <div class="dspy-flex">
      <input
        id="sendWelcomeEmail"
        class="sendWelcomeEmailIndent"
        type="checkbox"
        formControlName="sendWelcomeEmail"
        label="Send this user their Welcome email after they are created"
        data-testid="usersendwelcomeemailcheckbox"
      /><label for="sendWelcomeEmail" class="sendWelcomeEmailLabel"
        >Send this user their Welcome email after they are created.</label
      >
    </div>
  </div>
</div>
