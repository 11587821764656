<div class="modal-content">
  <div class="modal-header">
    <h3 class="modal-title">{{ actionTitle }}</h3>
  </div>
  <div class="modal-body">
    <div class="row">
      @if (selectedUsers && selectedUsers.length === 1) {
        <div>
          <h4 [innerHtml]="actionHeader"></h4>
        </div>
      }
      @if (selectedUsers && selectedUsers.length !== 1) {
        <div>
          <h4>{{ actionHeader }}</h4>
          <ul>
            @for (user of selectedUsers; track user) {
              <li>
                <b>{{ user.name }}</b> (<i>{{ user.email }}</i
                >)
              </li>
            }
          </ul>
        </div>
      }
    </div>
  </div>
  <div class="modal-footer">
    <app-button id="btnClose" buttonStyle="secondary" label="Close" (clickActionEmitter)="close()"> </app-button>
  </div>
</div>
