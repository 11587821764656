/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec } from "@angular/common/http";
import { CustomHttpParameterCodec } from "../encoder";
import { Observable } from "rxjs";

import { RnHCAppsPayloadAddNewApp } from "../models/models";
import { RnHCAppsPayloadModifyPackages } from "../models/models";
import { RnHCAppsPayloadUpdateApp } from "../models/models";
import { RnHCAppsResponsePayloadApplicationRNResponseRelay } from "../models/models";
import { RnHCAppsResponsePayloadApplicationTypeListRNResponseRelay } from "../models/models";
import { RnHCAppsResponsePayloadListRNResponseRelay } from "../models/models";
import { RnHCPackageResponsePayloadListRNResponseRelay } from "../models/models";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable({
  providedIn: "root",
})
export class ApplicationsService {
  protected basePath = "http://localhost";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== "string") {
      if (typeof basePath !== "string") {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string,
  ): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string,
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)),
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            value.toISOString().substr(0, 10),
          );
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k,
            )),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

  /**
   * ( Creates a new application )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Apps_App_Writer
   * @param rnHCAppsPayloadAddNewApp
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2AppsAddPost(
    rnHCAppsPayloadAddNewApp?: RnHCAppsPayloadAddNewApp,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCAppsResponsePayloadApplicationRNResponseRelay>;
  public apiV2AppsAddPost(
    rnHCAppsPayloadAddNewApp?: RnHCAppsPayloadAddNewApp,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnHCAppsResponsePayloadApplicationRNResponseRelay>
  >;
  public apiV2AppsAddPost(
    rnHCAppsPayloadAddNewApp?: RnHCAppsPayloadAddNewApp,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCAppsResponsePayloadApplicationRNResponseRelay>>;
  public apiV2AppsAddPost(
    rnHCAppsPayloadAddNewApp?: RnHCAppsPayloadAddNewApp,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnHCAppsResponsePayloadApplicationRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/apps/add`,
      rnHCAppsPayloadAddNewApp,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Assigns an existing application to the provided packages )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Apps_App_Writer
   * @param rnHCAppsPayloadModifyPackages
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2AppsAssignToPackagesPost(
    rnHCAppsPayloadModifyPackages?: RnHCAppsPayloadModifyPackages,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCAppsResponsePayloadApplicationRNResponseRelay>;
  public apiV2AppsAssignToPackagesPost(
    rnHCAppsPayloadModifyPackages?: RnHCAppsPayloadModifyPackages,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnHCAppsResponsePayloadApplicationRNResponseRelay>
  >;
  public apiV2AppsAssignToPackagesPost(
    rnHCAppsPayloadModifyPackages?: RnHCAppsPayloadModifyPackages,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCAppsResponsePayloadApplicationRNResponseRelay>>;
  public apiV2AppsAssignToPackagesPost(
    rnHCAppsPayloadModifyPackages?: RnHCAppsPayloadModifyPackages,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnHCAppsResponsePayloadApplicationRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/apps/assignToPackages`,
      rnHCAppsPayloadModifyPackages,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets an existing application matching the given ID )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Apps_App_Reader
   * @param appID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2AppsGetApplicationGet(
    appID?: number,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCAppsResponsePayloadApplicationRNResponseRelay>;
  public apiV2AppsGetApplicationGet(
    appID?: number,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnHCAppsResponsePayloadApplicationRNResponseRelay>
  >;
  public apiV2AppsGetApplicationGet(
    appID?: number,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCAppsResponsePayloadApplicationRNResponseRelay>>;
  public apiV2AppsGetApplicationGet(
    appID?: number,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (appID !== undefined && appID !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>appID,
        "appID",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnHCAppsResponsePayloadApplicationRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/apps/getApplication`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets a list of packages assigned to an existing item with the given ID )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Apps_App_Reader
   * @param appID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2AppsGetPackagesAssignedToApplicationGet(
    appID?: number,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCPackageResponsePayloadListRNResponseRelay>;
  public apiV2AppsGetPackagesAssignedToApplicationGet(
    appID?: number,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnHCPackageResponsePayloadListRNResponseRelay>>;
  public apiV2AppsGetPackagesAssignedToApplicationGet(
    appID?: number,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCPackageResponsePayloadListRNResponseRelay>>;
  public apiV2AppsGetPackagesAssignedToApplicationGet(
    appID?: number,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (appID !== undefined && appID !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>appID,
        "appID",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnHCPackageResponsePayloadListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/apps/getPackagesAssignedToApplication`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Lists the available application/item types )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Apps_App_Reader
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2AppsListApplicationTypesGet(
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCAppsResponsePayloadApplicationTypeListRNResponseRelay>;
  public apiV2AppsListApplicationTypesGet(
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnHCAppsResponsePayloadApplicationTypeListRNResponseRelay>
  >;
  public apiV2AppsListApplicationTypesGet(
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpEvent<RnHCAppsResponsePayloadApplicationTypeListRNResponseRelay>
  >;
  public apiV2AppsListApplicationTypesGet(
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnHCAppsResponsePayloadApplicationTypeListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/apps/listApplicationTypes`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Lists applications with names matching the given query )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Apps_App_Reader
   * @param name
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2AppsListApplicationsGet(
    name?: string,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCAppsResponsePayloadListRNResponseRelay>;
  public apiV2AppsListApplicationsGet(
    name?: string,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnHCAppsResponsePayloadListRNResponseRelay>>;
  public apiV2AppsListApplicationsGet(
    name?: string,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCAppsResponsePayloadListRNResponseRelay>>;
  public apiV2AppsListApplicationsGet(
    name?: string,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (name !== undefined && name !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>name,
        "name",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnHCAppsResponsePayloadListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/apps/listApplications`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Removes an existing application from the provided packages )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Apps_App_Writer
   * @param rnHCAppsPayloadModifyPackages
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2AppsRemoveFromPackagesPost(
    rnHCAppsPayloadModifyPackages?: RnHCAppsPayloadModifyPackages,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCAppsResponsePayloadApplicationRNResponseRelay>;
  public apiV2AppsRemoveFromPackagesPost(
    rnHCAppsPayloadModifyPackages?: RnHCAppsPayloadModifyPackages,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnHCAppsResponsePayloadApplicationRNResponseRelay>
  >;
  public apiV2AppsRemoveFromPackagesPost(
    rnHCAppsPayloadModifyPackages?: RnHCAppsPayloadModifyPackages,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCAppsResponsePayloadApplicationRNResponseRelay>>;
  public apiV2AppsRemoveFromPackagesPost(
    rnHCAppsPayloadModifyPackages?: RnHCAppsPayloadModifyPackages,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnHCAppsResponsePayloadApplicationRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/apps/removeFromPackages`,
      rnHCAppsPayloadModifyPackages,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Updates an existing item with the given ID )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; AzureAD&lt;br/&gt;&lt;b&gt;Roles:&lt;/b&gt; Apps_App_Writer
   * @param rnHCAppsPayloadUpdateApp
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2AppsUpdatePost(
    rnHCAppsPayloadUpdateApp?: RnHCAppsPayloadUpdateApp,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnHCAppsResponsePayloadApplicationRNResponseRelay>;
  public apiV2AppsUpdatePost(
    rnHCAppsPayloadUpdateApp?: RnHCAppsPayloadUpdateApp,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<
    HttpResponse<RnHCAppsResponsePayloadApplicationRNResponseRelay>
  >;
  public apiV2AppsUpdatePost(
    rnHCAppsPayloadUpdateApp?: RnHCAppsPayloadUpdateApp,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnHCAppsResponsePayloadApplicationRNResponseRelay>>;
  public apiV2AppsUpdatePost(
    rnHCAppsPayloadUpdateApp?: RnHCAppsPayloadUpdateApp,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnHCAppsResponsePayloadApplicationRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/apps/update`,
      rnHCAppsPayloadUpdateApp,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
