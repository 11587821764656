<div class="overlay-container">
  @if (showProcessingOverlay && !parentIsLoading) {
    <app-loader text="Processing..."></app-loader>
  }
  @if (isLoading && !parentIsLoading) {
    <app-loader text="Loading inventory resources..."></app-loader>
  }
  @if (!isLoading) {
    <div class="medium packageCatGroup">
      @for (group of categoryGroups; track group; let i = $index) {
        <div data-testid="packageAddonSection" class="medium">
          <mat-card appearance="outlined" class="mat-elevation-z0">
            <mat-panel>
              <mat-panel-header>
                <mat-panel-title>
                  <div
                    class="panel-heading"
                    [ngClass]="{
                      packagePanelHeading: !(group | rnPackageGroup: 'isaddon'),
                      addonPanelHeading: (group | rnPackageGroup: 'isaddon')
                    }"
                  >
                    <p data-testid="packagePanelTitle" class="packagePanelTitle">Select {{ group.Name }}</p>
                  </div>
                </mat-panel-title>
              </mat-panel-header>
              <hr />
              @if (group | rnPackageGroup: 'ispackage') {
                <div class="panelCatDescription">
                  <span data-testid="packageText"
                    >Select the package that fits you and your team and how you want to work within the Rightworks cloud
                    environment.<br /><br />If you need further assistance with a selection or would like to learn about
                    volume discounts, contact us at
                    <span data-testid="pkgSalesPhone" class="ws-nowrap">{{ sales_phone }}</span
                    >.</span
                  >
                </div>
              }
              @if (group | rnPackageGroup: 'isaddon') {
                <div class="panelCatDescription">
                  <span data-testid="addonText"
                    >We provide additional applications which can be used within the Rightworks cloud environment.<br /><br />If
                    you need further assistance with a selection contact us at
                    <span data-testid="addonSalesNumber" class="ws-nowrap">{{ sales_phone }}</span
                    >.</span
                  >
                </div>
              }
              @if (group | rnPackageGroup: 'isintuitupgrade') {
                <div class="panelCatDescription">
                  <p data-testid="intuitText">
                    Rightworks offers upgrades for Intuit customers that need access to more applications and
                    services.<br /><br />These upgrades do not replace the hosting service you purchased through Intuit
                    – they augment it. Upgrades require you to establish a billing relationship with Rightworks.
                  </p>
                  <br />
                  <b data-testid="intuitClassicPackageText">{{ intuitQbesOrgBasePackage.PackageName }} Package: </b>
                  <span data-testid="intuitSeats">{{ intuitQbesOrgBasePackage.CurrentQuantity }} Seats </span>
                  <br />
                  <span data-testid="QBESMsg">Note: {{ intuitQbesMaxHint }}</span>
                </div>
              }
              <div class="package-cards">
                @for (Package of group.Values; track Package) {
                  <app-package-card
                    [attr.data-testid]="Package.PackageName + '_card'"
                    class="package-card noHover"
                    [AddQtyControlsDisabled]="disableAddQtyChange"
                    [QtyControlsDisabled]="disableQtyChange"
                    [Disabled]="disabledByExclusivity(Package)"
                    [DisabledToolTip]="getDisabledTooltip(Package)"
                    MaxAllowed="Package.MaxItemsAllowed"
                    [Package]="Package"
                    [InternalExclusiveTooltip]="getInternalExclusiveTooltip(Package)"
                    (UpdateChanges)="updateChanges($event)"
                    [MaxUsedMessage]="intuitQbesAddonsMaxHint"
                  ></app-package-card>
                }
              </div> </mat-panel
          ></mat-card>
        </div>
      }
    </div>
  }
  <mat-card appearance="outlined" class="mat-elevation-z0 changeDetails">
    @if (!isLoading) {
      <div>
        <div class="sectionTitle">Change Details</div>
        <div data-testid="changesText" class="p-b-8 changesText">
          These changes will be applied to your current estimated bill. You can review your current detailed bill by
          clicking on the Billing tab.
        </div>
        <rn-grid [Configuration]="ChangeTableConfiguration"></rn-grid>
        @if (cart.length > 0) {
          <div class="totalsLightBlue">
            <span data-testid="totalCharges" class="columnTitle"
              >Total Charges:<span class="columnData">&nbsp;{{ totals | currency }}</span></span
            >
            <span data-testid="currentEstimatedBill" class="columnTitle"
              >Current Estimated Bill:<span class="columnData">&nbsp;{{ currentMonthlyBill | currency }}</span></span
            >
            <span class="fw-bold" data-testid="futureEstimatedTotal" class="columnTitle"
              >Future Estimated Total:<span class="columnData"
                >&nbsp;{{
                  totals
                    | rnPackageInventory
                      : 'futureesttotal'
                      : allPackages
                      : discountedBalance
                      : currentMonthlyBill
                      : validatedPromo
                    | currency
                }}</span
              ></span
            >
            <span class="taxData">Plus tax where applicable</span>
          </div>
        }
      </div>
    }
    @if (!this.isLoading) {
      <div>
        @if (canShowPromoCodeEntry) {
          <div class="promoCodeWell">
            <div class="pull-left">
              <label class="promo-label pull-left" for="promoTextbox">Promo Code</label>
              <br />
              <div class="form-group disp-inl-flex">
                <input
                  data-testid="promoInput"
                  id="promoTextbox"
                  maxlength="25"
                  autocomplete="off"
                  ng-focus="vm.error = false"
                  ng-class="{ 'sharp-angles-error': vm.error }"
                  type="text"
                  [(ngModel)]="inputPromoCode"
                  placeholder="Promo Code"
                  required
                  class="textbox-sharp-angles form-control"
                  (input)="onPromoChange()"
                />
                <button
                  [disabled]="!inputPromoCode"
                  type="button"
                  (click)="validatePromoCode()"
                  class="primaryButton"
                  (ngClass)="
                    (!promoCodeIsValid && !promoCodeWaiting
                      ? 'blue-promo-apply-btn'
                      : 'blue-promo-apply-btn blue-promo-apply-btn-default-pointer')
                  "
                  data-testid="btnValidatePromoCode-ChangePackage"
                >
                  @if (!promoCodeIsValid && !promoCodeWaiting) {
                    <span>APPLY</span>
                  }
                  @if (promoCodeWaiting) {
                    <span><app-icon source="rn-circle-notch" imgClass="loading-spinner gray-icon "></app-icon></span>
                  }
                  @if (promoCodeIsValid) {
                    <span><i class="fa-solid fa-check promo-apply-check" aria-hidden="true"></i></span>
                  }
                </button>
              </div>
              @if (promoCodeError) {
                <div class="red-help-block">Promo Code {{ inputPromoCode }} is not valid or has expired.</div>
              }
            </div>
          </div>
        }
      </div>
    }
    @if (!this.isLoading) {
      <div class="form-group rightAlignButtons">
        <app-button
          id="btnCancel"
          label="CANCEL"
          noContentPadding="true"
          buttonStyle="addUserTeritary"
          (clickActionEmitter)="cancelPackageMix()"
        >
        </app-button>
        <app-button
          id="btnSave"
          tooltip="{{ buttonTooltip }}"
          tooltipsPosition="left"
          label="SAVE CHANGES"
          (clickActionEmitter)="savePackageMix()"
          noContentPadding="true"
          [disabled]="!(this.totals !== 0 && !this.disableQtyChange) || qbesAddonsOutOfCompliance"
          buttonStyle="primary"
        >
        </app-button>
      </div>
    }
  </mat-card>
</div>
