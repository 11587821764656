import { Pipe, PipeTransform } from "@angular/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

@Pipe({
  name: "utcToLocalDate",
})
export class UtcToLocalDatePipe implements PipeTransform {
  transform(value: any): any {
    return dayjs.utc(value).local();
  }
}
