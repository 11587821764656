<div
  id="shoppingCartContainer"
  [ngClass]="{
    'wider-cart': !IsInline && UseWiderCart,
    notInline: !IsInline && !UseWiderCart
  }"
>
  @if (!IsInline) {
    <div class="borderBottom">
      <h4 class="titleText">Shopping Cart</h4>
      <div class="justifiedFlexEnd xCloseButton">
        <button
          data-testid="iconShoppingCartClose"
          class="close xToClose lmargin-auto"
          pTooltip="Close cart"
          tooltipPosition="bottom"
          (click)="hideShoppingCart()"
          aria-hidden="true"
        >
          x
        </button>
      </div>
    </div>
  }
  @if (IsInline && shoppingCart.items.length > 0) {
    <div class="orderSummaryDivision">
      <br />
      <h4 class="titleText">Your Order Summary</h4>
    </div>
  }
  @if (showGridArea) {
    <div (ngClass)="({ NonInlineComponent: !IsInline })">
      <div class="shoppingCartScrollableArea">
        <rn-grid data-testid="ShoppingCartTableHeaders" [Configuration]="CartTableConfiguration"></rn-grid>
      </div>
      @if (shoppingCart.items.length === 0) {
        <div>
          <div class="shoppingCartTableEmpty">
            <b data-testid="emptyShoppingCart">No items have been added to your cart.</b>
          </div>
        </div>
      }
      @if (showTotalDue) {
        <div class="totalsLightBlue">
          <span class="pull-left"><b>Total Due Today</b></span>
          <span class="lmargin-auto"
            ><b data-testid="totalDueVaule">{{ shoppingCart.CartTotal | currency }}</b></span
          >
          <span class="taxText">Plus tax where applicable</span>
        </div>
      }
    </div>
  }
  @if (showFutureMonthlyBill) {
    <div class="futureBillDivision dspy-flex">
      <span data-testid="futureMonthlyBill" class="pull-left"
        >Future Monthly Bill (starts {{ PaymentInfo?.NextBillDate | date: 'MM/dd/yyyy' }})</span
      >
      @if (PaymentInfo?.NextBillAmount) {
        <span data-testid="futureMonthlyBillValue" class="lmargin-auto"
          >{{ +PaymentInfo?.NextBillAmount + shoppingCart.CartMonthlyTotal | currency }} /month</span
        >
      }
    </div>
  }
  <div class="promoCodeDivision">
    <h4>Promo Code</h4>
  </div>
  @if (canShowPromoCodeEntry) {
    <app-promo-code
      #promo
      [IdThatPaysForThisOrg]="PaymentInfo?.OrganizationID"
      (PromoEvent)="applyPromo($event)"
    ></app-promo-code>
  }
  @if (showPaymentArea) {
    <div class="shoppingCartPaymentInfo">
      <div class="subTitleText m-b-11 black">Current Payment Method</div>
      @if (showACHInfo) {
        <div class="paymentDetails">
          <div>ACH</div>
          <div>Account Type: {{ PaymentInfo?.PaymentMethods[0]?.ACHTypeName }}</div>
          <div>{{ PaymentInfo?.PaymentMethods[0]?.AccountLast4 }}</div>
        </div>
      }
      @if (showCCInfo) {
        <div class="paymentDetails">
          <div data-testid="PaymentInfo">
            {{ PaymentInfo?.PaymentMethods[0]?.PaymentMethodType }}
            {{ PaymentInfo?.PaymentMethods[0]?.AccountLast4 }}
          </div>
          @if (PaymentInfo?.PaymentMethods[0]?.CCExpireOn) {
            <div data-testid="expDate">
              Expires
              {{ PaymentInfo?.PaymentMethods[0]?.CCExpireOn | date: 'MM/yyyy' }}
            </div>
          }
        </div>
      }
      <div class="m-t-14 paymentDetails">
        <span data-testid="changePaymentTxt"
          >You can change your payment and billing information on the Billing tab.</span
        >
      </div>
    </div>
  }
  @if (!IsInline) {
    <div class="closeButton">
      @if (!UsePurchaseButton) {
        <div class="m-t-33">
          <app-button id="btnClose" buttonStyle="secondary" label="CLOSE" (clickActionEmitter)="hideShoppingCart()">
          </app-button>
        </div>
      }
      @if (UsePurchaseButton) {
        <div class="purchase-button-container">
          <app-button
            data-testid="btnShoppingCartClose"
            id="btnShoppingCartClose"
            label="CLOSE"
            buttonStyle="addUserTeritary"
            (clickActionEmitter)="hideShoppingCart()"
          >
          </app-button>
          <app-button
            id="btnPlaceOrder"
            [disabled]="purchaseDisabled"
            buttonStyle="primary"
            label="PLACE ORDER"
            showProcessing="true"
            [processing]="submitting"
            (clickActionEmitter)="placeOrder()"
          >
          </app-button>
        </div>
      }
    </div>
  }
</div>
