@if (isLoading) {
  <app-loader text="Loading..."></app-loader>
}
<div class="suborg-group margin-bottom-sm" ng-if="data.orgInfo.Name">
  <span class="input-group-addon search-bar">
    <app-icon source="rn-search" [imgClass]="'searchImage'"></app-icon>
  </span>
  <input
    id="search"
    #search
    [(ngModel)]="selectedOrgText"
    type="text"
    data-testid="search"
    placeholder="{{ placeholderText }}"
    (ngModelChange)="searchChanged($event)"
    [required]="required"
    (blur)="searchBlur($event)"
    class="form-control org-lookkup-edit"
    autocomplete="off"
  />
  <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></div>
  <mat-menu #menu="matMenu" yPosition="below" xPosition="after" class="orgLookupMenu">
    @if (lookupOrgs?.length < 4) {
      <div>
        @for (org of lookupOrgs; track org; let i = $index) {
          <span
            tabindex="0"
            (keyup.enter)="orgSelected(org)"
            mat-menu-item
            [attr.data-testid]="'orgLookup' + i"
            class="orgLookupItem"
            (click)="orgSelected(org)"
            pTooltip="<span data-testid='tooltipMsg'> {{ org | rnOrgInfo: 'gettooltip' }} </span>"
            [escape]="false"
            tooltipStyleClass="wideTooltipMed"
            >{{ org.Name }}</span
          >
        }
      </div>
    }
    @if (lookupOrgs?.length >= 4) {
      <div>
        <cdk-virtual-scroll-viewport itemSize="200" class="org-lookup-viewport">
          <div>
            @for (org of lookupOrgs; track org; let i = $index) {
              <span
                tabindex="0"
                (keyup.enter)="orgSelected(org)"
                mat-menu-item
                [attr.data-testid]="'orgLookup' + i"
                class="orgLookupItem"
                (click)="orgSelected(org)"
                pTooltip="<span data-testid='tooltipMsg'> {{ org | rnOrgInfo: 'gettooltip' }} </span>"
                [escape]="false"
                tooltipStyleClass="wideTooltipMed"
                >{{ i }}: {{ org.Name }}</span
              >
            }
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    }
  </mat-menu>
  @if (noResultsFound) {
    <div><i class="glyphicon glyphicon-remove"></i> No Results Found</div>
  }
</div>
