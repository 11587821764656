@for (group of nonPurchasableAddOnGroups; track group) {
  <div class="medium">
    @if (!hideTitles) {
      <div class="panel-heading">
        <h3 class="select-package-addon-panel-title">{{ group.GroupName }}</h3>
        <div class="text_14_margin_top_10">{{ infoText }}</div>
      </div>
    }
    @if (group.GroupDescription) {
      <div class="category-desc">
        {{ group.GroupDescription }}
      </div>
    }
    <div class="package-cards m-l-neg15">
      @for (addOn of group.Addons; track addOn) {
        <app-package-card
          data-testid="packageCard"
          [AddOnDisabled]="addonIsDisabled(addOn)"
          [AddOnTooltip]="addonTooltip(addOn)"
          [ngClass]="{
            'card-selected': addOn.Selected,
            'package-card-info-only': addOn.InfoOnly,
            'package-card': !addOn.InfoOnly
          }"
          [Package]="addOn"
          CardType="addRemove"
          (AddItem)="packageCardAdd($event)"
          (RemoveItem)="packageCardRemove($event)"
        ></app-package-card>
      }
    </div>
  </div>
}

@for (group of purchasableAddOnGroups; track group) {
  <div class="medium">
    @if (!hideTitles) {
      <div class="panel-heading"></div>
    }
    @if (group.GroupDescription) {
      <div class="category-desc">
        {{ group.GroupDescription }}
      </div>
    }
    <div class="package-cards m-l-neg15">
      @for (addOn of group.Addons; track addOn) {
        <app-package-card
          data-testid="addonCard"
          [AddOnDisabled]="addonIsDisabled(addOn)"
          [AddOnTooltip]="addonTooltip(addOn)"
          [ngClass]="{
            'card-selected': addOn.Selected,
            'package-card-info-only': addOn.InfoOnly,
            'package-card': !addOn.InfoOnly
          }"
          class="package-card"
          [Package]="addOn"
          CardType="addRemove"
          (AddItem)="packageCardAdd($event)"
          (RemoveItem)="packageCardRemove($event)"
        ></app-package-card>
      }
    </div>
  </div>
}
