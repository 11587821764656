<div class="overlay-container bgColWhite">
  @if (isLoading) {
    <app-loader text="Loading DUO..."></app-loader>
  }
  <div class="mainDiv">
    @if (isDuoLoginError) {
      <div>
        <p-message
          severity="info"
          text=" Duo is unavailable. Please retry in few minutes."
          styleClass="p-mr-2"
        ></p-message>
      </div>
    }
    @if (!isDuoLoginError) {
      <iframe id="duo_iframe" class="duoiframe"></iframe>
    }
    @if (!isDuoLoginError && !isLoading) {
      <div class="setupWizard-supportMsg">
        <div class="lostResetDeviceText bgColWhite">
          If you require a change to the device you are using for multi-factor authentication,<br />
          please contact support for assistance.
        </div>
      </div>
    }
    @if (isDuoLoginError) {
      <div class="well side-margins-15">
        <h4>Duo is unavailable please retry in few Seconds</h4>
      </div>
    }
  </div>
</div>
