import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { UserService } from "src/app/shared/services/rnapi2-service/apis/user.service";
import { RnUserIsEmailUnique } from "../../../services/rnapi2-service/models/models";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { ConstantsService } from "../../../services/constants/constants.service";

@Component({
  selector: "app-email-verfication",
  templateUrl: "./email-verfication.component.html",
  styleUrls: ["./email-verfication.component.scss"],
})
export class EmailVerficationComponent {
  @Input() userDetailsForm = new FormGroup({});
  @Input() showStaticEmail = false;
  @Input() inEditingMode = false;
  @Input() userProfile;
  @Input() emailLabel = "Email Address";
  @Input() location = "addUser";
  @Input() profileId: string;
  @Input() showIcon = false;
  @Input() hideLeftPadding = false;
  @Input() labelClass = "";
  @Input() emailInputClass = "";
  @Output() pendingValidationEvent = new EventEmitter<boolean>();
  @Output() emailUnique = new EventEmitter<boolean>();
  public rnUserIsEmailUnique: RnUserIsEmailUnique;

  public pendingvalidation = false;
  private subscriptions: Subscription[] = [];
  public nonspecific = false;

  constructor(private userService: UserService,
    private toastService: RnToastService,
    private constantsService: ConstantsService) { }

  onBlurEmail() {
    if (this.email.value !== "" && this.email.valid) {
      // need to trim the value here, since the appTrimInput directive doesn't always execute before this function does
      this.rnUserIsEmailUnique = {
        search: this.email.value?.trim(),
        checkAD: true,
      };
      if (this.profileId && this.profileId != "")
        this.rnUserIsEmailUnique.Id = this.profileId;
      this.pendingvalidation = true;
      this.pendingValidationEvent.emit(true);
      this.emailUnique.emit(false);
      this.subscriptions.push(
        this.userService
          .apiV2UsersIsemailuniquePost(this.rnUserIsEmailUnique)
          .subscribe((response) => {
            this.nonspecific = !response.data;
            this.pendingvalidation = false;
            this.pendingValidationEvent.emit(false);
            this.emailUnique.emit(response.data);
            if (this.nonspecific) {
              this.email.setErrors({
                nonspecific: true,
              });
            }
          },
            (error) => {
              if (error.error.Messages && error.error.Messages[0].Code == this.constantsService.emailMergeError) {
                this.pendingvalidation = false;
                this.pendingValidationEvent.emit(false);
                this.nonspecific = true;
                this.email.setErrors({
                  mergeerror: true,
                });
                this.toastService.showError("There was a problem with the e-mail address provided. Please contact Support for further assistance.");
              }
          }),
      );
    }
  }
  get email() {
    return this.userDetailsForm.controls["email"];
  }
}
