<div class="adduser-modal-window">
  <div class="modal-content">
    <div id="createUserHeader" class="modal-header dspy-block">
      <div>
        <button type="button" class="close pullUpperRight" (click)="cancelAddUser()" aria-hidden="true">
          <span uib-tooltip="Close" tooltipPosition="top" pTooltip="Close">×</span>
        </button>
      </div>
      <div class="justifiedTitlebar">
        <div>
          <h3 class="header-black">Add User</h3>
          @if (!IsUsingWelcomeWizard) {
            <h3 class="header-normal">
              {{ steps[currentStep - 1].title }}
            </h3>
          }
        </div>
        @if (!IsUsingWelcomeWizard) {
          <div #cartIcon id="cartIcon">
            <app-shopping-cart-icon
              [cartClass]="'userCart'"
              (ToggleShoppingCart)="toggleShoppingCart()"
            ></app-shopping-cart-icon>
          </div>
        }
      </div>
    </div>
    <div class="modal-body" scroll-to-top-when="step_changed">
      <div #cart>
        @if (isShoppingCartVisible) {
          <app-shopping-cart
            [PaymentInfo]="paymentInfo"
            [IsInline]="false"
            (CloseCart)="toggleShoppingCart()"
          ></app-shopping-cart>
        }
      </div>
      @if (!(currentStep | rnAddUser: 'isLastStep' : steps.length)) {
        <div class="m-b-10">
          <span class="step-desc" [innerHtml]="steps[currentStep - 1].desc"></span>
        </div>
      }
      <form [formGroup]="mainForm">
        <app-new-user-details
          [userDetailsForm]="mainForm.get('UserCreate')"
          [hidden]="currentStep !== 1"
          [hasAccountOwner]="hasAccountOwner"
          [data]="data"
          (pendingValidationEvent)="pendingValidationChanged($event)"
        ></app-new-user-details>
        <app-change-package
          [packageSelectForm]="mainForm.get('UserPackage')"
          [OrganizationID]="OrganizationID"
          [orgProfile]="orgProfile"
          [orgConfig]="orgConfig"
          [orgWlpContactInfo]="orgWlpContactInfo"
          [hidden]="currentStep !== 2"
          [AllActions]="AllActions"
          (PackageSelected)="packageSelected($event)"
          (PackagesLoaded)="packagesLoaded($event)"
          (AddonsLoaded)="addonsLoaded($event)"
          [CheckForInitialActiveMessage]="checkForInitialActiveMessage"
        ></app-change-package>
        <app-manage-addons
          [addonsSelectForm]="mainForm.get('UserAddons')"
          [OrganizationID]="OrganizationID"
          [orgProfile]="orgProfile"
          [orgConfig]="orgConfig"
          [orgWlpContactInfo]="orgWlpContactInfo"
          [hidden]="currentStep !== 3"
          [SelectedPackage]="selectedPackage"
          [AddOnList]="availableAddOns"
        ></app-manage-addons>
        <app-deploy-quickbooks
          [licenseSelectForm]="mainForm.get('UserLicense')"
          [OrganizationID]="OrganizationID"
          [hidden]="currentStep !== 4"
          (noLicenseEvent)="noLicenseEvent($event)"
          (licensesEvent)="licensesEvent($event)"
          (pendingLicenseCheckEvent)="pendingLicenseCheckEvent($event)"
          (selectedLicenseEvent)="selectedLicenseEvent($event)"
        ></app-deploy-quickbooks>
        @if (currentStep === 5) {
          <app-review-user
            [reviewForm]="mainForm"
            [paymentInfo]="paymentInfo"
            (promoEvent)="promoEvent($event)"
          ></app-review-user>
        }
      </form>
    </div>
    <div class="modal-footer m-t-md">
      <div>
        <app-button
          id="btnCancel"
          label="CANCEL"
          buttonStyle="addUserTeritary"
          noContentPadding="true"
          (clickActionEmitter)="cancelAddUser()"
        >
        </app-button>
        @if (!(currentStep | rnAddUser: 'isfirststep')) {
          <app-button
            id="btnPrevious"
            label="BACK"
            buttonStyle="addUserBack"
            noContentPadding="true"
            (clickActionEmitter)="handlePrevious()"
          >
          </app-button>
        }
        <app-button
          id="btnNext"
          [label]="currentStep | rnAddUser: 'getNextLabel' : steps.length"
          [disabled]="!isCurrentFormValid()"
          noContentPadding="true"
          buttonStyle="primary"
          (clickActionEmitter)="handleNext()"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>
