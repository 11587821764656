@if (!Package.InfoOnly) {
  <div
    class="flex"
    [ngClass]="{
      'license-card': CardType === 'license',
      'disabled-view': CardType !== 'license' && !Package.ExternallyPurchasable
    }"
  >
    <mat-card-title
      [attr.data-testid]="'cardUpperSection_' + Package.PackageCategoryDescription"
      class="package-card-header"
      [ngClass]="{
        'card-standard': !Package.Selected && Package.CatalogVisibility !== 'Unpurchasable',
        'card-selected-not-purchasable': !Package.Selected && Package.CatalogVisibility === 'Unpurchasable',
        'card-selected': Package.Selected
      }"
    >
      @if (!Package.InfoOnly) {
        <mat-card-title-text>
          @if (CardType === 'license') {
            <div [attr.data-testid]="'licenseCardHeadline_' + Package.PackageType" class="dspy-inline">
              <span class="license-card-headline">{{ Package.PackageName }}</span>
              <br />
              <div class="license-card-desc" [innerHtml]="Package.PackageDescHtml | safeHtml"></div>
            </div>
          }
          @if (CardType !== 'license') {
            <span [attr.data-testid]="'packageTitle_' + Package.PackageName" class="package-card-headline">{{
              Package.PackageName
            }}</span>
          }
        </mat-card-title-text>
      }
      @if (ShowPriceHeading && !Package.InfoOnly) {
        <p>
          <span
            [attr.data-testid]="Package.PackageName + '_Price'"
            class="packagePriceHeading"
            [ngClass]="{ preDiscountedPrice: Package.DiscountPercent > 0 }"
            >{{ Package.ListPrice | currency }}</span
          >
          @if (Package.DiscountPercent > 0) {
            <span data-testid="packageAddonDiscountedPrice" class="packagePriceHeading"
              >&nbsp;{{ (Package.ListPrice * (100 - Package.DiscountPercent)) / 100 | currency }}</span
            >
          }
          <span class="packagePricePer">{{ Package.UnitTypeDesc }}</span>
          @if (Package.DiscountPercent > 0) {
            <span class="discountBadge"> {{ Package.DiscountPercent }}% OFF </span>
          }
        </p>
      }
      @if (ShowPriceHeading && !Package.InfoOnly) {
        <hr class="borderLess" />
      }
    </mat-card-title>
    @if (ShowDescription) {
      <mat-card-content class="card-content-standard packageCardContent">
        @if (CardType !== 'license') {
          <div
            [attr.data-testid]="Package.PackageName + '_Content'"
            class="rn-blackText"
            [innerHtml]="Package.PackageDescHtml | safeHtml"
          ></div>
        }
      </mat-card-content>
    }
  </div>
}
@if (!InfoOnly && ShowQuantityChanged) {
  <mat-card-actions class="card-product-footer">
    <div class="col-4 quantityControl">
      <div class="quantity-control-container">
        <app-quantity-control
          (quantityChangeActionEmitter)="quantityChanged(Package, $event)"
          (quantityEnteredActionEmitter)="newQuantityEntered(Package, $event)"
          [QtyControlsDisabled]="this.QtyControlsDisabled"
          [AddQtyControlsDisabled]="this.AddQtyControlsDisabled"
          [MinValue]="Package.CurrentUsed"
          [DefaultValue]="Package.CurrentQuantity"
          [DisallowInput]="false"
          [MaxUsedMessage]="MaxUsedMessage"
          [MaxAllowed]="Package.MaxItemsAllowed"
          [InternalExclusiveTooltip]="this.InternalExclusiveTooltip"
        ></app-quantity-control>
        @if (Disabled) {
          <div class="overlay" [pTooltip]="DisabledToolTip"></div>
        }
      </div>
    </div>
    <div style="margin-top: 10px">
      <span [attr.data-testid]="Package.PackageName + '_assignedSeats'" class="assignmentText appControlAssignmentText"
        >Assigned: {{ Package.CurrentUsed }} of {{ Package.CurrentQuantity }}</span
      >
    </div>
  </mat-card-actions>
}
@if (!InfoOnly && ShowStaticCounts) {
  <mat-card-actions>
    <div class="row">
      <div class="col-12 text-right pb-2 footerStyle">
        @if (!Package.AddOnAdded) {
          <app-button
            id="btnAdd"
            buttonStyle="packageCardPrimary"
            label="SELECT"
            [disabled]="Package | rnPackage: 'addonisdisabled' : AddOnDisabled : PurchaseRestricted"
            (clickActionEmitter)="AddClick()"
            width="120px"
            [escape]="false"
            noContentPadding="true"
            pTooltip="{{ AddOnTooltip }}"
          >
          </app-button>
        }
        @if (Package.AddOnAdded) {
          <app-button
            id="btnRemove"
            buttonStyle="dontCreateUserButton"
            label="REMOVE"
            (clickActionEmitter)="RemoveClick()"
            noContentPadding="true"
            width="120px"
          >
          </app-button>
        }
      </div>
      <div class="col-12 text-center pb-3 assigned-values">
        <span class="assignmentText">Assigned: {{ Package.CurrentUsed }} of {{ Package.CurrentQuantity }}</span>
      </div>
    </div>
  </mat-card-actions>
}
@if (!InfoOnly && ShowAddOnRemove) {
  <mat-card-actions>
    <div class="row">
      <div class="col-12 text-right pb-2 footerStyle">
        @if (!Package.AddOnAdded) {
          <app-button
            id="btnAdd"
            buttonStyle="packageCardPrimary"
            label="SELECT"
            [disabled]="Package | rnPackage: 'addonisdisabled' : AddOnDisabled : PurchaseRestricted"
            (clickActionEmitter)="AddClick()"
            width="120px"
            [escape]="false"
            noContentPadding="true"
            pTooltip="{{ AddOnTooltip }}"
          >
          </app-button>
        }
        @if (Package.AddOnAdded) {
          <app-button
            id="btnRemove"
            buttonStyle="dontCreateUserButton"
            label="REMOVE"
            (clickActionEmitter)="RemoveClick()"
            noContentPadding="true"
            width="120px"
          >
          </app-button>
        }
      </div>
      <div class="col-12 text-center pb-3 assigned-values">
        <span class="assignmentText">Assigned: {{ Package.CurrentUsed }} of {{ Package.CurrentQuantity }}</span>
      </div>
    </div>
  </mat-card-actions>
}
@if (Package.InfoOnly) {
  <div [innerHtml]="Package.PackageDescHtml | safeHtml" class="card-content-infoOnly"></div>
}
