@if (email) {
  <form [formGroup]="userDetailsForm">
    <div
      class="p-b-sm"
      [ngClass]="{
        'has-error': !email.valid && email.touched && !pendingvalidation,
        'has-pending-validation': email.touched && pendingvalidation,
        'p-field': location === 'addUser'
      }"
    >
      <!-- <label for="email">Email Address</label> -->
      @if (location === 'editUser') {
        <label for="emailInput" class="userInfoCategory">{{ emailLabel }}</label>
      }
      @if (location === 'addUser') {
        <label for="emailInput">{{ emailLabel }}</label>
      }
      <div class="showTitleText">
        @if (showStaticEmail) {
          <app-label [value]="userProfile?.EmailAddress" [cssClass]="labelClass" [truncateSize]="32"> </app-label>
        }
      </div>
      @if (showIcon) {
        <div class="input-icons">
          <i class="fa-solid fa-user icon"></i>
          @if (inEditingMode) {
            <input
              id="emailInput"
              data-testid="emailInput"
              placeholder="Email Address"
              type="text"
              appTrimInput
              class="form-control ui-inputtext input-field"
              [ngClass]="{ 'edit-form-control': location === 'editUser' }"
              formControlName="email"
              maxlength="75"
              (blur)="onBlurEmail()"
              (focus)="$event.target.select()"
            />
          }
        </div>
      }
      @if (!showIcon) {
        <div>
          @if (inEditingMode) {
            <input
              id="emailInput"
              data-testid="emailInput"
              placeholder="Email Address"
              type="text"
              appTrimInput
              class="form-control ui-inputtext input-field + {{ emailInputClass }}"
              [ngClass]="{
                'edit-form-control': location === 'editUser',
                hideLeftIconPadding: hideLeftPadding
              }"
              formControlName="email"
              maxlength="75"
              (blur)="onBlurEmail()"
              (focus)="$event.target.select()"
            />
          }
        </div>
      }
      @if (!email.valid && email.touched && !pendingvalidation && inEditingMode) {
        <div data-testid="emailErrors">
          @if (email.errors?.required) {
            <div data-testid="emailRequiredError" class="help-block">Required field</div>
          }
          @if (email.errors?.allowedemail) {
            <div data-testid="domainError" class="help-block">'rightnetworks' is not a valid domain</div>
          }
          @if (email.errors?.pattern) {
            <div data-testid="invalidEmailError" class="help-block">Invalid email</div>
          }
          @if (email.errors?.nonspecific) {
            <div data-testid="exsistingEmailError" class="help-block">This email address is already in use</div>
          }
        </div>
      }
      @if (email.touched && pendingvalidation && inEditingMode) {
        <div>
          @if (pendingvalidation) {
            <div data-testid="emailCheck" class="help-block">
              Checking if email is unique...
              <app-icon
                data-testid="whiteLoadingSpinner"
                source="rn-circle-notch"
                imgClass="loading-spinner white-icon "
              ></app-icon>
            </div>
          }
        </div>
      }
    </div>
  </form>
}
