import { Injectable, OnDestroy } from "@angular/core";
import { UserService } from "../services/rnapi2-service/apis/api";
import { jwtDecode } from "jwt-decode";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { UserToken } from "../../core/models/user-token";
import { UserLoginState } from "../../core/models/user.login.state";
import { loginStateTypes } from "../../core/enums/loginStateTypes";
import {
  RnRNResponseMessages,
  RnRNResponseMessageStatus,
  RnUpdateToken,
  RnUpdateTokenVMRNResponseRelay,
  RnUserLoginVM,
  RnUserLoginVMRNResponseRelay,
} from "../services/rnapi2-service/models/models";
import { NavigationService } from "../services/navigation/navigation.service";
import { LoggedInInfoService } from "../services/loggedInInfo/logged-in-info.service";
import { StartupService } from "../services/startup/startup.service";
import { EnvService } from "../../core/services/env.service";
import { RnUserLogin } from "../services/rnapi2-service/models/models";
import { PreferencesService } from "../services/preferences/preferences.service";
import { SelectionService } from "../services/selection/selection.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogTrackingService } from "../services/dialog/dialog-tracking.service";
import { LoginStateInfo } from "../../core/models/login-state-info";
import { RnToastService } from "../services/toast/rntoast.service";
import { AuthService } from "@rn-platform/frontend-shared-feature-identity-auth";

declare let pendo: any;

@Injectable({
  providedIn: "root",
})
export class AuthenticationService implements OnDestroy {
  public loggedInState: Observable<LoginStateInfo>;
  public loggedInUser: Observable<UserLoginState>;

  private loggedInStateSubject = new Subject<LoginStateInfo>();
  private loggedInUserSubject = new BehaviorSubject<UserLoginState>(null);
  private CURRENT_USER_STORAGE_KEY = "user_data";
  private CURRENT_TOKEN_STORAGE_KEY = "token";
  private subscriptions = [];
  private loginErrMessage: RnRNResponseMessages;

  constructor(
    private userService: UserService,
    private navigationService: NavigationService,
    private loggedInInfoService: LoggedInInfoService,
    private startupService: StartupService,
    private preferenceService: PreferencesService,
    private selectionService: SelectionService,
    private dialog: MatDialog,
    private dialogTrackService: DialogTrackingService,
    private toastService: RnToastService,
    private authService: AuthService,
  ) {
    this.loggedInStateSubject.next({ LoginStateType: loginStateTypes.none });
    this.loggedInState = this.loggedInStateSubject.asObservable();
    this.loggedInUser = this.loggedInUserSubject.asObservable();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  handleTokenResponse(
    token: string,
    signalRConnectionAccessToken: string,
    signalRConnectionUrl: string,
  ): void {
    localStorage.setItem("token", token);
    // sets cookie with expiration of "Session"
    document.cookie = "apphub_user_data=" + token + "; Path=/;Secure; SameSite=Lax; domain=" + this.getTokenDomain();
    if (signalRConnectionAccessToken)
      localStorage.setItem(
        "signalRConnection-AccessToken",
        signalRConnectionAccessToken,
      );
    if (signalRConnectionUrl)
      localStorage.setItem("signalRConnection-Url", signalRConnectionUrl);
    const loginObj: UserToken = jwtDecode(token);
    const userLoginState = new UserLoginState(loginObj);
    this.loadLoggedInUserInfoAndNavigate(userLoginState);
  }

  getTokenDomain() {
    const hostnameParts = window.location.hostname.split(".");
    // should either be ".rightworks.com" or "localhost"
    return hostnameParts.length > 1 ? `.${hostnameParts.slice(-2).join('.')}` : window.location.hostname;
  }

  identityLogin(userLoginResponse: RnUserLoginVM): void {
    this.loginErrMessage = null;
    /*const userLoginResponse: RnUserLoginVM = r.body.data;*/
    if (userLoginResponse.Token === "MFA") {
      this.loggedInStateSubject.next({
        LoginStateType: loginStateTypes.mfaLogin,
        NotifyMFAEnabled: userLoginResponse.NotifyMFAEnabled,
        ID: userLoginResponse.ID,
      });
    } else if (
      userLoginResponse.Token === "WriteOff" ||
      userLoginResponse.Token === "Suspended" ||
      userLoginResponse.Token === "Canceled"
    ) {
      this.loggedInStateSubject.next({
        LoginStateType: loginStateTypes.error_dialog,
      });
    } else {
      this.handleTokenResponse(
        userLoginResponse.Token,
        userLoginResponse.SignalRConnection?.AccessToken,
        userLoginResponse.SignalRConnection?.Url,
      );
    }
  }

  login(userEmail: string, password: string): void {
    this.loginErrMessage = null;
    const loginPayload = new RnUserLogin();

    loginPayload.Email = userEmail;
    loginPayload.Password = password;
    loginPayload.UseAdvancedLogin = true;
    loginPayload.LoginApp = "APPHUB";

    loginPayload.BaseApiUrl = EnvService.EnvVariables().baseUrl2;
    const loginResponse = this.userService.apiV2UsersLoginPost(
      loginPayload,
      "response",
    );

    this.subscriptions.push(
      loginResponse.subscribe(
        (r) => {
          if (r.status === 200 && r.body.Success) {
            const userLoginResponse: RnUserLoginVM = r.body.data;
            if (userLoginResponse.Token === "MFA") {
              this.loggedInStateSubject.next({
                LoginStateType: loginStateTypes.mfaLogin,
                NotifyMFAEnabled: userLoginResponse.NotifyMFAEnabled,
                ID: userLoginResponse.ID,
              });
            } else if (
              userLoginResponse.Token === "WriteOff" ||
              userLoginResponse.Token === "Suspended" ||
              userLoginResponse.Token === "Canceled"
            ) {
              this.loggedInStateSubject.next({
                LoginStateType: loginStateTypes.error_dialog,
              });
            } else {
              this.handleTokenResponse(
                userLoginResponse.Token,
                userLoginResponse.SignalRConnection?.AccessToken,
                userLoginResponse.SignalRConnection?.Url,
              );
            }
          } else {
            if (r.body && r.body.Messages && r.body.Messages.length > 0) {
              this.loginErrMessage = r.body.Messages[0];
            } else {
              this.loginErrMessage = new RnRNResponseMessages();
              this.loginErrMessage.Description =
                "Sorry, please try logging in again.";
            }
            this.loggedInStateSubject.next({
              LoginStateType: loginStateTypes.error_static,
            });
          }
        },
        (r) => {
          const loginResponse = !r.error
            ? (r as RnUserLoginVMRNResponseRelay)
            : (r.error as RnUserLoginVMRNResponseRelay);

          if (loginResponse.Messages && loginResponse.Messages.length > 0) {
            const state =
              loginResponse.Messages[0].Status ===
              RnRNResponseMessageStatus.Error
                ? loginStateTypes.error_dialog
                : loginStateTypes.error_static;
            this.loginErrMessage = loginResponse.Messages[0];
            this.loggedInStateSubject.next({ LoginStateType: state });
          } else {
            this.loginErrMessage = new RnRNResponseMessages();
            this.loginErrMessage.Description =
              "Sorry, please try logging in again.";
            this.loggedInStateSubject.next({
              LoginStateType: loginStateTypes.error_static,
            });
          }
        },
      ),
    );
  }

  refreshToken() {
    if (this.loggedInUserSubject.value) {
      const payload = new RnUpdateToken();
      payload.Token = localStorage.getItem("token");
      this.userService.apiV2UsersUpdatetokenPost(payload, "response").subscribe(
        async (r) => {
          if (r.ok && r.body.Success) {
            localStorage.setItem(
              this.CURRENT_TOKEN_STORAGE_KEY,
              r.body.data.Token,
            );
          } else if (
            r.ok &&
            !r.body.Success &&
            r.body.Messages.filter((m) => {
              m.Code === this.concurrentLicenseCode;
            }).length > 0
          ) {
            this.loginErrMessage = r.body.Messages.filter((m) => {
              return m.Code === this.concurrentLicenseCode;
            })[0];
            this.loggedInInfoService.SetLoggedInUser(null);
            this.loggedInUserSubject.next(null);
            await this.navigationService.GoToLoginPage();
            this.loggedInStateSubject.next({
              LoginStateType: loginStateTypes.error_static,
            });
          }
        },
        async (error) => {
          const rnErr = error;
          let errorState = loginStateTypes.error;
          if (rnErr) {
            if (
              rnErr.error.Messages &&
              rnErr.error.Messages.filter((m) => {
                return m.Code === this.concurrentLicenseCode;
              }).length > 0
            ) {
              this.loginErrMessage = rnErr.error.Messages.filter((m) => {
                return m.Code === this.concurrentLicenseCode;
              })[0];
              errorState = loginStateTypes.error_static;
              this.toastService.showError(this.loginErrMessage.Description);
            }
          }

          await this.logoutWithState(errorState, true);
        },
      );
    }
  }

  checkForRefreshLogin() {
    if (this.loggedInUserSubject.value === null) {
      const temp = localStorage.getItem(this.CURRENT_USER_STORAGE_KEY);
      if (temp && temp !== "") {
        const loggedInUser = new UserLoginState(null);
        loggedInUser.CopyFromJson(temp);
        this.loggedInUserSubject.next(loggedInUser);
        this.loggedInInfoService.SetLoggedInUser(loggedInUser);
        setTimeout(() => {
          // Added delay so pendo gets loaded on the system first
          const pendoData = localStorage.getItem("pendoObject");
          if (pendoData) {
            this.startupService.startPendo(JSON.parse(pendoData));
          }
        }, 500);
      }
    }
  }

  async logout(withRedirecttoLogin = true): Promise<void> {
    if (pendo) {
      try {
        pendo.clearSession();
      } catch (error) {
        // The function does not exist.
      }
    }
    await this.logoutWithState(loginStateTypes.none, withRedirecttoLogin);
  }
  async logoutWithState(
    state: loginStateTypes,
    withRedirecttoLogin: boolean,
    idleClose = false,
  ): Promise<void> {
    if (this.loggedInUserSubject.value) {
      this.loggedInStateSubject.next({ LoginStateType: state });
      this.handleLogout(idleClose);
      if (withRedirecttoLogin)
        await this.navigationService.GoToLoginPageAndLogout();
    }
    localStorage.removeItem("token");
    localStorage.removeItem("alreadyLoggedIn");
    document.cookie = 'apphub_user_data=; path=/; expires=' + new Date().toUTCString() + ';';
    this.selectionService.onLogout();
    this.startupService.onLogout();
    this.authService.logout();
  }

  userIsLoggedIn(): boolean {
    return this.loggedInUserSubject.value !== null;
  }

  loginErrorMessage(): RnRNResponseMessages {
    return this.loginErrMessage;
  }

  private loadLoggedInUserInfoAndNavigate(
    userLoginState: UserLoginState,
  ): void {
    const alreadyLoggedIn = localStorage.getItem("alreadyLoggedIn");
    if (userLoginState !== null && !alreadyLoggedIn) {
      this.loggedInUserSubject.next(userLoginState);
      this.startupService.StartUp(userLoginState);
      this.dialogTrackService.closeAll();
    }
  }

  handleLogout(idleClose = false) {
    if (!idleClose) {
      this.dialog.closeAll();
    }
    this.dialogTrackService.closeAll();

    this.loggedInUserSubject.next(null);
    this.loggedInInfoService.SetLoggedInUser(null);

    localStorage.removeItem("signalRConnection-AccessToken");
    localStorage.removeItem("signalRConnection-Url");
    localStorage.removeItem("alreadyLoggedIn");
    localStorage.removeItem(this.CURRENT_USER_STORAGE_KEY);
    if (this.preferenceService) {
      this.preferenceService.clearAllPreferences();
    }
  }

  private concurrentLicenseCode = "33333";
}
