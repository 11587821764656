<div class="modal-content hide-y-overflow">
  <div class="modal-header">
    <p class="editLicenseHeader">Edit License</p>
    <div>
      <button type="button" class="close pullUpperRight" (click)="close()" aria-hidden="true">
        <span uib-tooltip="Close" tooltipPosition="top" pTooltip="Close">×</span>
      </button>
    </div>
  </div>
  <hr class="headerSeperator" />
  <form [formGroup]="frmGroup">
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div
            class="p-field form-group"
            [ngClass]="{
              'has-error': !licenseType?.valid && (submitted || licenseType?.touched)
            }"
          >
            <label for="licenseType">License Type</label>
            <input
              #licenseTypelInput
              id="licenseType"
              name="licenseType"
              formControlName="licenseType"
              class="ui-inputtext ui-inputtext-notallowed form-control"
              placeholder="License Type"
              pInputText
              readonly
              maxlength="64"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div
            class="p-field form-group"
            [ngClass]="{
              'has-error': !license1?.valid && (submitted || license1?.touched)
            }"
          >
            <label for="license1">License Key</label>

            <input
              id="license1"
              name="license1"
              class="form-control ui-inputtext inputMask"
              formControlName="license1"
              mask="0000-0000-0000-000"
            />
            @if (submitted || license1?.touched) {
              <div>
                @if (license1.errors?.required || license1.errors?.whitespace) {
                  <div class="help-block">Required field</div>
                }
              </div>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div
            class="p-field form-group"
            [ngClass]="{
              'has-error': !license2?.valid && (submitted || license2?.touched)
            }"
          >
            <label for="license2">Product Code</label>
            <input
              id="license2"
              name="license2"
              class="form-control ui-inputtext inputMask"
              formControlName="license2"
              mask="999-999"
              placeholder="xxx xxx"
            />
            @if (submitted || license2?.touched) {
              <div>
                @if (license2.errors?.required || license2.errors?.whitespace) {
                  <div class="help-block">Required field</div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    <hr class="headerSeperatorBottom" />
    <div class="modal-footer right">
      <app-button id="btnCancel" label="CANCEL" buttonStyle="addUserTeritary" (clickActionEmitter)="close()">
      </app-button>
      <app-button
        id="btnSubmit"
        label="SAVE"
        [disabled]="submitted || !frmGroup.valid || this.frmGroup.pristine"
        showProcessing="true"
        buttonStyle="primary"
        (clickActionEmitter)="saveChanges()"
      >
      </app-button>
    </div>
  </form>
</div>
