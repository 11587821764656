<div class="overlay-container">
  @if (isProcessing) {
    <app-loader text="{{ message }}" opaque="true"></app-loader>
  }
  @if (!isProcessing) {
    <a
      tabindex="0"
      (keyup.enter)="close()"
      class="close xToClose c-pointer"
      pTooltip="Close"
      tooltipPosition="top"
      (click)="close()"
      ><img src="{{ assetUrl }}/icons/rn-x.svg" alt="close icon"
    /></a>
  }
  <div>
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12">
          <span class="messageTitle">Disable MFA</span>
          <br />
          <br />
          <p class="messageSection">
            Disabling MFA will no longer require users to enable it for their accounts before logging in. If a user has
            MFA enabled on their account, it will remain enabled unless they choose to disable it.
          </p>
          <br />
          <p class="messageSection">Are you sure you want to continue?</p>
        </div>
      </div>
    </div>
    <hr />
    <div class="modal-footer">
      <a tabindex="0" (keyup.enter)="close()" class="cancel" (click)="close()">Cancel</a>
      @if (enabled) {
        <app-button id="btnDisableMFA" (clickActionEmitter)="disableMfa()" label="Disable MFA" buttonStyle="primary">
        </app-button>
      }
    </div>
  </div>
</div>
