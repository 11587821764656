<div class="modal-content">
  <h3 class="modal-title">Copy Account Activation Link</h3>
  <div class="modal-body p-t-xl" id="copyLink">
    <div class="text_14">
      Copy and share account activation link for:
      {{ dialogConfig.userFullName }}
    </div>
    <div class="md-medium-tall p-t-sm display-flex">
      <input
        class="input_field"
        [disabled]="state | rnCopyActivation: 'disableActivationLink'"
        id="copyLinkField"
        value="{{ activationLink }}"
      />
      @if (!(state | rnCopyActivation: 'isCopiedButtonVisible')) {
        <app-button
          id="btnCopy"
          label="Copy"
          [disabled]="state | rnCopyActivation: 'disableCopyButton'"
          buttonStyle="primary"
          (clickActionEmitter)="copy()"
        >
        </app-button>
      }
      @if (state | rnCopyActivation: 'isCopiedButtonVisible') {
        <app-button
          id="btnCopied"
          label="Copied"
          showIcon="true"
          iconClass="fa-solid fa-check"
          disabled="true"
          buttonStyle="completed"
        >
        </app-button>
      }
    </div>
  </div>

  <div class="">
    <app-button id="btnCancel" label="Close" (clickActionEmitter)="dismiss()" buttonStyle="secondary"> </app-button>
  </div>
</div>
