import { Pipe, PipeTransform } from "@angular/core";
import { ApplicationCard } from "../../../core/models/application-card.model";
import dayjs from "dayjs";

@Pipe({
  name: "rnApplicationCard",
})
export class RnApplicationCardPipe implements PipeTransform {
  transform(appCard: ApplicationCard, ...args: unknown[]): unknown {
    if (appCard && args && args.length > 0) {
      const prop = args[0].toString().toLowerCase();
      switch (prop) {
        case "formatgroupname": {
          return this.formatName(appCard.Name);
        }
        case "formatblockingflavorname": {
          return this.formatName(appCard.BlockingFlavorName);
        }
        case "caninstall": {
          if (!appCard.BlockingFlavorId) {
            return true;
          }
          return false;
        }
        case "popovertext": {
          if (!appCard) {
            return "";
          }
          let result = `<b>${appCard.Name}</b><br /> ${appCard.FlavorString}`;
          if (appCard.Installed && appCard.InstalledDate) {
            result += `<br /><br />INSTALLED ON: ${dayjs(appCard.InstalledDate).format("MM/DD/YYYY")}`;
          }
          return result;
        }
        case "iserrordisplaytype": {
          return (
            appCard.displayType() === "InstallError" ||
            appCard.displayType() === "RemoveError" ||
            appCard.displayType() === "InstallFailure" ||
            appCard.displayType() === "RemoveFailure"
          );
        }
        case "displaytype": {
          return appCard.displayType();
        }
      }
    }

    return null;
  }

  private formatName(name: string): string {
    return name ? name.replace(/\b\d{4}\b/, "").trim() : "";
  }
}
