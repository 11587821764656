import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs";
import { UserLoginState } from "../../../../../core/models/user.login.state";
import { MenuItem } from "primeng/api/menuitem";
import { SignalRService } from "../../../../notifications/signalr.service";
import { AuthenticationService } from "../../../../security/authentication.service";
import {
  RnCommonId,
  RnOrganizationConfigurationVM,
  RnOrganizationsProfileVM,
  RnSignalRConnection,
  RnUsersProfileVM,
} from "../../../../services/rnapi2-service/models/models";
import { RnDialogService } from "../../../../services/dialog/rndialog.service";
import { OrganizationService } from "../../../../services/rnapi2-service/apis/api";
import { ProcessButtonConfiguration } from "../../../third-party-wrappers/process-button/process-button-configuration";
import { processButtonStateTypes } from "../../../third-party-wrappers/process-button/process-button-state";
import { MatMenuTrigger } from "@angular/material/menu";
import { SignalrPollingService } from "../../../../services/signalr-polling/signalr-polling.service";
import { SignalrPollingBaseMessage } from "../../../../../core/models/signalr/signalr-polling-base-message";
import { GenericPropertyPayload } from "../../../../../core/models/signalr/generic-property-payload";
import { Observable, Subject, Subscription, switchMap } from "rxjs";
import { HttpResponse } from "@angular/common/http";
import { OrganizationCatalogInfoVM } from "src/app/shared/services/rnapi2-service/models/OrganizationCatalogInfoVM";
import { EnvService } from "../../../../../core/services/env.service";
import { UserCacheService } from "src/app/shared/services/user-cache/user-cache.service";
import { ModalService } from "@rn-platform/frontend-shared-ui-common";
import { FormGroup } from "@angular/forms";
import { AccessManagementConfiguration } from "src/app/core/models/access-management-configuration";
import { LoggedInInfoService } from "src/app/shared/services/loggedInInfo/logged-in-info.service";
import { SelectionService } from "src/app/shared/services/selection/selection.service";
import { NavigationService } from "src/app/shared/services/navigation/navigation.service";
import { ProfileModalService } from "src/app/shared/services/profileModalService/profile-modal.service";
import { UserProfileUpdateEvent } from "src/app/shared/services/profileModalService/profile-update-event.model";
import {
  RnsidebarService,
  SidebarComponents,
} from "src/app/shared/services/sidebar/rnsidebar.service";
import { AuthService } from "@rn-platform/frontend-shared-feature-identity-auth";
import { UserDetailsModalComponent } from "../user-details-modal/user-details-modal.component";

const welcomeWizardSegment = "welcomewizard";

@Component({
  selector: "app-rn-header",
  templateUrl: "./rn-header.component.html",
  styleUrls: ["./rn-header.component.scss"],
})
export class RnHeaderComponent implements OnInit, OnDestroy {
  loggedInUser: UserLoginState;
  user: RnUsersProfileVM;
  userOrg: RnOrganizationsProfileVM;
  menuItems: MenuItem[];
  resetUserPasswordButtonConfig: ProcessButtonConfiguration;
  resetUserPasswordButtonState: processButtonStateTypes;
  loadingUser = true;
  mfaLabel = "Security Settings";
  showUserDetailsModal = false;
  mfaStatus: string;
  mfaButtonContentStyle: string;
  mfaButtonContentIcon: string;
  loggedInUserName: string;
  userLoggedIn: boolean;
  userId: string;
  private _userID: string;
  showSecurityBtn = true;
  showEditClientAccessButton = false;
  showEditPackagesButton = false;
  userProfileLoading = true;
  selectedOrg: RnOrganizationsProfileVM;
  orgConfiguration: RnOrganizationConfigurationVM;
  isChangePackageButtonDisabled = true;
  isChangeManageAddonsDisabled = true;
  private subscriptions: Subscription[] = [];
  public assetUrl = EnvService.EnvVariables().assetUrl;
  protected userDetailsForm: FormGroup;
  public UserDetailsModalInfo = {
    id: "UserDetailsModalInfo",
    title: "Edit",
    modal_arialabel: "edit user",
    id_prefix: "edit-user-modal-",
  };
  isWelcomeWizard = false;
  isSavingUserDetails: boolean = false;

  @ViewChild(MatMenuTrigger) matMenuTrig: MatMenuTrigger;
  @ViewChild("resetPasswordButton") resetPasswordButton: any;
  @ViewChild("UserDetailsModalInfo") userDetailsModal: UserDetailsModalComponent;

  constructor(
    private selectionService: SelectionService,
    private authenticationService: AuthenticationService,
    private signalRService: SignalRService,
    private rnDialogService: RnDialogService,
    private signalRPollingService: SignalrPollingService,
    private orgService: OrganizationService,
    private userCacheService: UserCacheService,
    private loggedInInfoService: LoggedInInfoService,
    private navigationService: NavigationService,
    protected modalService: ModalService,
    private profileModalService: ProfileModalService,
    private rnSideBarService: RnsidebarService,
    private router: Router,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    authenticationService.checkForRefreshLogin();
    authenticationService.loggedInUser.subscribe((u) => {
      this.loggedInUser = u;
      this.userLoggedIn = !!this.loggedInUser;
      if (!this.loggedInUserName) {
        this.loggedInUserName = this.loggedInUser
          ? `${this.loggedInUser.FirstName} ${this.loggedInUser.LastName}`
          : "";
      }
      const selectedOrgProfileLoad =
        this.selectionService.SelectedOrgProfile.subscribe((p) => {
          this.userOrg = p;
        });
      this.subscriptions.push(selectedOrgProfileLoad);
    });

    this.resetUserPasswordButtonConfig = new ProcessButtonConfiguration();
    this.resetUserPasswordButtonConfig.ButtonClass = "mat-menu-item";
    this.resetUserPasswordButtonConfig.ProcessID = "SendPasswordReset";
    this.resetUserPasswordButtonConfig.NormalButtonText = "Reset My Password";
    this.resetUserPasswordButtonConfig.ProcessingButtonText =
      "Sending Email...";
    this.resetUserPasswordButtonConfig.SucceededButtonText = "Email Sent";
    this.resetUserPasswordButtonConfig.FailedButtonText =
      "Email Failed. Try Again.";
    this.resetUserPasswordButtonConfig.StopPropagation = true;
    this.resetUserPasswordButtonConfig.UseIcons = false;
    this.resetUserPasswordButtonConfig.UsePrecedingIcons = true;
    this.resetUserPasswordButtonConfig.IconNormalImage = "rn-lock-outline";
    this.resetUserPasswordButtonConfig.IconProcessingImage = "rn-circle-notch";
    this.resetUserPasswordButtonConfig.IconProcessingClass =
      "loading-spinner color-black spinner-menu-size";
    this.resetUserPasswordButtonConfig.IconSucceededImage = "rn-check";
    this.resetUserPasswordButtonConfig.IconSucceededClass =
      "filter-green color-green";
    this.resetUserPasswordButtonConfig.IconFailedImage = "rn-x";
    this.resetUserPasswordButtonConfig.IconFailedClass = "filter-red color-red";
    this.resetUserPasswordButtonConfig.AutoReset = true;
    this.resetUserPasswordButtonConfig.GetIsDisabled = () => {
      return false;
    };
    this.resetUserPasswordButtonConfig.State.subscribe((r) => {
      this.resetUserPasswordButtonState = r;
    });
  }

  setUserMfaStatus(): void {
    this.mfaLabel = this.user.MfaEnabled
      ? "Security Settings: "
      : "Security Settings";
    this.mfaStatus = this.user.MfaEnabled ? " Enabled" : "";
    this.mfaButtonContentStyle = this.user.MfaEnabled
      ? "color-green"
      : "color-red";
    this.mfaButtonContentIcon = this.user.MfaEnabled ? "rn-edit-2" : "";
  }

  ngOnInit(): void {
    const routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const currentRoute = event.urlAfterRedirects;
        this.isWelcomeWizard = currentRoute.includes(welcomeWizardSegment);
        if (this.resetPasswordButton) {
          this.resetPasswordButton.ButtonDisabled = this.isWelcomeWizard;
        }
        this.changeDetectorRef.detectChanges();
      });
    this.subscriptions.push(routerSubscription);
    if (this.loggedInUser) {
      const userId = this.loggedInUser.UserID.toString();
      this.userCacheService.getUser(userId).subscribe((response) => {
        this.user = response.body.data;
        this.userProfileLoading = false;
        if (this.user) {
          this.setUserMfaStatus();
        }
        this.changeDetectorRef.detectChanges();
      });
    }
    this.menuItems = [
      {
        label: "Log Out",
        command: async () => {
          await this.logout();
        },
      },
    ];
    const signalRConnection: RnSignalRConnection = {
      AccessToken: localStorage.getItem("signalRConnection-AccessToken"),
      Url: localStorage.getItem("signalRConnection-Url"),
    };

    if (signalRConnection.AccessToken && signalRConnection.Url) {
      this.signalRService.init(signalRConnection);

      this.signalRPollingService.AddMessageHandler((message) => {
        const notification = JSON.parse(message) as SignalrPollingBaseMessage;

        if (
          notification &&
          notification.Payload &&
          notification.Payload.Area === "User" &&
          notification.Payload.AreaID === this.loggedInUser.UserID
        ) {
          const data = JSON.parse(notification.Payload.AreaData);
          switch (notification.Payload.AreaDataType) {
            case "User_Action_Payload":
              if (data.UserAction == "SendPasswordReset") {
                if (data.Success) {
                  // success!
                  this.resetUserPasswordButtonConfig.StateChanged(
                    processButtonStateTypes.succeeded,
                  );
                } else {
                  // failure
                  this.resetUserPasswordButtonConfig.StateChanged(
                    processButtonStateTypes.failed,
                  );
                }
              }
              break;
            case "Generic_Property_Payload":
              {
                const propertyPayload = data as GenericPropertyPayload;
                if (
                  propertyPayload.Properties &&
                  propertyPayload.Properties.length > 0
                ) {
                  const mfaItem = propertyPayload.Properties.find(
                    (p) => p.PropertyName.toLowerCase() === "mfa_status",
                  );
                  if (mfaItem) {
                    this.user.MfaEnabled =
                      mfaItem.PropertyValue.toLowerCase() === "enabled"
                        ? Date.UTC.toString()
                        : undefined;
                    this.setUserMfaStatus();
                  }
                }
              }
              break;
          }
        }
      });
    }

    if (this.loggedInUser?.OrganizationID) {
      this.subscriptions.push(
        this.loadOrganizationPackageData().subscribe((r) => {
          if (r.body.Success) {
            const responsePackages = r.body.data.CatalogItems.filter(
              (p) => "package" == p.PackageUIType,
            );
            if (
              responsePackages.filter(
                (p) =>
                  p.PackageCategoryID == 2 &&
                  p.PackageTypeName == "Entitlement" &&
                  (p.PackageName == "QuickBooks Enterprise Standard" ||
                    p.PackageName == "QuickBooks Plus Standard"),
              ).length > 0
            ) {
              const countAddons = r.body.data.CatalogItems.filter(
                (p) =>
                  p.PackageUIType === "addon" &&
                  (p.PackageName === "Intuit All Apps" ||
                    p.PackageName === "Intuit Core Apps") &&
                  p.CurrentQuantity > 0,
              ).length;
              if (countAddons < 1) {
                this.showSecurityBtn = false;
              }
            }
          }
        }),
      );
    }

    this.setChangeClientAccess();
    this.setChangePackagesAddons();
    const sub = this.profileModalService
      .profileUpdated()
      .subscribe((event: UserProfileUpdateEvent) => {
        if (event.userId === this.loggedInUser.UserID) {
          this.loggedInUserName = event.fullName;
          this.loggedInUser.FirstName = event.firstName;
          this.loggedInUser.LastName = event.lastName;
          this.loggedInUser.Email = event.email;
          this.loggedInInfoService.SetLoggedInUser(this.loggedInUser);
          this.loggedInInfoService.refreshLoggedInUser();
        }
        this.changeDetectorRef.detectChanges();
      });
    this.subscriptions.push(sub);
  }

  setChangePackagesAddons(): void {
    const selectedOrgConfigLoad =
      this.selectionService.SelectedOrgConfig.subscribe((c) => {
        this.orgConfiguration = c;
        this.isChangeManageAddonsDisabled =
          this.orgConfiguration?.InitiatedBillingSeparation;
        this.isChangePackageButtonDisabled =
          this.orgConfiguration?.InitiatedBillingSeparation;
        if (
          !this.isChangeManageAddonsDisabled ||
          !this.isChangePackageButtonDisabled
        ) {
          this.isChangeManageAddonsDisabled =
            this.isChangePackageButtonDisabled =
              this.getHideShowChangePackageButton();
        }
      });
    this.subscriptions.push(selectedOrgConfigLoad);
  }

  setChangeClientAccess(): void {
    this.showEditClientAccessButton =
      this.loggedInInfoService.loggedInUserHasRights(["GRNTORGACC"]) &&
      this.loggedInInfoService.loggedInUserHasRights(["REVKORGACC"]);
  }

  closedMenuHandler(event) {
    if (
      this.resetUserPasswordButtonState == processButtonStateTypes.succeeded ||
      this.resetUserPasswordButtonState == processButtonStateTypes.failed
    ) {
      setTimeout(() => {
        this.resetUserPasswordButtonConfig.StateChanged(
          processButtonStateTypes.normal,
        );
      }, 500);
    }
  }

  launchSecurityModal(): void {
    const dialogRef = this.rnDialogService.EditMfaDialog();
    dialogRef.onClose.subscribe((r) => {
      // do some mfa stuff
    });
  }

  refreshUsername(): void {
    this.loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    this.loggedInInfoService.LoggedInUser.subscribe((u) => {
      this.loggedInUser = u;
    });
  }

  private loadOrganizationPackageData(): Observable<
    HttpResponse<OrganizationCatalogInfoVM>
  > {
    const payload = new RnCommonId();
    (payload.Id = this.loggedInUser.OrganizationID.toString()),
      (payload.AffectedOrganizationId = this.loggedInUser.OrganizationID);
    return this.orgService.apiV2OrganizationsGetcatalogitemsfororgPost(
      payload,
      "response",
    );
  }

  processButtonClick(processID: string) {
    switch (processID) {
      case "ChangePackage":
        this.rnSideBarService.trigger(SidebarComponents.appmanagepackages, {
          userId: this.user.UserID,
        });
        break;
      case "ManageAddons":
        this.rnSideBarService.trigger(SidebarComponents.appmanageaddons, {
          userId: this.user.UserID,
        });
        break;
    }
  }

  getHideShowChangePackageButton(): boolean {
    if (
      this.userOrg?.Status === "Suspended" ||
      this.userOrg?.Status === "Canceled" ||
      this.userOrg?.WlpStatus === "CancelPending"
    ) {
      return true;
    }
    return !this.loggedInInfoService.loggedInUserHasRights(["ASNRMOWNPK"]);
  }

  async navigateToPackageInventory() {
    await this.navigationService.OpenOrgToPackagePageInventoryState(
      this.loggedInUser.OrganizationID,
      this.user.OrganizationID,
    );
  }

  showProfileModal(): void {
    this.rnDialogService.EditUserProfileDialog(this.user.UserID, this.user.OrganizationID);
  }

  openClientAccess(): void {
    const accessConfig = new AccessManagementConfiguration();
    accessConfig.UserProfile = this.user;
    this.rnDialogService.AccessManagementDialog(accessConfig);
  }

  resetPassword(): void {
    // we're going to get logged out, so do some cleanup, but don't do a full logout
    localStorage.removeItem("alreadyLoggedIn");
    localStorage.removeItem("token");
    document.cookie = 'apphub_user_data=; path=/; expires=' + new Date().toUTCString() + ';';

    const email = this.authService.getParsedIdToken()?.email;
    this.authService.requestPasswordReset(email);
    // requestPasswordReset() is a fire-and-forget function, so just mark as succeeded
    this.resetUserPasswordButtonConfig.StateChanged(
      processButtonStateTypes.succeeded,
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  async logout(): Promise<void> {
    // hopefully the Genesys JS SDK will offer a more streamlined approach in the future
    await this.authenticationService.logout();
  }

  savingUserDetails(isSaving: boolean): void {
    this.isSavingUserDetails = isSaving;
  }
}
