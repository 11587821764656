import { GridColumnBaseConfiguration } from "./grid-column-base-configuration";
import { DatePipe } from "@angular/common";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export class GridFormatDateColumnConfiguration extends GridColumnBaseConfiguration {
  constructor(
    fieldName: string,
    title: string,
    classname: string,
    sortOrder: string,
    private datepipe: DatePipe,
  ) {
    super("date", fieldName, title, classname, sortOrder);
    this.CustomDataRetrieval = (dataRow: any) => {
      return this.GetDateString(dataRow);
    };
  }

  public FormatDate = "MM/dd/yyyy hh:mm:ss a";
  public GetCustomDate: (dataRow: any) => string;
  public GetDateCol: (dataRow: any) => string;
  public Target: string;

  public GetDateString(dataRow: any) {
    if (this.GetCustomDate) {
      return this.GetCustomDate(dataRow);
    }
    return this.datepipe.transform(
      dataRow[this.FieldName],
      this.FormatDate,
      "GMT",
    );
  }

  public TypeName(dataRow: any): string {
    if (dataRow) {
      if (dataRow[this.FieldName] && dataRow[this.FieldName] !== "") {
        dataRow[this.FieldName] = dayjs.utc(dataRow[this.FieldName]).local();
        return super.TypeName(dataRow);
      }
    }
    return "generic";
  }
}
