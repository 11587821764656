@if (configuration.ShowHeader) {
  <div>
    <h2 class="{{ configuration.HeaderClass }}">
      {{ configuration.GridHeader }}
    </h2>
  </div>
}
@if (configuration.ShowCountHeader) {
  <div class="{{ configuration?.HeaderClass | rnGrid: 'headercountclass' }}">
    <span>{{ configuration.CountHeaderItemsName }}: {{ configuration.TotalRecords }} Total</span>
  </div>
}
<p-table
  [columns]="configuration.ColumnConfiguration"
  [value]="configuration.GridData"
  [lazy]="configuration.IsLazyLoading"
  (onLazyLoad)="configuration.lazyLoadData($event)"
  loadingIcon="fa-solid fa-spinner"
  [paginator]="false"
  [rows]="pageSize"
  [rowsPerPageOptions]="configuration.RowsPerPageOptions"
  [pageLinks]="configuration.NumberPageLinks"
  [first]="configuration.FirstRow"
  [totalRecords]="configuration.TotalRecords"
  [responsive]="true"
  [(selection)]="selection"
  sortField="{{ configuration.SortField }}"
  [sortOrder]="configuration.SortOrder"
  class="p-datatable {{ configuration.GridClass }} rn-table"
  tableStyleClass="{{ configuration.TableClass }}"
  rowIndexVar="rowIx"
>
  <ng-template pTemplate="header" let-columns>
    <tr class="table-content-font">
      @for (col of columns; track col) {
        <th
          [ngClass]="{ 'c-default': !col.SortOrder }"
          [class]="col.Class"
          [pSortableColumn]="col.SortOrder"
          [pSortableColumnDisabled]="col.SortableColumnDisabled"
        >
          @if (col.TypeName() === 'checkbox') {
            <div>
              <p-tableHeaderCheckbox class="checkboxStyle" (click)="setSelection($event)"></p-tableHeaderCheckbox>
            </div>
          }
          @if ((col | rnGrid: 'coltype') === 'checkboxLimit') {
            <div>
              @if (col.SelectAllAvailable) {
                <input
                  class="checkboxStyle"
                  type="checkbox"
                  data-testid="tableCheckboxHeader"
                  class="checkboxStyle no-href-link"
                  [checked]="configuration.RnSelectAll"
                  (change)="rnSelectAllChanged($event, col)"
                  [disabled]="configuration.selectAllDisabled || (col | rnGrid: 'rnselectalldisabled')"
                  tooltipPosition="top"
                  pTooltip="{{ col | rnGrid: 'rnSelectAllTooltip' }}"
                />
              }
            </div>
          }
          @if (col | rnGrid: 'coltypenotchecktype') {
            <div>
              {{ col.Title }}
              @if (col | rnGrid: 'colcanbesorted') {
                <p-sortIcon [field]="col.SortOrder"></p-sortIcon>
              }
            </div>
          }
        </th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <tr [ngClass]="configuration.RetrieveClassForRow(rowData)" class="table-content-font">
      @for (col of columns; track col) {
        <td
          data-testid="cellData"
          (click)="rowClicked.emit(rowData)"
          class="{{ configuration.RetrieveClassForCell(col, rowData) }} + {{
            col | rnGrid: 'getremovebuttonclassforcell'
          }}"
          [attr.colspan]="configuration.RetrieveColSpanForCell(col, rowData)"
        >
          @if ((col | rnGrid: 'coltype' : rowData) === 'linkbutton') {
            <span>
              <a
                tabindex="0"
                (keyup.enter)="CallLinkButtonAction($event, col, rowData)"
                (click)="CallLinkButtonAction($event, col, rowData)"
                class="{{ col | rnGrid: 'getremovebuttonclassforcell' }}"
                tooltipPosition="top"
                pTooltip="{{ col | rnGrid: 'gettooltipforcell' : rowData }}"
              >
                <i class="{{ col | rnGrid: 'getlinkbuttonclassforcell' }}"></i>
                {{ col | rnGrid: 'getlinkbuttontextdataforcolumn' : rowData }}</a
              >
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'menu') {
            <span>
              <mat-menu id="actionMenu" #appMenu="matMenu" class="custom-menu">
                @for (item of col.MenuItems; track $index) {
                  <div>
                    <button
                      id="{{ item.menuItemId }}"
                      mat-menu-item
                      (click)="CallMenuButtonAction($event, col, item, rowData)"
                      [disabled]="!IsMenuItemEnabled(col, item, rowData)"
                      [ngClass]="{
                        'last-menu-item': item === col.MenuItems[col.MenuItems.length - 1]
                      }"
                    >
                      @if (item.iconClass) {
                        <app-icon imgClass="{{ item.iconClass }}" source="{{ item.iconSource }}"></app-icon>
                      }
                      <span class="action-text">{{ item.text }}</span>
                    </button>
                  </div>
                }
              </mat-menu>
              <button
                mat-icon-button
                [matMenuTriggerFor]="appMenu"
                class="button-action gridMatIcon menu-button"
                [disabled]="!IsMenuButtonEnabled(col)"
              >
                <mat-icon class="margin-bot-10" [ngClass]="{ 'button-disabled': !IsMenuButtonEnabled(col) }"
                  >more_horiz</mat-icon
                >
              </button>
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'hyperlink') {
            <span>
              @if ((col | rnGrid: 'gethyperlinkenabled' : rowData) && (col | rnGrid: 'gethyperlinkashref' : rowData)) {
                <a
                  data-testid="user"
                  class="no-text-decoration"
                  href="{{ col | rnGrid: 'gethyperlinkhrefdataforcolumn' : rowData }}"
                  >{{ col | rnGrid: 'gethyperlinktextdataforcolumn' : rowData }}</a
                >
              }
              @if ((col | rnGrid: 'gethyperlinkenabled' : rowData) && !(col | rnGrid: 'gethyperlinkashref' : rowData)) {
                <a
                  id="initiyName"
                  [attr.data-testid]="col | rnGrid: 'gethyperlinktextdataforcolumn' : rowData"
                  class="no-text-decoration"
                  href="javascript:;"
                  class="no-href-link"
                  (click)="HyperLinkClickHandler(col, rowData)"
                  >{{ col | rnGrid: 'gethyperlinktextdataforcolumn' : rowData }}</a
                >
              }
              @if (!(col | rnGrid: 'gethyperlinkenabled' : rowData)) {
                <span
                  [attr.data-testid]="col | rnGrid: 'gethyperlinktextdataforcolumn' : rowData"
                  class="no-text-decoration"
                  >{{ col | rnGrid: 'gethyperlinktextdataforcolumn' : rowData }}</span
                >
              }
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'affected') {
            <span>
              <span [innerHtml]="col | rnGrid: 'getaffecteddataforcolumn' : rowData"></span>
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'html') {
            <span>
              <span [innerHtml]="GetHtmlDataForColumn(col, rowData) | safeHtml"></span>
              @if (
                col.falseyButtonText &&
                !rowData.SupportPIN &&
                rowData.UserStateName !== 'Processing' &&
                rowData.UserStateName !== 'Failed' &&
                rowData.UserStateName !== 'Failed - Fatal'
              ) {
                <app-button
                  id="btnGrid"
                  buttonStyle="teritary"
                  [label]="col.falseyButtonText"
                  (clickActionEmitter)="falseyValueClickHandler(rowData)"
                >
                </app-button>
              }
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'boolean') {
            <span>
              <span [innerHtml]="col | rnGrid: 'getbooleandataforcolumn' : rowData"></span>
              @if (col | rnGrid: 'isbooleanvaluefalsey' : rowData) {
                <button class="rn-btn rn-btn-tertiary-action m-l-8" (click)="falseyValueClickHandler(rowData)">
                  {{ col.falseyButtonText }}
                </button>
              }
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'multivalue') {
            <span>
              @if (col.useRouterNavigation) {
                <a class="rn-text-link" (click)="userRowClickHandler(rowData)">
                  @for (field of col.fieldNames; track $index) {
                    <span class="{{ col.classNames[$index] }}">{{ rowData[field] }}</span>
                  }
                </a>
              } @else {
                <span [innerHtml]="col | rnGrid: 'getmultivaluedataforcolumn' : rowData"></span>
              }
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'dialog') {
            <span>
              <a class="no-text-decoration" class="no-href-link">{{
                col | rnGrid: 'getdialogvaluedataforcolumn' : rowData
              }}</a>
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'generic') {
            <span>
              <span
                id="cell"
                [attr.data-testid]="configuration.getDataForCell(col, rowData)"
                [innerHtml]="configuration.getDataForCell(col, rowData)"
              ></span>
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'linkStyle') {
            <span>
              <span
                data-testid="linkStyle"
                class="linkStyle"
                [innerHtml]="configuration.getDataForCell(col, rowData)"
              ></span>
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'date') {
            <span>
              <p>{{ col | rnGrid: 'getdatevaluedataforcolumn' : rowData }}</p>
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'checkbox') {
            <span>
              <p-tableCheckbox
                class="checkboxStyle"
                (click)="setSelection(rowData)"
                pTooltip="{{ col | rnGrid: 'gettooltipforcell' : rowData }}"
                [disabled]="col | rnGrid: 'isitemdisabled' : rowData"
                [value]="rowData"
              ></p-tableCheckbox>
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'checkboxLimit') {
            <span
              tooltipPosition="top"
              pTooltip="{{ col | rnGrid: 'gettooltipforcell' : rowData }}"
              [escape]="false"
              tooltipZIndex="9999999"
            >
              <input
                class="checkboxStyle"
                type="checkbox"
                data-testid="tableCheckbox"
                class="checkboxStyle no-href-link"
                [ngModel]="rowData.RnTableSelected"
                (click)="rncheckboxclick($event, col, rowData)"
                (change)="RnCheckboxChanged($event, col, rowData)"
                [disabled]="IsItemDisabled(col, rowData)"
              />
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'actions') {
            <span>
              @if (GetPredicateValue(col, rowData)) {
                <div>
                  @for (button of (col | rnGrid: 'getactionsforcolumn' : rowData.UserStateName) || []; track button) {
                    <div>
                      <button class="{{ button.class }}" (click)="actionClickHandler(rowData, button.type)">
                        {{ button.text }}
                      </button>
                    </div>
                  }
                </div>
              }
              @if (!GetPredicateValue(col, rowData)) {
                <div>
                  {{ rowData[col.FieldName] }}
                </div>
              }
            </span>
          }
          @if ((col | rnGrid: 'coltype' : rowData) === 'quantity') {
            <span>
              <app-quantity-control
                [MinValue]="1"
                [DisallowInput]="false"
                [btnStyleClass]="'shopping-cart-quantity-control-btn'"
                [inputStyleClass]="'shopping-cart-quantity-control-input'"
                [DefaultValue]="getDefaultValue(rowData, col)"
                (quantityChangeActionEmitter)="quantityChangeHandler($event, rowData, col)"
                (quantityEnteredActionEmitter)="quantityEnteredHandler($event, rowData, col)"
              ></app-quantity-control>
            </span>
          }
        </td>
      }
    </tr>
  </ng-template>
</p-table>
@if (configuration.UsePaginator) {
  <div class="paginator-container" #navButtons data-testid="gridNavButtons">
    <div class="left-section">
      Rows per page
      <select [(ngModel)]="pageSize" (change)="pageSizeChanged()" class="selectOptionForPagination">
        @for (option of configuration.RowsPerPageOptions; track option) {
          <option [value]="option">
            {{ option }}
          </option>
        }
      </select>
    </div>
    <div class="center-section">
      @if (configuration.TotalRecords > 0) {
        <span data-testid="gridPageButtonContainer" class="gridPageButtonContainer">
          <div class="paginator-nav-buttons">
            <button
              class="paginator-nav-button"
              data-testid="gridNavButtonLeft"
              [disabled]="currentPageNum === 0"
              (click)="navToPage(currentPageNum - 1)"
            >
              <i class="fa-solid fa-chevron-left"></i>
            </button>
            @for (data of paginatorData; track $index) {
              <span>
                <button
                  class="paginator-nav-button"
                  [disabled]="data.pageNumber < 0 || data.pageNumber === currentPageNum"
                  [ngClass]="{
                    'active-page-item': data.pageNumber === currentPageNum
                  }"
                  (click)="navToPage(data.pageNumber)"
                  [attr.data-testid]="'gridPageButton_' + data.pageLabel"
                >
                  {{ data.pageLabel }}
                </button>
              </span>
            }
            <button
              class="paginator-nav-button"
              data-testid="gridNavButtonRight"
              [disabled]="currentPageNum === getLastPageNumber()"
              (click)="navToPage(currentPageNum + 1)"
            >
              <i class="fa-solid fa-chevron-right"></i>
            </button>
          </div>
          <div class="paginator-nav-text">
            {{ configuration?.CurrentFirstRowNumber }}-{{
              configuration?.CurrentFirstRowNumber + configuration.GridData?.length - 1
            }}
            of {{ configuration.TotalRecords }}
          </div>
        </span>
      }
    </div>
  </div>
}
