<div class="promoContainer futureBill">
  <div>
    <span class="promo-label">Promo Code&nbsp;</span>
    <br />
    <div class="form-group disp-inl-flex">
      <input
        id="promoTextbox"
        maxlength="25"
        autocomplete="off"
        (focus)="error = false"
        [ngClass]="{ 'sharp-angles-error': error }"
        type="text"
        [(ngModel)]="inputPromoCode"
        placeholder="Promo Code"
        class="textbox-sharp-angles"
        (keyup)="onPromoChange()"
      />
      <button
        [disabled]="!inputPromoCode"
        type="button"
        (click)="validatePromoCode(inputPromoCode)"
        [ngClass]="
          promoCodeIsValid === false && wait === false
            ? 'blue-promo-apply-btn'
            : 'blue-promo-apply-btn blue-promo-apply-btn-default-pointer'
        "
      >
        @if (!promoCodeIsValid && !wait) {
          <span class="applyPromo">APPLY</span>
        }
        @if (wait === true) {
          <span><app-icon source="rn-circle-notch" imgClass="loading-spinner gray-icon "></app-icon></span>
        }
        @if (promoCodeIsValid === true) {
          <span><i class="fa-solid fa-check promo-apply-check" aria-hidden="true"></i></span>
        }
      </button>
    </div>
  </div>
  @if (error) {
    <div class="red-help-block">Promo Code {{ inputPromoCode }} is not valid or has expired.</div>
  }
</div>
