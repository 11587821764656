<form [formGroup]="parentForm">
  <fieldset>
    <div class="form-group" [ngClass]="{ 'has-error': !supportPIN.valid && supportPIN.touched }">
      @if (!loadNewUI) {
        <div class="col-sm-12">
          <span class="createSupportLabel">Create a Support PIN</span>
        </div>
      }
      <div class="col-sm-12" [ngClass]="{ 'p-0': loadNewUI }">
        <span class="otherText"
          >PIN must be 4 digits long and contain only numbers. When engaging Rightworks support by telephone, you will
          be asked to supply this PIN as a means of authentication.</span
        >
      </div>
      <div class="col-sm-12" [ngClass]="{ 'p-0': loadNewUI }">
        <label for="supportPIN" class="supportText">Support PIN</label>
      </div>
      <div class="col-sm-3 col-md-3 col-lg-3" [ngClass]="{ 'p-0': loadNewUI }">
        <div class="input-icons">
          @if (!loadNewUI) {
            <i class="fa-solid fa-user icon"></i>
          }
          <input
            id="supportPIN"
            placeholder="{{ loadNewUI ? 'Support PIN' : 'PIN' }}"
            type="text"
            maxlength="4"
            class="form-control input-field"
            [ngClass]="{ 'input-field-pl-30': !loadNewUI }"
            formControlName="supportPIN"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          />
        </div>
        @if (supportPIN.touched) {
          <div>
            @if (!supportPIN.valid) {
              <div class="help-block">Required field - Support PIN must be present and 4 digits long</div>
            }
          </div>
        }
      </div>
    </div>
  </fieldset>
</form>
@if (loadNewUI) {
  <div class="accordion-next-button-footer">
    <rw-button
      [buttonclass]="'rw-btn-secondary'"
      [text]="'next'"
      [arialabel]="'Support PIN Next Button'"
      [datapendoid]="'apphub-newwelcomewizard-supportpin-nextbutton'"
      [datatestid]="'apphub-newwelcomewizard-supportpin-nextbutton'"
      [disable]="!parentForm.valid"
      (clickButton)="proceedToNextTab()"
    >
    </rw-button>
  </div>
}
