<form [formGroup]="frmGroup">
  <div class="header_wrapper">
    <div class="col-md-4 events-search-text-container">
      <div class="ui-fluid">
        <app-search-control
          id="eventsSearchComponent"
          [searchText]="searchText"
          [Configuration]="searchControlConfiguration"
          (SearchOccurred)="textSearchOccurred($event)"
        ></app-search-control>
      </div>
    </div>
    <div class="col-md-1 no-padding"></div>
    <div class="col-md-7 no-padding d-flex">
      <div class="col-md-1 no-padding"></div>
      <div class="col-md-6 no-padding event-types-container">
        <div class="ui-fluid">
          <app-select
            componentId="eventTypeSelect"
            [items]="eventTypes"
            [overlayClass]="'event-types-container'"
            data-testid="allEventsDrop"
            value="value"
            text="label"
            defaultText="All Events"
            [frmComponent]="frmGroup"
            (changed)="onChangeEventType()"
            [loading]="loadingEvents"
          >
          </app-select>
        </div>
      </div>
      <div
        class="col-md-5 no-padding date-picker-container"
        [ngClass]="{
          'has-error': frmGroup.get('eventDateRange').hasError('dateRangeInvalid')
        }"
      >
        <rw-date-picker
          id="eventDateRange"
          formControlName="eventDateRange"
          arialabel="event date range"
          datapendoid="event-date-range"
          datatestid="event-date-range"
          acceptDateRanges="true"
          selectWeekRanges="true"
          (dataChanged)="dateRangePickerHandler($event)"
        ></rw-date-picker>
        @if (frmGroup.get('eventDateRange').hasError('dateRangeInvalid')) {
          <div class="help-block">Invalid date range</div>
        }
      </div>
    </div>
  </div>
  <div data-testid="eventsContainer" class="p-lg-12 no-padding">
    <rn-grid [Configuration]="eventsGridConfiguration"></rn-grid>
  </div>
</form>
