<div class="full-container-padding-side-24 p-t-xl p-b-xl scroll-content">
  @if (isLoading) {
    <app-loader text="Loading..."></app-loader>
  }
  <div class="detailsDetails">
    <div class="dspy-flex justifiedRow detailsHeading">
      <h4 class="assignHeading">Assign License to Users</h4>
    </div>
    <div>
      <span class="subHeading"
        >Select the users that need to be assigned to this license. They need to have a package assigned to them in
        order to assign a license.</span
      >
      <br />
    </div>
    <div class="row medium">
      <div class="col-sm-12">
        <div class="row p-t-sm p-b-sm">
          <div class="col-sm-9">
            <div class="p-datatable rn-table">
              <div class="ui-table ui-widget ui-table-responsive">
                <div class="ui-table-wrapper">
                  <table>
                    <thead class="ui-table-thead">
                      <tr>
                        <th>License Type</th>
                        <th>License Key</th>
                        <th>Product Code</th>
                      </tr>
                    </thead>
                    <tbody class="ui-table-tbody">
                      <tr>
                        <td>
                          <span data-testid="licenseType">{{ license.DisplayName }}</span>
                        </td>
                        <td>
                          <span data-testid="licenseKey">{{ license.License1 }}</span>
                        </td>
                        <td>
                          <span data-testid="licenseProductCode">{{ license.License2 }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-row">
    <div class="col-sm-6 searchDiv">
      <app-search-control
        id="searchControl"
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 searchDiv"
        [Configuration]="searchControlConfiguration"
        (SearchOccurred)="searchOccurred($event)"
        [clearEvent]="clearSearch.asObservable()"
        [searchText]="searchText"
      ></app-search-control>
    </div>
  </div>
  <div class="flex-row assignmentGrid">
    <rn-grid id="assigedLicenseGrid" [Configuration]="assigedLicenseGridConfiguration"></rn-grid>
  </div>
</div>
<div class="modal-footer copy-activation-link-footer">
  <app-button id="btnCancel" label="CANCEL" buttonStyle="addUserTeritary" (clickActionEmitter)="dismiss()">
  </app-button>
  <app-button
    id="btnAssignLicense"
    buttonStyle="primary"
    [disabled]="userAssignBtnDisabled"
    showProcessing="true"
    [processing]="isProcessing"
    label="ASSIGN LICENSE"
    (clickActionEmitter)="AssignLicense()"
  >
  </app-button>
</div>
