/**
 * RNAPI 2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec } from "@angular/common/http";
import { CustomHttpParameterCodec } from "../encoder";
import { Observable } from "rxjs";

import { RnCategoriesVMListRNResponseRelay } from "../models/models";
import { RnCosmosTableVMListRNResponseRelay } from "../models/models";
import { RnCountryCodesVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnEventTypesVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnLUCCTypeOrderByTypes } from "../models/models";
import { RnLUCountryCodesOrderByTypes } from "../models/models";
import { RnLUEventTypeOrderByTypes } from "../models/models";
import { RnLUOrderSourceOrderByTypes } from "../models/models";
import { RnOrderSourcesVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnPaymentMethodsVMSearchResultsVMRNResponseRelay } from "../models/models";
import { RnSegmentVMListRNResponseRelay } from "../models/models";
import { RnStringStringDictionaryRNResponseRelay } from "../models/models";
import { RnUserTypesVMListRNResponseRelay } from "../models/models";
import { RnWLPLocalAppVMListRNResponseRelay } from "../models/models";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable({
  providedIn: "root",
})
export class LookupService {
  protected basePath = "http://localhost";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== "string") {
      if (typeof basePath !== "string") {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string,
  ): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string,
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)),
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            value.toISOString().substr(0, 10),
          );
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k,
            )),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

  /**
   * ( Get Country Codes as Lookup )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param orderBy
   * @param searchText
   * @param pageSize
   * @param pageNumber
   * @param isDescendingOrder
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LookupCountrycodesGet(
    orderBy?: RnLUCountryCodesOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnCountryCodesVMSearchResultsVMRNResponseRelay>;
  public apiV2LookupCountrycodesGet(
    orderBy?: RnLUCountryCodesOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnCountryCodesVMSearchResultsVMRNResponseRelay>>;
  public apiV2LookupCountrycodesGet(
    orderBy?: RnLUCountryCodesOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnCountryCodesVMSearchResultsVMRNResponseRelay>>;
  public apiV2LookupCountrycodesGet(
    orderBy?: RnLUCountryCodesOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (orderBy !== undefined && orderBy !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>orderBy,
        "OrderBy",
      );
    }
    if (searchText !== undefined && searchText !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>searchText,
        "SearchText",
      );
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>pageSize,
        "PageSize",
      );
    }
    if (pageNumber !== undefined && pageNumber !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>pageNumber,
        "PageNumber",
      );
    }
    if (isDescendingOrder !== undefined && isDescendingOrder !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>isDescendingOrder,
        "IsDescendingOrder",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnCountryCodesVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/lookup/countrycodes`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Get Event Types as Lookup )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param orderBy
   * @param searchText
   * @param pageSize
   * @param pageNumber
   * @param isDescendingOrder
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LookupEventtypesGet(
    orderBy?: RnLUEventTypeOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnEventTypesVMSearchResultsVMRNResponseRelay>;
  public apiV2LookupEventtypesGet(
    orderBy?: RnLUEventTypeOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnEventTypesVMSearchResultsVMRNResponseRelay>>;
  public apiV2LookupEventtypesGet(
    orderBy?: RnLUEventTypeOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnEventTypesVMSearchResultsVMRNResponseRelay>>;
  public apiV2LookupEventtypesGet(
    orderBy?: RnLUEventTypeOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (orderBy !== undefined && orderBy !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>orderBy,
        "OrderBy",
      );
    }
    if (searchText !== undefined && searchText !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>searchText,
        "SearchText",
      );
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>pageSize,
        "PageSize",
      );
    }
    if (pageNumber !== undefined && pageNumber !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>pageNumber,
        "PageNumber",
      );
    }
    if (isDescendingOrder !== undefined && isDescendingOrder !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>isDescendingOrder,
        "IsDescendingOrder",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnEventTypesVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/lookup/eventtypes`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Get Categories )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LookupGetCategoriesGet(
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnCategoriesVMListRNResponseRelay>;
  public apiV2LookupGetCategoriesGet(
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnCategoriesVMListRNResponseRelay>>;
  public apiV2LookupGetCategoriesGet(
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnCategoriesVMListRNResponseRelay>>;
  public apiV2LookupGetCategoriesGet(
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnCategoriesVMListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/lookup/getCategories`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Internal&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; GETCDBTBL
   * @param requestBody
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LookupGetcosmostablesPost(
    requestBody?: Array<string>,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnCosmosTableVMListRNResponseRelay>;
  public apiV2LookupGetcosmostablesPost(
    requestBody?: Array<string>,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnCosmosTableVMListRNResponseRelay>>;
  public apiV2LookupGetcosmostablesPost(
    requestBody?: Array<string>,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnCosmosTableVMListRNResponseRelay>>;
  public apiV2LookupGetcosmostablesPost(
    requestBody?: Array<string>,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.post<RnCosmosTableVMListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/lookup/getcosmostables`,
      requestBody,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets all WLP-specific configuration values for the given WLP name )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param wlpName WLP name to retrieve configuration for, should match the name in the DB
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LookupGetwlpconfigGet(
    wlpName?: string,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnStringStringDictionaryRNResponseRelay>;
  public apiV2LookupGetwlpconfigGet(
    wlpName?: string,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnStringStringDictionaryRNResponseRelay>>;
  public apiV2LookupGetwlpconfigGet(
    wlpName?: string,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnStringStringDictionaryRNResponseRelay>>;
  public apiV2LookupGetwlpconfigGet(
    wlpName?: string,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (wlpName !== undefined && wlpName !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>wlpName,
        "wlpName",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnStringStringDictionaryRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/lookup/getwlpconfig`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Gets all WLP-specific configuration values for the given WLP name )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param wlpName WLP name to retrieve configuration for, should match the name in the DB
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LookupGetwlplocalappsGet(
    wlpName?: string,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnWLPLocalAppVMListRNResponseRelay>;
  public apiV2LookupGetwlplocalappsGet(
    wlpName?: string,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnWLPLocalAppVMListRNResponseRelay>>;
  public apiV2LookupGetwlplocalappsGet(
    wlpName?: string,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnWLPLocalAppVMListRNResponseRelay>>;
  public apiV2LookupGetwlplocalappsGet(
    wlpName?: string,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (wlpName !== undefined && wlpName !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>wlpName,
        "wlpName",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnWLPLocalAppVMListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/lookup/getwlplocalapps`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Get PaymentMethods )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LookupIndustriesGet(
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnSegmentVMListRNResponseRelay>;
  public apiV2LookupIndustriesGet(
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnSegmentVMListRNResponseRelay>>;
  public apiV2LookupIndustriesGet(
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnSegmentVMListRNResponseRelay>>;
  public apiV2LookupIndustriesGet(
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnSegmentVMListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/lookup/industries`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Get order sources as lookup )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param orderBy
   * @param searchText
   * @param pageSize
   * @param pageNumber
   * @param isDescendingOrder
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LookupOrdersourcesGet(
    orderBy?: RnLUOrderSourceOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnOrderSourcesVMSearchResultsVMRNResponseRelay>;
  public apiV2LookupOrdersourcesGet(
    orderBy?: RnLUOrderSourceOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnOrderSourcesVMSearchResultsVMRNResponseRelay>>;
  public apiV2LookupOrdersourcesGet(
    orderBy?: RnLUOrderSourceOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnOrderSourcesVMSearchResultsVMRNResponseRelay>>;
  public apiV2LookupOrdersourcesGet(
    orderBy?: RnLUOrderSourceOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (orderBy !== undefined && orderBy !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>orderBy,
        "OrderBy",
      );
    }
    if (searchText !== undefined && searchText !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>searchText,
        "SearchText",
      );
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>pageSize,
        "PageSize",
      );
    }
    if (pageNumber !== undefined && pageNumber !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>pageNumber,
        "PageNumber",
      );
    }
    if (isDescendingOrder !== undefined && isDescendingOrder !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>isDescendingOrder,
        "IsDescendingOrder",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnOrderSourcesVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/lookup/ordersources`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Get PaymentMethods )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; Jwt Bearer token&lt;br/&gt;&lt;b&gt;MyAccount User Class:&lt;/b&gt; Any&lt;br/&gt;&lt;b&gt;MyAccount Rights:&lt;/b&gt; NEWORGORDR,PRCHDROPPK
   * @param orderBy
   * @param searchText
   * @param pageSize
   * @param pageNumber
   * @param isDescendingOrder
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LookupPaymentmethodsGet(
    orderBy?: RnLUCCTypeOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnPaymentMethodsVMSearchResultsVMRNResponseRelay>;
  public apiV2LookupPaymentmethodsGet(
    orderBy?: RnLUCCTypeOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnPaymentMethodsVMSearchResultsVMRNResponseRelay>>;
  public apiV2LookupPaymentmethodsGet(
    orderBy?: RnLUCCTypeOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnPaymentMethodsVMSearchResultsVMRNResponseRelay>>;
  public apiV2LookupPaymentmethodsGet(
    orderBy?: RnLUCCTypeOrderByTypes,
    searchText?: string,
    pageSize?: number,
    pageNumber?: number,
    isDescendingOrder?: boolean,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (orderBy !== undefined && orderBy !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>orderBy,
        "OrderBy",
      );
    }
    if (searchText !== undefined && searchText !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>searchText,
        "SearchText",
      );
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>pageSize,
        "PageSize",
      );
    }
    if (pageNumber !== undefined && pageNumber !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>pageNumber,
        "PageNumber",
      );
    }
    if (isDescendingOrder !== undefined && isDescendingOrder !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>isDescendingOrder,
        "IsDescendingOrder",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnPaymentMethodsVMSearchResultsVMRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/lookup/paymentmethods`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Get User Types (roles) as Lookup )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param loggedInUserID
   * @param selectedOrganizationID
   * @param internalOverride
   * @param evlUserName
   * @param evlAppSource
   * @param evlUserID
   * @param evlEmail
   * @param evlCreatedByUserID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LookupUsertypesGet(
    loggedInUserID?: number,
    selectedOrganizationID?: number,
    internalOverride?: boolean,
    evlUserName?: string,
    evlAppSource?: string,
    evlUserID?: string,
    evlEmail?: string,
    evlCreatedByUserID?: number,
    observe?: "body",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<RnUserTypesVMListRNResponseRelay>;
  public apiV2LookupUsertypesGet(
    loggedInUserID?: number,
    selectedOrganizationID?: number,
    internalOverride?: boolean,
    evlUserName?: string,
    evlAppSource?: string,
    evlUserID?: string,
    evlEmail?: string,
    evlCreatedByUserID?: number,
    observe?: "response",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpResponse<RnUserTypesVMListRNResponseRelay>>;
  public apiV2LookupUsertypesGet(
    loggedInUserID?: number,
    selectedOrganizationID?: number,
    internalOverride?: boolean,
    evlUserName?: string,
    evlAppSource?: string,
    evlUserID?: string,
    evlEmail?: string,
    evlCreatedByUserID?: number,
    observe?: "events",
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<HttpEvent<RnUserTypesVMListRNResponseRelay>>;
  public apiV2LookupUsertypesGet(
    loggedInUserID?: number,
    selectedOrganizationID?: number,
    internalOverride?: boolean,
    evlUserName?: string,
    evlAppSource?: string,
    evlUserID?: string,
    evlEmail?: string,
    evlCreatedByUserID?: number,
    observe: any = "body",
    reportProgress = false,
    options?: {
      httpHeaderAccept?: "text/plain" | "application/json" | "text/json";
    },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (loggedInUserID !== undefined && loggedInUserID !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>loggedInUserID,
        "loggedInUserID",
      );
    }
    if (
      selectedOrganizationID !== undefined &&
      selectedOrganizationID !== null
    ) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>selectedOrganizationID,
        "selectedOrganizationID",
      );
    }
    if (internalOverride !== undefined && internalOverride !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>internalOverride,
        "internalOverride",
      );
    }
    if (evlUserName !== undefined && evlUserName !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>evlUserName,
        "evl.UserName",
      );
    }
    if (evlAppSource !== undefined && evlAppSource !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>evlAppSource,
        "evl.AppSource",
      );
    }
    if (evlUserID !== undefined && evlUserID !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>evlUserID,
        "evl.UserID",
      );
    }
    if (evlEmail !== undefined && evlEmail !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>evlEmail,
        "evl.Email",
      );
    }
    if (evlCreatedByUserID !== undefined && evlCreatedByUserID !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>evlCreatedByUserID,
        "evl.CreatedByUserID",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        "text/plain",
        "application/json",
        "text/json",
      ];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<RnUserTypesVMListRNResponseRelay>(
      `${this.configuration.basePath}/api/v2/lookup/usertypes`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * ( Validate a PromoCode )
   * &lt;br/&gt;&lt;b&gt;Authentication Scheme:&lt;/b&gt; None
   * @param promoCode promocode to validate
   * @param packageIDs packages for which promocode is being applied (for validation purposes)
   * @param orgID orgId for lookup of existing packages
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV2LookupValidatepromocodeGet(
    promoCode?: string,
    packageIDs?: Array<number>,
    orgID?: number,
    observe?: "body",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<any>;
  public apiV2LookupValidatepromocodeGet(
    promoCode?: string,
    packageIDs?: Array<number>,
    orgID?: number,
    observe?: "response",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<HttpResponse<any>>;
  public apiV2LookupValidatepromocodeGet(
    promoCode?: string,
    packageIDs?: Array<number>,
    orgID?: number,
    observe?: "events",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<HttpEvent<any>>;
  public apiV2LookupValidatepromocodeGet(
    promoCode?: string,
    packageIDs?: Array<number>,
    orgID?: number,
    observe: any = "body",
    reportProgress = false,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (promoCode !== undefined && promoCode !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>promoCode,
        "promoCode",
      );
    }
    if (packageIDs) {
      packageIDs.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          "packageIDs",
        );
      });
    }
    if (orgID !== undefined && orgID !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>orgID,
        "orgID",
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys["Bearer"] ||
        this.configuration.apiKeys["Authorization"];
      if (key) {
        headers = headers.set("Authorization", key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let responseType: "text" | "json" = "json";
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith("text")
    ) {
      responseType = "text";
    }

    return this.httpClient.get<any>(
      `${this.configuration.basePath}/api/v2/lookup/validatepromocode`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
