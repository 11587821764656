<div class="modal-content dropPackageModal">
  <div class="modal-header">
    <h3 data-testid="releaseOrDropPackageText">Release or Drop Package{{ multiPackages ? 's' : '' }}?</h3>
  </div>
  <div class="modal-body pad-20">
    @if (isWlp) {
      <div class="p-field wlp-text">
        This process will affect the Packages and Add-ons that are purchased from Rightworks.<br />
        To cancel any products purchased from our partner, please contact them directly.
      </div>
    }
    <form [formGroup]="oldPackageActionForm">
      <div>
        @if (isChangePackage) {
          <h4>
            User{{ plural ? 's' : '' }}:
            <span class="unbolded-h4-text">{{ usersText }}</span>
          </h4>
        }
        <h4>
          Package{{ multiPackages ? 's' : '' }}:
          <span class="unbolded-h4-text">{{ packageToString }}</span>
        </h4>
        <br />
      </div>
      @if (isChangePackage && showDeleteUserText) {
        <div class="p-field">
          <span
            >Before deleting {{ plural ? 'these' : 'this' }} user{{ plural ? 's' : '' }}, you need to choose what to do
            with the package{{ multiPackages ? 's' : '' }} currently assigned to them.</span
          >
        </div>
      }
      <div>
        <div class="p-field">
          <label
            ><input
              type="radio"
              data-testid="radioBtnRelease"
              formControlName="keepOldPackage"
              value="true"
              required
            /><b>Release Package{{ multiPackages ? 's' : '' }}</b></label
          >
          <div class="indent">
            Release {{ multiPackages ? 'these' : 'this' }} package{{ multiPackages ? 's' : '' }} and make
            {{ multiPackages ? 'them' : 'it' }} available for use by {{ multiPackages ? 'other' : 'another' }} user{{
              multiPackages ? 's' : ''
            }}.
          </div>
          <div class="indent">
            You will continue to be charged each month for
            {{ multiPackages ? 'these' : 'this' }} package{{ multiPackages ? 's' : '' }}, even if
            {{ multiPackages ? 'they are' : 'it is' }} not assigned.
          </div>
        </div>
        <div class="p-field">
          <label
            ><input
              type="radio"
              data-testid="radioBtnDrop"
              formControlName="keepOldPackage"
              value="false"
              required
              [attr.disabled]="releaseDisabled"
            /><b>Drop Package{{ multiPackages ? 's' : '' }}</b></label
          >
          <div class="indent">
            You will not receive a prorated refund, but you will not be charged in the next billing period for
            {{ multiPackages ? 'these' : 'this' }} package{{ multiPackages ? 's' : '' }}.
          </div>
        </div>
      </div>

      @if (!isChangePackage) {
        <div class="p-field">
          <span>Click Cancel if you do not want to make any changes to this user's package.</span>
        </div>
      }
    </form>
  </div>
  <div class="modal-footer">
    <app-button
      id="btnReleaseOrDropCancel"
      data-testid="btnReleaseOrDropCancel"
      (clickActionEmitter)="cancel()"
      label="CANCEL"
      buttonStyle="cancelButton"
    >
    </app-button>
    <app-button
      id="btnSubmit"
      [disabled]="(!oldPackageActionForm.valid && !setManually) || (oldPackageActionForm.pristine && !setManually)"
      (clickActionEmitter)="close()"
      label="CONFIRM"
      buttonStyle="primary"
    >
    </app-button>
  </div>
</div>
