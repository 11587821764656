<div class="page-color-padding package-tab">
  <div class="row medium">
    <div class="col-md-12">
      <h2 class="pageTitle" data-testid="pkgManagementTitle">Package Management</h2>
      <h6 class="packages-subtext" data-testid="pkgSubTxt">
        View details for this package, assign and unassign users as needed.
      </h6>
    </div>
  </div>
  <div class="row margin-bottom-10">
    <div class="col-md-8">
      <h2 class="sectionTitle">Package Details</h2>
    </div>
    <div class="col-md-4 text-right margin-top-4">
      <span class="pad-top-10" [pTooltip]="addOnSuspendedTooltip" tooltipPosition="top">
        @if (canShowChangePackageMix) {
          <app-button
            id="btnChangePackageMix"
            buttonStyle="changeSeatCount"
            noContentPadding="true"
            [disabled]="orgIsInAddOnSuspended"
            label="CHANGE SEAT COUNT"
            (clickActionEmitter)="changePackageMix()"
          >
          </app-button>
        }
      </span>
    </div>
  </div>
  <h6 class="packages-subtext">
    {{ CustomerTypePackageText }}
  </h6>
  <div class="button-margin">
    @if (!canShowChangePackageMix) {
      <div class="step-desc">{{ infoText }}</div>
    }
    <div class="no-padding well table-intuit-container no-side-margins">
      <table show-filter="false" class="table-package-detail table-packages">
        <thead>
          <tr>
            <th data-testid="pkgType" class="orgPackageTableNameColumn">Type</th>
            <th data-testid="pkgSeats" class="packageCells">Seats</th>
            <th data-testid="pkgAssigned" class="packageCells">Assigned</th>
            <th data-testid="pkgRemaining" class="packageCells">Remaining</th>
          </tr>
        </thead>
        <tr>
          <td data-testid="pkgName">{{ PackagePool?.PackageName }}</td>
          <td class="packageCells">
            <span data-testid="pkgSeatsNumber">{{ PackagePool?.NumberAllowed }}</span>
          </td>
          <td class="packageCells">
            <span data-testid="pkgAssignedNumber">{{ PackagePool?.NumberUsed }}</span>
          </td>
          <td class="packageCells">
            <span data-testid="pkgRemainingNumber">{{ RemainingAmount }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="btn-group">
        <h2 class="sectionTitle disp-inl-flex">Users assigned to {{ PackagePool?.PackageName }}</h2>
        &nbsp;<app-icon
          source="rn-help"
          [imgClass]="'questionMarkTooltip'"
          tooltip-placement="right"
          [escape]="false"
          [pTooltip]="packageAssignTooltip"
          tooltipStyleClass="wideTooltipMed"
        ></app-icon>
      </div>
      @if (canShowAssignRemove) {
        <div class="btn-group pull-right margin-top-4">
          <div class="display-flex">
            <span class="pull-right assignPackageSpan"
              ><app-button
                id="btnAssign"
                buttonStyle="primaryAssign"
                noContentPadding="true"
                [disabled]="isEntitlementCompletelyConsumed"
                label="ASSIGN PACKAGE TO USERS"
                (clickActionEmitter)="AssignPackage()"
              >
              </app-button
            ></span>
            <span class="pull-right"
              ><app-button
                id="btnRemove"
                buttonStyle="changeSeatCount"
                noContentPadding="true"
                [disabled]="!(SelectedUsersForRemoval.length | rnQuantityChange: 'hasQuantity')"
                label="REMOVE PACKAGE FROM USERS"
                (clickActionEmitter)="removeSelectedPackages()"
              >
              </app-button
            ></span>
          </div>
        </div>
      }
    </div>
  </div>
  <div class="row medium">
    <div class="col-md-12">
      <div>
        <rn-grid
          [Configuration]="TableConfiguration"
          [(selection)]="SelectedUsersForRemoval"
          (rowSelected)="rowSelected($event)"
          [clearSelected]="clearSelection.asObservable()"
        ></rn-grid>
      </div>
    </div>
    <span class="returnToInventorySpan"
      ><app-button
        id="btnReturn"
        (clickActionEmitter)="returnToInventory()"
        noContentPadding="true"
        label="RETURN TO INVENTORY"
        buttonStyle="returnToInventory"
      >
      </app-button
    ></span>
  </div>
</div>
