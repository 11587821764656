<div class="list-container">
  @for (c of cardList; track c) {
    <div [ngClass]="{ 'card-spacing': canUseNewTiles }">
      <app-application-card
        [userID]="userID"
        [card]="c"
        (cardRequestEvent)="handleCardEvent($event)"
        [canUseOpenButton]="canUseOpenButton"
        [canUseNewTiles]="canUseNewTiles"
      ></app-application-card>
    </div>
  }
  @for (l of localAppList; track l) {
    <div [ngClass]="{ 'card-spacing': canUseNewTiles }">
      <app-local-app-card [userID]="userID" [card]="l" [canUseNewTiles]="canUseNewTiles"></app-local-app-card>
    </div>
  }
</div>
